import { Button, Input, message, Modal, Progress, Skeleton, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SaveSurveyResponseAsDraftRequest } from '../../constants/apiRequestResponse'
import TEST_IDS from '../../constants/cypressTestIds'
import { SurveyQuestion, SurveyQuestionResponse, SurveyQuestionType, SurveyResponse, SurveyResponseStatusLabels } from '../../constants/types'
import { finishSurveyResponse, saveSurveyResponseAsDraft } from '../../services/api'
import { parseTime } from '../../util'
import styles from './Questions.module.css'
import { SurveyResponsePageKeys } from '../../services/i8tn/SurveyResponsePage/keys'

type Prop = {
    surveyResponse?: SurveyResponse,
    surveyQuestions?: SurveyQuestion[],
}

export default function Questions(props: Prop) {

    const [surveyResponse, setSurveyResponse] = useState<SurveyResponse | undefined>()
    const { t } = useTranslation()
    const [surveyQuestionResponseByQuestionId, setSurveyQuestionResponseByQuestionId] = useState<{
        [key: string]: SurveyQuestionResponse
    }>({})
    const [surveyQuestionByQuestionId, setSurveyQuestionById] = useState<{
        [key: string]: SurveyQuestion
    }>({})

    useEffect(() => {
        setSurveyResponse(props.surveyResponse)

        const tempMap1: { [key: string]: SurveyQuestion } = {}
        props.surveyQuestions?.forEach(sq => {
            tempMap1[sq._id] = sq
        })
        setSurveyQuestionById(tempMap1)

        const tempMap2: { [key: string]: SurveyQuestionResponse } = {}
        props.surveyResponse?.responses?.forEach(response => {
            tempMap2[response.surveyQuestionId] = response
        })

        setSurveyQuestionResponseByQuestionId(tempMap2)

    }, [props.surveyResponse, props.surveyQuestions])

    const getQuestionsComponent = () => {
        return Object.keys(surveyQuestionByQuestionId).length > 0 ? (
            <div
                className={styles.question_options_container}
                data-testid={TEST_IDS.SURVEY_RESPONSE_PAGE.question_container_id}
            >
                {
                    Object.values(surveyQuestionByQuestionId).map((surveyQuestion, i) => {
                        const surveyQuestionResponse = surveyQuestionResponseByQuestionId[surveyQuestion._id]
                        const parsedQuestionStatement = surveyQuestion?.statement
                        return (
                            <div
                                id={`surveyQuestion_${surveyQuestion._id}`}
                                key={surveyQuestion._id}
                                className={styles.question_container}
                            >
                                <Typography.Title
                                    data-testid={`${TEST_IDS.SURVEY_RESPONSE_PAGE.question_id}_${i}`}
                                    style={{ color: 'white' }} level={3}>
                                    {i + 1}. {parsedQuestionStatement}
                                    {
                                        surveyQuestion?.required ? <span style={{ color: 'white' }}> *</span> : null
                                    }
                                </Typography.Title>
                                {
                                    surveyQuestion?.type === SurveyQuestionType.SLIDER ? (

                                        <div
                                            className={styles.question_options}
                                        >
                                            {

                                                surveyQuestion?.choices?.map(choice => {
                                                    return (
                                                        <Button
                                                            onClick={() => {

                                                                const tempMap: { [key: string]: SurveyQuestionResponse } = JSON.parse(JSON.stringify(surveyQuestionResponseByQuestionId))

                                                                /**
                                                                 * 19/7/2022 daniel.kwok
                                                                 * If re-click the same value, de-select it
                                                                 */
                                                                if (tempMap[surveyQuestion._id].answer === choice.value) {
                                                                    tempMap[surveyQuestion._id].answer = null
                                                                } else {
                                                                    tempMap[surveyQuestion._id].answer = choice.value
                                                                }

                                                                setSurveyQuestionResponseByQuestionId(tempMap)
                                                            }}
                                                            ghost
                                                            shape='round'
                                                            style={{
                                                                width: '100%',
                                                                height: 40,
                                                                color: surveyQuestionResponse.answer === choice.value ? '#99f78d' : 'grey',
                                                                borderColor: surveyQuestionResponse.answer === choice.value ? '#99f78d' : 'grey',
                                                                borderWidth: 3,
                                                                fontWeight: 'bold'
                                                            }}
                                                            key={choice.label}
                                                            data-testid={`${TEST_IDS.SURVEY_RESPONSE_PAGE.answer_btn_id}_${i}`}
                                                        >
                                                            {choice.label}
                                                        </Button>
                                                    )
                                                })
                                            }
                                        </div>
                                    ) : surveyQuestion?.type === SurveyQuestionType.TEXT ? (

                                        <Input.TextArea
                                            onChange={(e) => {

                                                const tempMap: { [key: string]: SurveyQuestionResponse } = JSON.parse(JSON.stringify(surveyQuestionResponseByQuestionId))
                                                tempMap[surveyQuestion._id].answer = e.target.value
                                                setSurveyQuestionResponseByQuestionId(tempMap)
                                            }}
                                            data-testid={`${TEST_IDS.SURVEY_RESPONSE_PAGE.answer_text_id}_${i}`}
                                            value={surveyQuestionResponse.answer?.toString()}
                                            placeholder={`Write your thoughts here!`}
                                        />
                                    ) : null
                                }
                            </div>
                        )
                    })
                }
            </div>
        ) : <Skeleton active />
    }

    const onSaveAsDraft = async () => {
        if (!surveyResponse) return null

        try {
            /**
             * 10/6/2022 daniel.kwok
             * Massage data structure
             */
            surveyResponse.responses = Object.values(surveyQuestionResponseByQuestionId)

            const req: SaveSurveyResponseAsDraftRequest = {
                surveyResponse: surveyResponse
            }

            const res = await saveSurveyResponseAsDraft(req)
            if (!res.success) throw new Error(res.message)
            message.success('Saved!')

            window.location.pathname = '/survey'

        } catch (err) {

            message.error(typeof err === 'string' ? err : typeof err === 'object' ? err?.toString() : `Something went wrong`)
        }
    }

    const onSubmit = async () => {
        if (!surveyResponse) return null

        // check if all required questions are answered. If not, scroll to question.
        const firstRequiredButNotAnsweredQuestionId = Object.keys(surveyQuestionByQuestionId).find(surveyQuestionId => {
            const surveyQuestion = surveyQuestionByQuestionId[surveyQuestionId]
            const surveyQuestionResponse = surveyQuestionResponseByQuestionId[surveyQuestionId]
            return surveyQuestion.required && (surveyQuestionResponse.answer === null || surveyQuestionResponse.answer === undefined)
        })

        const elementId = `surveyQuestion_${firstRequiredButNotAnsweredQuestionId}`
        var element = document.getElementById(elementId);

        if (element) {
            message.warn("It looks like you forgot to answer a required question. Please provide an answer before proceeding.")
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            return
        }

        Modal.confirm({
            content: <>{t(SurveyResponsePageKeys.surveyResponsePage_confirmString)}</>,
            onOk: async () => {
                /**
                 * 11/6/2022 daniel.kwok
                 * Massage data structure
                 */
                surveyResponse.responses = Object.values(surveyQuestionResponseByQuestionId)

                const req: SaveSurveyResponseAsDraftRequest = {
                    surveyResponse: surveyResponse
                }
                finishSurveyResponse(req)
                    .then(res => {
                        if (!res.success) throw new Error(res.message)
                        message.success('Submitted!')

                        window.location.pathname = '/survey/end'
                    })
                    .catch(err => message.error(err.message))

            }
        })
    }

    const requiredQuestionsLength = Object.values(surveyQuestionByQuestionId).filter(q => q.required).length
    const answeredLength = Object.values(surveyQuestionResponseByQuestionId).filter(r => r.answer).length
    const completionPercentage = (answeredLength / requiredQuestionsLength) * 100

    return (
        <div className={styles.container}>
            <div
                style={{
                    margin: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    maxWidth: 700,
                }}
            >
                {
                    surveyResponse?.status === SurveyResponseStatusLabels.DRAFT ? (
                        <p
                            style={{
                                color: 'grey',
                                fontStyle: 'italic'
                            }}
                        >
                            Last updated: {parseTime(surveyResponse?.updatedAt)}
                        </p>
                    ) : null
                }
                {
                    getQuestionsComponent()
                }
            </div>

            <div
                style={{
                    position: 'fixed',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    backgroundColor: 'white',
                    zIndex: 99,
                }}
            >

                <div
                    className={styles.footer_container}
                >
                    <Progress
                        showInfo={false}
                        percent={completionPercentage}
                        strokeColor={'black'}
                    />
                    <div
                        className={styles.footer_buttons_container}
                    >
                        <Tooltip
                            title={<p>
                                <>{t(SurveyResponsePageKeys.surveyResponsePage_saveProgressPrompt)}</>
                                <br />
                                <>{t(SurveyResponsePageKeys.surveyResponsePage_accessibleFromEmailPrompt)}</>
                            </p>}
                        >

                            <Button
                                type='link'
                                style={{ color: 'black' }}
                                onClick={() => { onSaveAsDraft() }}
                            >
                                <>{t(SurveyResponsePageKeys.surveyResponsePage_saveAsDraftBtn)}</>
                            </Button>
                        </Tooltip>
                        <Button
                            type='primary'
                            className={styles.button}
                            data-testid={TEST_IDS.SURVEY_RESPONSE_PAGE.submit_btn_id}
                            onClick={() => { onSubmit() }}
                        >
                            <>{t(SurveyResponsePageKeys.surveyResponsePage_submitBtn)}</>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}