import { SurveyCampaignFormKeys } from "./keys"

export const englishValues = {
    [SurveyCampaignFormKeys.surveyCampaignForm_runASurveyString]: 'Run a survey',
    [SurveyCampaignFormKeys.surveyCampaignForm_basicInfoString]: 'Basic info',
    [SurveyCampaignFormKeys.surveyCampaignForm_campaignNameString]: 'Campaign name',
    [SurveyCampaignFormKeys.surveyCampaignForm_saveBtn]: 'Save',
    [SurveyCampaignFormKeys.surveyCampaignForm_nextBtn]: 'Next',
    [SurveyCampaignFormKeys.surveyCampaignForm_backBtn]: 'Back',
    [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesString]: 'Upload employees',
    [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesTitleString]: 'Upload employees',
    [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesDownloadString]: 'Download a ',
    [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesSampleCSVLink]: 'sample CSV template',
    [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesExampleString]: 'to see an example of the format required.',
    [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesSupportedFormatsString]: 'Supported formats: xlxs, csv',
    [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesOverridePrompt]: 'This will override existing data. Confirm?',
    [SurveyCampaignFormKeys.surveyCampaignForm_errorsFoundString]: 'errors found.',
    [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesGoodToGoPrompt]: 'All good to go!',
    [SurveyCampaignFormKeys.surveyCampaignForm_addAnEmployeeBtn]: 'Add an employee',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesCSVNoDataFoundString]: 'No data found',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesFirstNameTableHeader]: 'First name',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesLastNameTableHeader]: 'Last name',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesPositionTableHeader]: 'Position',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesEmailTableHeader]: 'Email',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesTeamTableHeader]: 'Team',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesGenderTableHeader]: 'Gender',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesErrorsTableHeader]: 'Errors',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesActionTableHeader]: 'Action',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddNewEmployeeString]: 'Add a new employee',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddedString]: 'Added',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormFirstNameString]: 'First Name',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormLastNameString]: 'Last Name',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormEmailString]: 'Email',
    [SurveyCampaignFormKeys.surveyCampaignForm_emailMustEndString]: 'Email must end with one of',
    [SurveyCampaignFormKeys.surveyCampaignForm_egJohnDoeString]: '. E.g. johndoe@',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormGenderString]: 'Gender',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormPositionString]: 'Position',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormTeamString]: 'Team',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormDeleteBtn]: 'Delete',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormSaveBtn]: 'Save',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddConfirmDeletePrompt]: 'Confirm delete?',
    [SurveyCampaignFormKeys.surveyCampaignForm_deletedString]: 'Deleted',
    [SurveyCampaignFormKeys.surveyCampaignForm_updatedString]: 'Updated',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormFirstNameString]: 'First Name',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormLastNameString]: 'Last Name',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormEmailString]: 'Email',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormGenderString]: 'Gender',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormPositionString]: 'Position',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormTeamString]: 'Team',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormDeleteBtn]: 'Delete',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormSaveBtn]: 'Save',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditConfirmDeletePrompt]: 'Confirm delete?',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewAndSendString]: 'Preview and send',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewAndSendTitleString]: 'Preview and send',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewUploadedEmployeesString]: 'Uploaded employees ',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewFirstNameTableHeader]: 'First name',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewLastNameTableHeader]: 'Last name',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewPositionTableHeader]: 'Position',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewEmailTableHeader]: 'Email',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewTeamTableHeader]: 'Team',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewGenderTableHeader]: 'Gender',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewMoreActionsDropdown]: 'More actions',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewExampleEmailString]: 'Example email for',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewRefreshDropdown]: 'Refresh',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewEditTemplateDropdown]: ' Edit template',
    [SurveyCampaignFormKeys.surveyCampaignForm_faqString]: 'FAQ',
    [SurveyCampaignFormKeys.surveyCampaignForm_whatIsLinkString]: 'What is [$link]?',
    [SurveyCampaignFormKeys.surveyCampaignForm_uniqueLinkString]: 'This is the unique link for each survey. Will only be generated once "Send" is clicked.',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewBackBtn]: 'Back',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewDraftBtn]: 'Save as draft',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewSendBtn]: 'Send',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewSendAreYouSurePrompt]: 'Are you sure?',
    [SurveyCampaignFormKeys.surveyCampaignForm_sendingSurveyPrompt]: 'Sending survey',
    [SurveyCampaignFormKeys.surveyCampaignForm_surveyCampaignStartedPrompt]: 'Survey campaign started!',
    [SurveyCampaignFormKeys.surveyCampaignForm_surveyCampaignBreadcrumb]: 'Survey Campaign',
}

export const malayValues = {
    [SurveyCampaignFormKeys.surveyCampaignForm_runASurveyString]: 'Jalankan tinjauan',
    [SurveyCampaignFormKeys.surveyCampaignForm_basicInfoString]: 'Info asas',
    [SurveyCampaignFormKeys.surveyCampaignForm_campaignNameString]: 'Nama kempen',
    [SurveyCampaignFormKeys.surveyCampaignForm_saveBtn]: 'Simpan',
    [SurveyCampaignFormKeys.surveyCampaignForm_nextBtn]: 'Seterusnya',
    [SurveyCampaignFormKeys.surveyCampaignForm_backBtn]: 'Kembali',
    [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesString]: 'Memuat naik pekerja',
    [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesTitleString]: 'Memuat naik pekerja',
    [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesDownloadString]: 'Muat turun ',
    [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesSampleCSVLink]: 'templat sampel CSV ',
    [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesExampleString]: ' untuk lihat contoh format yang ditetapkan.',
    [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesSupportedFormatsString]: 'Format yang disokong: xlxs, csv',
    [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesOverridePrompt]: 'Tindakan ini akan menggantikan data yang sedia ada. Sahkan?',
    [SurveyCampaignFormKeys.surveyCampaignForm_errorsFoundString]: 'ralat ditemui.',
    [SurveyCampaignFormKeys.surveyCampaignForm_uploadEmployeesGoodToGoPrompt]: 'Data sudah disediakan!',
    [SurveyCampaignFormKeys.surveyCampaignForm_addAnEmployeeBtn]: 'Tambah pekerja',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesCSVNoDataFoundString]: 'Tiada data dijumpai',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesFirstNameTableHeader]: 'Nama pertama',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesLastNameTableHeader]: 'Nama keluarga',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesPositionTableHeader]: 'Jawatan',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesEmailTableHeader]: 'E-mel',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesTeamTableHeader]: 'Pasukan',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesGenderTableHeader]: 'Jantina',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesErrorsTableHeader]: 'Ralat',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesActionTableHeader]: 'Tindakan',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddNewEmployeeString]: 'Tambah pekerja baharu',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddedString]: 'Ditambahkan',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormFirstNameString]: 'Nama pertama',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormLastNameString]: 'Nama keluarga',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormEmailString]: 'E-mel',
    [SurveyCampaignFormKeys.surveyCampaignForm_emailMustEndString]: 'E-mel mesti berakhir dengan salah satu daripada',
    [SurveyCampaignFormKeys.surveyCampaignForm_egJohnDoeString]: '. Contohnya johndoe@',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormGenderString]: 'Jantina',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormPositionString]: 'Jawatan',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormTeamString]: 'Pasukan',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormDeleteBtn]: 'Padam',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddFormSaveBtn]: 'Simpan',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesAddConfirmDeletePrompt]: 'Sahkan padam?',
    [SurveyCampaignFormKeys.surveyCampaignForm_deletedString]: 'Dipadamkan',
    [SurveyCampaignFormKeys.surveyCampaignForm_updatedString]: 'Dikemaskinikan',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormFirstNameString]: 'Nama pertama',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormLastNameString]: 'Nama keluarga',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormEmailString]: 'E-mel',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormGenderString]: 'Jantina',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormPositionString]: 'Jawatan',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormTeamString]: 'Pasukan',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormDeleteBtn]: 'Padam',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditFormSaveBtn]: 'Simpan',
    [SurveyCampaignFormKeys.surveyCampaignForm_employeesEditConfirmDeletePrompt]: 'Sahkan padam?',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewAndSendString]: 'Pratonton dan hantar',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewAndSendTitleString]: 'Pratonton dan hantar',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewUploadedEmployeesString]: 'Pekerja yang dimuat naik ',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewFirstNameTableHeader]: 'Nama pertama',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewLastNameTableHeader]: 'Nama keluarga',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewPositionTableHeader]: 'Jawatan',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewEmailTableHeader]: 'E-mel',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewTeamTableHeader]: 'Pasukan',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewGenderTableHeader]: 'Jantina',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewMoreActionsDropdown]: 'Tindakan lanjut',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewExampleEmailString]: 'Contoh e-mel untuk',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewRefreshDropdown]: 'Muat semula',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewEditTemplateDropdown]: ' Edit templat',
    [SurveyCampaignFormKeys.surveyCampaignForm_faqString]: 'Soalan lazim',
    [SurveyCampaignFormKeys.surveyCampaignForm_whatIsLinkString]: 'Apakah itu [$link]?',
    [SurveyCampaignFormKeys.surveyCampaignForm_uniqueLinkString]: 'Ini ialah pautan yang unik untuk setiap tinjauan. Pautan akan dijanakan selepas tekan "Hantar".',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewBackBtn]: 'Kembali',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewDraftBtn]: 'Simpan sebagai draf',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewSendBtn]: 'Hantar',
    [SurveyCampaignFormKeys.surveyCampaignForm_previewSendAreYouSurePrompt]: 'Adakah anda pasti?',
    [SurveyCampaignFormKeys.surveyCampaignForm_sendingSurveyPrompt]: 'Tinjauan sedang dihantar',
    [SurveyCampaignFormKeys.surveyCampaignForm_surveyCampaignStartedPrompt]: 'Kempen tinjauan telah dimulakan!',
    [SurveyCampaignFormKeys.surveyCampaignForm_surveyCampaignBreadcrumb]: 'Kempen Tinjauan',
}