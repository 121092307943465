import { EditProfilePageKeys } from "./keys"

export const englishValues = {
    [EditProfilePageKeys.editProfilePage_editProfileTitle]: 'Edit profile',
    [EditProfilePageKeys.editProfilePage_userTab]: 'User',
    [EditProfilePageKeys.editProfilePage_userProfileTabTitle]: 'User profile',
    [EditProfilePageKeys.editProfilePage_userProfileNameString]: 'Name',
    [EditProfilePageKeys.editProfilePage_userProfileEmailString]: 'Email',
    [EditProfilePageKeys.editProfilePage_userProfileGenderString]: 'Gender',
    [EditProfilePageKeys.editProfilePage_userProfileMobileString]: 'Mobile',
    [EditProfilePageKeys.editProfilePage_userProfileSaveBtn]: 'Save',
    [EditProfilePageKeys.editProfilePage_companyTab]: 'Company',
    [EditProfilePageKeys.editProfilePage_companyTabTitle]: 'Company',
    [EditProfilePageKeys.editProfilePage_companyChangeLogoString]: 'Change logo',
    [EditProfilePageKeys.editProfilePage_companyUpdateLogoPrompt]: 'Update company logo',
    [EditProfilePageKeys.editProfilePage_companyEmailDomainString]: 'Email Domain',
    [EditProfilePageKeys.editProfilePage_companyEmailDomainToolTip]: 'The portion after "@" of your company email. E.g. if your company email is johndoe@acme.com, your email domain would be acme.com',
    [EditProfilePageKeys.editProfilePage_companyNameString]: 'Name',
    [EditProfilePageKeys.editProfilePage_companyNamePlaceholder]: 'Insert company name',
    [EditProfilePageKeys.editProfilePage_companyIndustryString]: 'Industry',
    [EditProfilePageKeys.editProfilePage_companyIndustryPlaceholder]: 'Insert industry',
    [EditProfilePageKeys.editProfilePage_companyLocationString]: 'Location',
    [EditProfilePageKeys.editProfilePage_companyLocationPlaceholder]: 'Insert company location',
    [EditProfilePageKeys.editProfilePage_companySizeString]: 'Company Size',
    [EditProfilePageKeys.editProfilePage_companySaveBtn]: 'Save',
    [EditProfilePageKeys.editProfilePage_companySaveCompanyMissingErrorMessage]: 'Something went wrong. Please refresh and try again?',
    [EditProfilePageKeys.editProfilePage_companySaveCompanySuccessMessage]: 'Successfully updated company.',
    [EditProfilePageKeys.editProfilePage_resetPasswordTab]: 'Reset password',
    [EditProfilePageKeys.editProfilePage_resetPasswordTabTitle]: 'Reset password',
    [EditProfilePageKeys.editProfilePage_resetPasswordOldString]: 'Old password',
    [EditProfilePageKeys.editProfilePage_resetPasswordNewString]: 'New password',
    [EditProfilePageKeys.editProfilePage_resetPasswordConfirmString]: 'Confirm new password',
    [EditProfilePageKeys.editProfilePage_resetPasswordResetBtn]: 'Reset',
}

export const malayValues = {
    [EditProfilePageKeys.editProfilePage_editProfileTitle]: 'Edit profil',
    [EditProfilePageKeys.editProfilePage_userTab]: 'Pengguna',
    [EditProfilePageKeys.editProfilePage_userProfileTabTitle]: 'Profil pengguna',
    [EditProfilePageKeys.editProfilePage_userProfileNameString]: 'Nama',
    [EditProfilePageKeys.editProfilePage_userProfileEmailString]: 'E-mel',
    [EditProfilePageKeys.editProfilePage_userProfileGenderString]: 'Jantina',
    [EditProfilePageKeys.editProfilePage_userProfileMobileString]: 'Telefon bimbit',
    [EditProfilePageKeys.editProfilePage_userProfileSaveBtn]: 'Simpan',
    [EditProfilePageKeys.editProfilePage_companyTab]: 'Syarikat',
    [EditProfilePageKeys.editProfilePage_companyTabTitle]: 'Syarikat',
    [EditProfilePageKeys.editProfilePage_companyChangeLogoString]: 'Tukar logo',
    [EditProfilePageKeys.editProfilePage_companyUpdateLogoPrompt]: 'Kemaskini logo syarikat',
    [EditProfilePageKeys.editProfilePage_companyEmailDomainString]: 'Domain E-mel',
    [EditProfilePageKeys.editProfilePage_companyEmailDomainToolTip]: 'Bahagian selepas "@" pada e-mel syarikat anda. Contohnya, jika e-mel syarikat anda ialah johndoe@acme.com, domain e-mel anda ialah acme.com',
    [EditProfilePageKeys.editProfilePage_companyNameString]: 'Nama',
    [EditProfilePageKeys.editProfilePage_companyNamePlaceholder]: 'Isikan nama syarikat',
    [EditProfilePageKeys.editProfilePage_companyIndustryString]: 'Industri',
    [EditProfilePageKeys.editProfilePage_companyIndustryPlaceholder]: 'Isikan industri',
    [EditProfilePageKeys.editProfilePage_companyLocationString]: 'Lokasi',
    [EditProfilePageKeys.editProfilePage_companyLocationPlaceholder]: 'Isikan lokasi syarikat',
    [EditProfilePageKeys.editProfilePage_companySizeString]: 'Saiz Syarikat',
    [EditProfilePageKeys.editProfilePage_companySaveBtn]: 'Simpan',
    [EditProfilePageKeys.editProfilePage_companySaveCompanyMissingErrorMessage]: 'Sesuatu telah berlaku. Sila muat semula dan cuba lagi?',
    [EditProfilePageKeys.editProfilePage_companySaveCompanySuccessMessage]: 'Berjaya mengemas kini syarikat',
    [EditProfilePageKeys.editProfilePage_resetPasswordTab]: 'Set semula kata laluan',
    [EditProfilePageKeys.editProfilePage_resetPasswordTabTitle]: 'Set semula kata laluan',
    [EditProfilePageKeys.editProfilePage_resetPasswordOldString]: 'Kata laluan semasa',
    [EditProfilePageKeys.editProfilePage_resetPasswordNewString]: 'Kata laluan baharu',
    [EditProfilePageKeys.editProfilePage_resetPasswordConfirmString]: 'Sahkan kata laluan baharu',
    [EditProfilePageKeys.editProfilePage_resetPasswordResetBtn]: 'Set semula',
}