import { FeedbackGroupQuestionReportKeys } from "./keys"

export const englishValues = {
    [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_groupReportTitleString]: 'Group Report',
    [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_surveyCampaignString]: 'Survey Campaign',
    [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_surveyString]: 'Survey',
    [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_lastUpdatedString]: 'Last updated',
    [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_downloadAsPDFTooltip]: 'Download as PDF',
    [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_comparisonString]: 'Comparison',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualView_emailTableHeader]: 'Email',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualView_actionsTableHeader]: 'Actions',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualView_viewReportLink]: 'View report',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualView_positionTableHeader]: 'Position',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualView_lastUpdatedTableHeader]: 'Last updated',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualView_selectReportString]: 'Select a report from the left',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_individualReportTitleString]: 'Individual Report',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_genderString]: 'Gender',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_emailString]: 'Email',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_responseIDString]: 'Response ID',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_surveyCampaignString]: 'Survey Campaign',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_surveyString]: 'Survey',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_lastUpdatedString]: 'Last updated',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_downloadAsPDFTooltip]: 'Download as PDF',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_comparisonString]: 'Comparison',

}

export const malayValues = {
    [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_groupReportTitleString]: 'Laporan Kumpulan',
    [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_surveyCampaignString]: 'Kempen Tinjauan',
    [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_surveyString]: 'Tinjauan',
    [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_lastUpdatedString]: 'Dikemas kini pada',
    [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_downloadAsPDFTooltip]: 'Muat turun sebagai PDF',
    [FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_comparisonString]: 'Perbandingan',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualView_emailTableHeader]: 'E-mel',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualView_actionsTableHeader]: 'Tindakan',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualView_viewReportLink]: 'Tunjukkan laporan',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualView_positionTableHeader]: 'Jawatan',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualView_lastUpdatedTableHeader]: 'Dikemas kini pada',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualView_selectReportString]: 'Pilih laporan di sebelah kiri',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_individualReportTitleString]: 'Laporan Individu',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_genderString]: 'Jantina',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_emailString]: 'E-mel',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_responseIDString]: 'ID Respons',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_surveyCampaignString]: 'Kempen Tinjauan',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_surveyString]: 'Tinjauan',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_lastUpdatedString]: 'Dikemas kini pada',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_downloadAsPDFTooltip]: 'Muat turun sebagai PDF',
    [FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_comparisonString]: 'Perbandingan',

}