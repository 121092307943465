import { Tag } from 'antd'
import React from 'react'
import { POSITION_COLORS } from '../constants/misc'
import { Positions } from '../constants/types'

interface Prop {
  position?: Positions | string
  team?: string
}

export default function PositionsTag(props: Prop) {
  switch (props.position) {
    case Positions.LineManager:
      return <Tag color={POSITION_COLORS['Line Manager']}>
        {Positions.LineManager}
        {props.team ? ` (${props.team})` : undefined}
      </Tag>
    case Positions.Participant:
      return <Tag color={POSITION_COLORS.Participant}>
        {Positions.Participant}
        {props.team ? ` (${props.team})` : undefined}
      </Tag>
    case Positions.Reports:
      return <Tag color={POSITION_COLORS.Reports}>
        {Positions.Reports}
        {props.team ? ` (${props.team})` : undefined}
      </Tag>
    case Positions.Team:
      return <Tag color={POSITION_COLORS.Team}>
        {Positions.Team}
        {props.team ? ` (${props.team})` : undefined}
      </Tag>
    default:
      return <Tag>
        {props.position}
      </Tag>
  }
}
