import { Skeleton } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import logo from '../../assets/logo.png'
import FeedbackGroupQuestionReport from '../../components/QuestionReport/FeedbackGroupQuestionReport'
import { GetCompanyByCompanyIdRequest, GetSurveyCampaignBySurveyCampaignIdRequest, GetSurveyTemplatesBySurveyTemplateIdRequest } from '../../constants/apiRequestResponse'
import { COLORS } from '../../constants/misc'
import { Company, SurveyTemplate } from '../../constants/types'
import { getCompanyByCompanyId, getSurveyCampaignBySurveyCampaignId, getSurveyTemplateBySurveyTemplateId } from '../../services/api'
import { getQueryParam } from '../../util'

export default function FeedbackGroupPrintView() {


    const surveyCampaignId = getQueryParam('surveyCampaignId') || undefined
    const [isLoading, setIsLoading] = useState(false)
    const [company, setCompany] = useState<Company | undefined>()
    const [surveyTemplate, setSurveyTemplate] = useState<SurveyTemplate | undefined>()

    const getData = useCallback(async () => {
        try {
            setIsLoading(true)
            /**Get survey campaign */
            const req2: GetSurveyCampaignBySurveyCampaignIdRequest = {
                surveyCampaignId: surveyCampaignId
            }
            const res2 = await getSurveyCampaignBySurveyCampaignId(req2)
            if (!res2.success) throw res2.message

            /**Get company */
            const companyId = res2.surveyCampaign.companyId
            const req3: GetCompanyByCompanyIdRequest = {
                companyId: companyId
            }
            const res3 = await getCompanyByCompanyId(req3)
            if (!res3.success) throw res3.message
            setCompany(res3.company)

            /**Get survey template */
            const req4: GetSurveyTemplatesBySurveyTemplateIdRequest = {
                surveyTemplateId: res2.surveyCampaign.surveyTemplateId
            }
            const res4 = await getSurveyTemplateBySurveyTemplateId(req4)
            if (!res4.success) throw res4.message
            setSurveyTemplate(res4.surveyTemplate)
        } catch (err) {
            console.log(err)
        }

        setIsLoading(false)
    }, [surveyCampaignId])

    useEffect(() => {
        getData()
    }, [surveyCampaignId, getData])


    if (isLoading) {
        return (
            <div
                style={styles.page}
            >
                <Skeleton active />
            </div>
        )
    }

    return (
        <>
            <div
                style={{
                    ...styles.page,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}
            >
                <div>
                    <img alt="" src={company?.logo} height={150} />

                    <div
                        style={{
                            marginTop: 100
                        }}
                    >
                        <p
                            style={{ ...styles.survey_template_title, color: COLORS.PRIMARY_COLOR, }}
                        >
                            {surveyTemplate?.name}
                        </p>
                        <p
                            style={styles.report_type_title}
                        >
                            Group Report
                        </p>
                        <br />
                        <h2 style={{ color: 'grey' }}>
                            {moment().format('Do MMMM YYYY')}
                        </h2>
                    </div>
                </div>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                    }}
                >
                    <div
                        style={{
                            width: '30%'
                        }}
                    >
                        <p><b>This report was prepared by</b></p>

                        <img alt="" src={logo} height={100} />
                    </div>

                    <div
                        style={{
                            width: '30%'
                        }}
                    >
                        <p><b>Confidential</b></p>
                        <p style={{ color: 'grey' }}>
                            This document contains confidential information that should not be shared with unauthorised persons
                        </p>
                    </div>
                </div>
            </div>

            <div
                style={styles.page}
            >
                <h1>
                    <b>As introductory statement for this assesment</b>
                </h1>
                <p>
                    This is merely mock copy to illustrate how large
                    bodies of text look in this layout. This page is
                    optional. The fonts used in the pdf would be the
                    same as the site. the font family is “Lato”. The
                    use of different weight fonts negate the need
                    to have multiple families or font pairing.
                    Holisticly drive compelling infrastructures
                    before interoperable vortals. Interactively

                    evolve market-driven results vis-a-vis cross-
                    media quality vectors. Competently incentivize

                    distinctive best practices for B2C ROI.
                    Collaboratively whiteboard user friendly
                    results for installed base intellectual capital.
                    Monotonectally create functionalized action
                    items without clicks-and-mortar web services.
                    Intrinsicly negotiate cost effective benefits
                    after superior leadership. Holisticly
                    whiteboard long-term high-impact web
                    services for just in time opportunities.
                    Dynamically engineer excellent collaboration
                    and idea-sharing after fully researched
                    intellectual capital. Interactively iterate timely
                    potentialities rather than bricks-and-clicks
                    strategic theme areas. Holisticly synthesize

                    web-enabled supply chains through stand-
                    alone outsourcing.
                </p>
                <br />
                <p>

                    Appropriately engage cost effective
                    partnerships whereas leading-edge platforms.
                    Completely foster compelling communities for
                    revolutionary alignments. Dramatically
                    empower just in time leadership without
                    standardized technologies. Globally e-enable
                    enterprise-wide services vis-a-vis cooperative
                    paradigms. Dramatically promote enabled
                    vortals through clicks-and-mortar testing
                    procedures.
                    Continually enhance quality portals after
                    standardized innovation. Interactively
                    monetize an expanded array of leadership
                    without cross-unit niche markets. Progressively
                    expedite high-payoff expertise for resource
                    maximizing manufactured products.
                    Synergistically reintermediate multifunctional
                    products without turnkey collaboration and
                    idea-sharing. Authoritatively promote viral
                    portals via emerging processes.
                    Intrinsicly enhance cutting-edge models
                    whereas user friendly total linkage.
                    Conveniently restore accurate portals vis-a-vis
                    fully researched imperatives. Synergistically
                    productize prospective data vis-a-vis.
                </p>
            </div>
            <div
                style={styles.page}
            >
                <FeedbackGroupQuestionReport
                    surveyCampaignId={surveyCampaignId}
                />
            </div>

        </>
    )
}

const styles = {
    page: {
        minHeight: '100vh',
        minWidht: '100vw',
        backgroundColor: 'white',
        padding: 30,
    },
    survey_template_title: {
        fontSize: 60,
        fontWeight: 500,
    },
    report_type_title: {
        fontSize: 80,
        fontWeight: 1000,
        lineHeight: 1,
    }
}