/**
 * 9/6/2022 daniel.kwok
 * READ ME FIRST
 * 
 * Any and all fields prefixed with "_", e.g. _isAbleToResend
 * means it's a frontend-only, derived field.
 * 
 * Backend has no knowledge of it, and this value isn't stored in db as well.
 */


/** TODO daniel.kwok
 * possible to convert to i18tn compatible for these labels? or am I using incorrect method to assign keys for these?
 * -sherlyn
 */

export enum Gender {
    Male = 'Male',
    Female = 'Female'
}

export enum IsResetPasswordCodeValidFailureCode {
    expired = 'expired',
    invalid = 'invalid',
}

export type CompanyUser = {
    _id: string,
    name: string,
    companyId: string,
    gender?: Gender
    email: string,
    mobile: string,
    role?: Roles,
    createdAt?: number,
    updatedAt?: number,
}

export type Company = {
    _id: string,
    emailDomains: string[],
    isAdmin?: boolean,
    subDomain?: string,
    helpEmail: string,
    name: string,
    logo: string,
    industry?: string,
    size: CompanySizes,
    location?: string,
    remark?: string,
    createdAt?: number,
    updatedAt?: number,
}

export enum CompanySizes {
    micro = "1 - 9",
    small = "1 - 49",
    medium = "50 - 99",
    large = "100 - 499",
    enterprise = ">500"
}

export type BasicCompany = {
    name: string,
    logo: string,
}

export enum SurveyTemplateType {
    normal = "Normal",
    /**
     * 16/4/2022 daniel.kwok 
     * "feedback" means 360 surveys.
     * Can't put number as keys, so yea
     */
    feedback = "360"
}

export type SurveyTemplate = {
    _id: string,
    companyId: string,
    name: string,
    description: string,
    type: SurveyTemplateType,
    questions: SurveyQuestion[];
    createdAt?: number,
    updatedAt?: number,

    order?: number,

    prompt: string,
    iconUrl: string,
    active?: boolean;
}

export type BasicSurveyTemplate = {
    name: string,
    type: SurveyTemplateType,
}

export type SurveyQuestion = {
    _id: string;
    type: SurveyQuestionType;
    limit?: {
        min: number;
        max: number;
    };
    statement: string;
    choices?: {
        label: string;
        value: number;
    }[];
    attachment: string;
    default?: string | boolean | number | null; // by right, this field should be a generic field, it can be number, string, list
    required: boolean;
    placeholder?: string;
    description?: string;
    benchmarkScore?: number;
    category?: string[]
    extNormFavourable?: number,
};
export enum SurveyQuestionType {
    RADIO = "radio",
    SELECT = "select",
    TEXT = "text",
    SLIDER = "slider",
}

export type SurveyTemplateQuestionBenchmark = {
    _id: string,
    surveyTemplateQuestionId: string,
    answer: string | number | boolean | null,
}

export type SurveyCampaign = {
    _id?: string;
    surveyTemplateId: string;
    companyId?: string;
    name: string;
    status?: SurveyCampaignStatusLabels;
    prevStatus?: SurveyCampaignStatusLabels;

    // survey questionaire snapshot from template
    questions: SurveyQuestion[];
    prompt?: string;

    // time sensitive information
    startedAt?: number;
    plannedEndAt?: number;
    endedAt?: number;
    createdAt?: number;
    updatedAt?: number;
}
export enum SurveyCampaignStatusLabels {
    draft = 'Draft',
    ongoing = 'Ongoing',
    completed = 'Completed',
    deleted = 'Deleted',
}

export type BasicSurveyCampaign = {
    name: string;
    status?: SurveyCampaignStatusLabels;
}

export type SurveyResponse = {
    _id: string,
    surveyCampaignId: string;
    status: SurveyResponseStatusLabels;

    surveyEmployee: SurveyEmployee;
    responses: SurveyQuestionResponse[];

    createdAt?: number,
    updatedAt?: number,

    _isAbleToResend: boolean,
    _isAbleToCancel: boolean,
    _isAbleToReopen: boolean,
    _isAbleToViewReport: boolean,
    _totalNumberOfQuestions: number,
    _totalNumberOfAnsweredQuestions: number,
    _answerProgress: number,
}
export enum SurveyResponseStatusLabels {
    PENDING = "Pending",
    EMAIL_FAILED = "Failed",
    EMAIL_SENT = "Sent",
    DRAFT = "Draft",
    COMPLETED = "Completed",
    CANCELLED = "Cancelled",
}

export type SurveyQuestionResponse = {
    _id: string,
    surveyQuestionId: string;
    answer: string | boolean | number | null,
}

export enum Positions {
    Participant = "Participant",
    LineManager = "Line Manager",
    Team = "Team",
    Reports = "Reports"
}

export type SurveyEmployee = {
    _id?: string,
    fname: string,
    lname: string,
    email: string,
    gender: Gender,
    position: Positions | "",
    team: string,
    participantId?: string,
    createdAt?: number,
    updatedAt?: number,
}

// SurveyEmployee without extra fields
export type BasicSurveyEmployee = {
    fname: string,
    lname: string,
    position: Positions | "",
    team: string
}

export type GroupResponseStatistics = {
    surveyQuestionId: string;
    description: string;
    statement: string;
    benchmarkScore?: number;
    min?: number;
    max?: number;
    median?: number;
    mode?: number;
    average?: number;

    /**
     * 12/6/2022 daniel.kwok
     * Percentage is value / maxAllowableValue in range
     *
     * Only applicable for slider (I guess?)
     */
    minPercentage?: number;
    maxPercentage?: number;
    medianPercentage?: number;
    modePercentage?: number;
    averagePercentage?: number;
    benchmarkScorePercentage?: number;
}

export type IndividualResponseStatistics = {
    surveyQuestionId: string;
    description: string;
    statement: string;
    benchmarkScore?: number;
    answer: any;
    /**
     * 12/6/2022 daniel.kwok
     * Percentage is value / maxAllowableValue in range
     *
     * Only applicable for slider (I guess?)
     */
    answerPercentage?: number,
    benchmarkScorePercentage?: number;
}

export type GroupStatistics = {
    min?: number;
    max?: number;
    median?: number;
    mode?: number;
    average?: number;
    olympicScoring?: number

    // only application if question type is slider
    minPercentage?: number;
    maxPercentage?: number;
    medianPercentage?: number;
    modePercentage?: number;
    averagePercentage?: number;
    benchmarkScorePercentage?: number;
    olympicScoringPercentage?: number

    numberOfFinishedSurveyResponse?: number;
    numberOfCancelledSurveyResponse?: number;
    numberOfSurveyResponse?: number;
};

export interface IndividualResponse360Statistics extends GroupStatistics {
    surveyQuestionId: string;
    description: string;
    statement: string;
    type?: SurveyQuestionType;
    benchmarkScore?: number;
    chickenthigh: boolean;
    benchmarkScorePercentage?: number
    byPosition: {
        [key: string]: GroupStatistics;
    };
}

export interface GroupResponse360Statistics extends GroupStatistics {
    surveyQuestionId: string;
    description: string;
    statement: string;
    type?: SurveyQuestionType;
    benchmarkScore?: number;

    /**
     * 27/6/2022 daniel.kwok
     * Map statistics by employee positions
     * positions is dynamically extracted from surveyresponse.surveyemployee of this surveycampaign
     */
    byPosition: {
        [key: string]: GroupStatistics;
    };

    /**
     * 27/6/2022 daniel.kwok
     * Map statistics by employee team
     * team is dynamically extracted from surveyresponse.surveyemployee of this surveycampaign
     */
    byTeam: {
        [key: string]: GroupStatistics;
    };
}

export interface Response360StatisticsByTeam {
    [key: string]: {
        numberOfCompleted: number,
        numberOfCancelled: number,
        numberOfResponse: number,
        responseStatistics: TeamResponse360Statistics[]
    }
}
interface TeamResponse360Statistics extends GroupStatistics {

    surveyQuestionId: string;
    description: string;
    statement: string;
    type?: SurveyQuestionType;
    benchmarkScore?: number;

    /**
     * 27/6/2022 daniel.kwok
     * Map statistics by employee positions
     * positions is dynamically extracted from surveyresponse.surveyemployee of this surveycampaign
     */
    byPosition: {
        [key: string]: GroupStatistics;
    };
}

export type EmailTemplate = {
    _id?: string;
    name: string;
    description?: string;
    subject: string;

    // OSS URL
    attachmentLink?: string;

    // actual rich text content of email
    // might be markdown or html, not sure yet
    // variables are inserted into content directly
    // function to extract values of variables are defined in code
    content: string;

    // if this email template is system default, or belongs to a particular company
    // we'll always start off with a couple system defaults, and upon company creation
    // we'll make a copy of all email templates to the company
    isCustom: boolean;

    // company this template is linked to
    // related to isCustom field
    companyId?: string;

    // surveyCampaignId?: ObjectId; // deprecated
    surveyTemplateId?: string;
    position?: Positions | "";

    createdAt?: number;
    updatedAt?: number;
};


export interface EmailVariableGenericType {
    displayName: string;
    description?: string;
}

export type EmailVariables = {
    "[$participantName]"?: EmailVariableGenericType;
    "[$companyName]"?: EmailVariableGenericType;
    "[$teamName]"?: EmailVariableGenericType;
    "[$surveyCampaignName]"?: EmailVariableGenericType;
    "[$currentUserName]"?: EmailVariableGenericType;
};

export enum Roles {
    ADMIN = 'ADMIN',
    COMPANY_USER = 'COMPANY_USER'
}

export enum Rankings {
    unfavourable = "unfavourable",
    neutral = "neutral",
    favourable = "favourable",
    historicalFavourable = "historicalFavourable",
    extNormFavourable = "extNormFavourable"
}
export type CategoryGroupedStatistics = {
    [key: string]: {
        [Rankings.favourable]: number,
        [Rankings.neutral]: number,
        [Rankings.unfavourable]: number,
        [Rankings.historicalFavourable]: number
    }
}

export type TeamStatistics = {
    [key: string]: {
        [Rankings.favourable]: number,
        [Rankings.neutral]: number,
        [Rankings.unfavourable]: number,
        [Rankings.historicalFavourable]: number
        [Rankings.extNormFavourable]: number
    }
}

export type QuestionStatistics = {
    index: number,
    statement: string,
    isApplicable: boolean,
    [Rankings.favourable]: number,
    [Rankings.neutral]: number,
    [Rankings.unfavourable]: number,
    [Rankings.historicalFavourable]: number
    [Rankings.extNormFavourable]: number
}