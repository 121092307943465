import { CompanyUserLayoutKeys } from "./keys"

export const englishValues = {
    [CompanyUserLayoutKeys.companyUserLayout_surveysCampaignsString]: 'Surveys Campaigns',
    [CompanyUserLayoutKeys.companyUserLayout_morningGreetingString]: 'Good morning',
    [CompanyUserLayoutKeys.companyUserLayout_afternoonGreetingString]: 'Good afternoon',
    [CompanyUserLayoutKeys.companyUserLayout_eveningGreetingString]: 'Good evening',
    [CompanyUserLayoutKeys.companyUserLayout_reportString]: 'Report',
    [CompanyUserLayoutKeys.companyUserLayout_profileDropDownString]: 'Profile',
    [CompanyUserLayoutKeys.companyUserLayout_settingsDropDownString]: 'Settings',
    [CompanyUserLayoutKeys.companyUserLayout_logoutDropDownString]: 'Logout',
}

export const malayValues = {
    [CompanyUserLayoutKeys.companyUserLayout_surveysCampaignsString]: 'Kempen Tinjauan',
    [CompanyUserLayoutKeys.companyUserLayout_morningGreetingString]: 'Selamat pagi',
    [CompanyUserLayoutKeys.companyUserLayout_afternoonGreetingString]: 'Selamat petang',
    [CompanyUserLayoutKeys.companyUserLayout_eveningGreetingString]: 'Selamat petang',
    [CompanyUserLayoutKeys.companyUserLayout_reportString]: 'Laporan',
    [CompanyUserLayoutKeys.companyUserLayout_profileDropDownString]: 'Profil',
    [CompanyUserLayoutKeys.companyUserLayout_settingsDropDownString]: 'Tetapan',
    [CompanyUserLayoutKeys.companyUserLayout_logoutDropDownString]: 'Log keluar',
}