export enum SurveyCampaignViewKeys {
    surveyCampaignView_viewReportBtn = 'surveyCampaignView_viewReportBtn',
    surveyCampaignView_emailTableHeader = 'surveyCampaignView_emailTableHeader',
    surveyCampaignView_surveyLinkTableHeader = 'surveyCampaignView_surveyLinkTableHeader',
    surveyCampaignView_surveyLinkString = 'surveyCampaignView_surveyLinkString',
    surveyCampaignView_progressTableHeader = 'surveyCampaignView_progressTableHeader',
    surveyCampaignView_progressPrompt = 'surveyCampaignView_progressPrompt',
    surveyCampaignView_firstNameTableHeader = 'surveyCampaignView_firstNameTableHeader',
    surveyCampaignView_lastNameTableHeader = 'surveyCampaignView_lastNameTableHeader',
    surveyCampaignView_positionTableHeader = 'surveyCampaignView_positionTableHeader',
    surveyCampaignView_teamTableHeader = 'surveyCampaignView_teamTableHeader',
    surveyCampaignView_genderTableHeader = 'surveyCampaignView_genderTableHeader',
    surveyCampaignView_lastUpdatedTableHeader = 'surveyCampaignView_lastUpdatedTableHeader',
    surveyCampaignView_faqString = 'surveyCampaignView_faqString',
    surveyCampaignView_availableStatusString = 'surveyCampaignView_availableStatusString',
    surveyCampaignView_pendingStatusString = 'surveyCampaignView_pendingStatusString',
    surveyCampaignView_sentStatusString = 'surveyCampaignView_sentStatusString',
    surveyCampaignView_failedStatusString = 'surveyCampaignView_failedStatusString',
    surveyCampaignView_cancelledStatusString = 'surveyCampaignView_cancelledStatusString',
    surveyCampaignView_completedStatusString = 'surveyCampaignView_completedStatusString',
    surveyCampaignView_refreshPrompt = 'surveyCampaignView_refreshPrompt',
    surveyCampaignView_refreshingDataPrompt = 'surveyCampaignView_refreshingDataPrompt',
    surveyCampaignView_resendEmailBtn = 'surveyCampaignView_resendEmailBtn',
    surveyCampaignView_cancelSurveyBtn = 'surveyCampaignView_cancelSurveyBtn',
    surveyCampaignView_reopenSurveyBtn = 'surveyCampaignView_reopenSurveyBtn',
    surveyCampaignView_completeCampaignBtn = 'surveyCampaignView_completeCampaignBtn',
    surveyCampaignView_completeCampaignAreYouSurePrompt = 'surveyCampaignView_completeCampaignAreYouSurePrompt',
    surveyCampaignView_completeCampaignFurtherResponsesPrompt = 'surveyCampaignView_completeCampaignFurtherResponsesPrompt',
    surveyCampaignView_deleteCampaignPrompt = 'surveyCampaignView_deleteCampaignFurtherResponsesPrompt',
    surveyCampaignView_completingSurveyCampaignPrompt = 'surveyCampaignView_completingSurveyCampaignPrompt',
    surveyCampaignView_completedSurveyCampaignPrompt = 'surveyCampaignView_completedSurveyCampaignPrompt',
    surveyCampaignView_moreTooltip = 'surveyCampaignView_moreTooltip',
    surveyCampaignView_deletingSurveyCampaignPrompt = 'surveyCampaignView_deletingSurveyCampaignPrompt',
    surveyCampaignView_deletedSurveyCampaignPrompt = 'surveyCampaignView_deletedSurveyCampaignPrompt',
    surveyCampaignView_restoreCampaignPrompt = 'surveyCampaignView_restoreCampaignPrompt',
    surveyCampaignView_restoringSurveyCampaignPrompt = 'surveyCampaignView_restoringSurveyCampaignPrompt',
    surveyCampaignView_restoredSurveyCampaignPrompt = 'surveyCampaignView_restoredSurveyCampaignPrompt',
    surveyCampaignView_surveyString = 'surveyCampaignView_surveyString',
    surveyCampaignView_createdString = 'surveyCampaignView_createdString',

}