
import { LoadingOutlined } from "@ant-design/icons";
import React, { Suspense, lazy } from "react";
import {
    Route, Switch
} from "react-router-dom";
const ForgotPasswordPage = lazy(() => import("../../pages/ForgotPasswordPage"));
const LoginPage = lazy(() => import("../../pages/LoginPage/index"));
const NotFoundPage = lazy(() => import("../../pages/NotFoundPage"));
const ResetPasswordPage = lazy(() => import("../../pages/ResetPasswordPage/index"));


export default function UnauthenticatedRoutes() {
    return (
        <Suspense
            fallback={
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <LoadingOutlined
                        style={{ fontSize: 50 }}
                    />
                </div>
            }
        >
            <Switch>

                <Route
                    exact
                    path="/login"
                    component={LoginPage}
                />

                <Route
                    exact
                    path="/forgot-password"
                    component={ForgotPasswordPage}
                />

                <Route
                    exact
                    path="/reset-password"
                    component={ResetPasswordPage}
                />

                <Route
                    path='*'
                    component={NotFoundPage}
                />
            </Switch>
        </Suspense>
    );
}