import { FilePdfFilled, LoadingOutlined } from '@ant-design/icons'
import { Empty, message, Skeleton, Tooltip, Typography } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import config from '../../config'
import { GetSurveyCampaignBySurveyCampaignIdRequest, GetSurveyCampaignIndividualReportRequest, GetSurveyResponseBySurveyResponseIdRequest, PrintRequest } from '../../constants/apiRequestResponse'
import { ReportLayouts } from '../../constants/misc'
import { Positions, SurveyResponse, SurveyTemplate, IndividualResponse360Statistics, SurveyCampaign, SurveyEmployee } from '../../constants/types'
import { getSurveyCampaign360IndividualReport, getSurveyCampaignBySurveyCampaignId, getSurveyResponseBySurveyResponseId, printAsPDF } from '../../services/api'
import { getQueryParam, parseDate, parseTime, stringParser } from '../../util/index'
import QuestionProgress from '../QuestionProgress/index'
import styles from './index.module.css'
import { useTranslation } from 'react-i18next'
import { FeedbackGroupQuestionReportKeys } from '../../services/i8tn/FeedbackGroupQuestionReport/keys'

type Prop = {
    surveyResponseId?: string,
}

enum Colors {
    participant = '#4d3e98',
    comparison = '#009e4f'
}

export default function FeedbackIndividualQuestionReport(props: Prop) {

    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [isPrintAsPDFLoading, setIsPrintAsPDFLoading] = useState(false)
    const [surveyTemplate, setSurveyTemplate] = useState<SurveyTemplate | undefined>()
    const [surveyResponse, setSurveyResponse] = useState<SurveyResponse | undefined>()
    const [surveyCampaign, setSurveyCampaign] = useState<SurveyCampaign | undefined>()
    const [participant, setParticipant] = useState<SurveyEmployee | undefined>()
    const [responseStatistics, setResponseStatistics] = useState<IndividualResponse360Statistics[] | undefined>()

    const surveyResponseId = props.surveyResponseId || getQueryParam('surveyResponseId') || undefined
    const surveyCampaignId = getQueryParam('surveyCampaignId')
    const mode = ReportLayouts.feedbackIndividualReport
    const isPdf = getQueryParam('print') ? true : false

    const { t } = useTranslation()

    const getData = useCallback(async () => {
        try {

            setIsLoading(true)

            const req1: GetSurveyResponseBySurveyResponseIdRequest = {
                surveyResponseId: surveyResponseId,
            }

            const req2: GetSurveyCampaignIndividualReportRequest = {
                surveyResponseId: surveyResponseId,
            }

            const req3: GetSurveyCampaignBySurveyCampaignIdRequest = {
                surveyCampaignId: surveyCampaignId
            }

            const [res1, res2, res3] = await Promise.all([
                getSurveyResponseBySurveyResponseId(req1),
                getSurveyCampaign360IndividualReport(req2),
                getSurveyCampaignBySurveyCampaignId(req3)

            ])

            if (!res1.success) throw res1.message
            setSurveyResponse(res1.surveyResponse)
            setParticipant(res1.participant)

            if (!res2.success) throw res2.message
            setResponseStatistics(res2.responseStatistics)
            setSurveyTemplate(res2.surveyTemplate)


            if (!res3.success) throw res2.message
            setSurveyCampaign(res3.surveyCampaign)

        } catch (err) {
            message.error(typeof err === 'string' ? err : typeof err === 'object' ? err?.toString() : `Something went wrong`)
            setIsError(true)
        }
        setIsLoading(false)
    }, [surveyResponseId, surveyCampaignId])

    useEffect(() => {
        getData()
    }, [surveyResponseId, getData])


    const onExportPDF = () => {
        setIsPrintAsPDFLoading(true)

        let url = new URL(`${window.location.origin}/print`)
        url.searchParams.append('print', 'true')
        url.searchParams.append('layout', mode)
        url.searchParams.append('surveyResponseId', surveyResponseId?.toString() || '')
        url.searchParams.append('surveyCampaignId', surveyCampaignId?.toString() || '')


        let urlString = url.toString()

        /**
         * 14/8/2022 daniel.kwok
         * Render pdf will not work locally (puppeteer thing)
         * So, if it's local, just render the UI
         * Else, call the API to produce PDF
         */
        if (config.env === 'dev') {
            window.location.href = urlString
        } else {

            urlString = encodeURIComponent(urlString)
            const req: PrintRequest = {
                url: urlString
            }
            printAsPDF(req)
                .then(() => {
                    setIsPrintAsPDFLoading(false)
                })
                .catch(err => {
                    message.error(err?.toString())
                })
        }
    }

    if (isLoading) {
        return (
            <div className='page-content' style={{ backgroundColor: 'white' }}>
                <Skeleton active />
            </div>
        )
    }

    if (isError) {
        return (
            <div className='page-content' style={{ backgroundColor: 'white' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    <Typography.Title level={5}>
                        Something went wrong
                    </Typography.Title>
                </div>
            </div>
        )
    }

    return (
        <div className='page-content' style={{ backgroundColor: 'white' }}>
            <div
                style={{
                    margin: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >

                <div
                    style={{ marginBottom: 50 }}
                >
                    <div

                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            width: '100%',
                        }}
                    >
                        {
                            !isPdf ? (
                                <div>
                                    <Typography.Title level={2}>
                                        <>{t(FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_individualReportTitleString)}</> {parseDate(moment.now())}
                                    </Typography.Title>
                                    <h1>
                                        {surveyResponse?.surveyEmployee?.fname}{surveyResponse?.surveyEmployee?.lname}, {surveyResponse?.surveyEmployee?.position} ({surveyResponse?.surveyEmployee?.team})
                                    </h1>
                                    <p style={{ color: 'grey' }}><>{t(FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_genderString)}</></p>
                                    <p style={{ fontWeight: 1000 }} >{surveyResponse?.surveyEmployee.gender}</p>
                                    <p style={{ color: 'grey' }}><>{t(FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_emailString)}</></p>
                                    <p style={{ fontWeight: 1000 }} >{surveyResponse?.surveyEmployee.email}</p>
                                    <p style={{ color: 'grey' }}><>{t(FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_responseIDString)}</></p>
                                    <p style={{ fontWeight: 1000 }} >{surveyResponseId}</p>
                                    <p style={{ color: 'grey' }}><>{t(FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_surveyCampaignString)}</></p>
                                    <p style={{ fontWeight: 1000 }} >{surveyCampaign?.name}</p>
                                    <p style={{ color: 'grey' }}><>{t(FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_surveyString)}</></p>
                                    <p style={{ fontWeight: 1000 }} >{surveyTemplate?.name}</p>
                                    <p style={{ color: 'grey' }}><>{t(FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_lastUpdatedString)}</></p>
                                    <p style={{ fontWeight: 1000 }} >{parseTime(surveyResponse?.updatedAt)}</p>
                                </div>
                            ) : null
                        }
                        {

                            !isPdf ? (
                                isPrintAsPDFLoading ? (
                                    <LoadingOutlined
                                        style={{ fontSize: 30, }} />
                                ) : (
                                    <Tooltip title={t(FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_downloadAsPDFTooltip)}>
                                        <FilePdfFilled
                                            onClick={e => {
                                                onExportPDF()
                                            }}
                                            style={{ fontSize: 30, }} />
                                    </Tooltip>
                                )
                            ) : null
                        }
                    </div>
                </div>
                <div
                    className={styles.options_container}
                >
                    {
                        (responseStatistics)?.map((rs, i) => {
                            const participantAnswer = rs.byPosition[Positions.Participant]?.average
                            const participantAnswerPercentage = rs.byPosition[Positions.Participant]?.averagePercentage

                            const lineManagerAnswer = rs.byPosition[Positions.LineManager]?.olympicScoring
                            const lineManagerAnswerPercentage = rs.byPosition[Positions.LineManager]?.olympicScoringPercentage

                            const teamAnswer = rs.byPosition[Positions.Team]?.average
                            const teamAnswerPercentage = rs.byPosition[Positions.Team]?.averagePercentage

                            const reportAnswer = rs.byPosition[Positions.Reports]?.average
                            const reportAnswerPercentage = rs.byPosition[Positions.Reports]?.averagePercentage

                            const parsedStatement = stringParser(rs.statement, {
                                '[$participantName]': `${participant?.fname} ${participant?.lname}`
                            })

                            return (
                                <div
                                    key={rs?.surveyQuestionId}
                                    style={{
                                        marginBottom: 50,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        breakInside: 'avoid',
                                        pageBreakInside: 'avoid',
                                    }}
                                >
                                    <div
                                        style={{
                                            marginBottom: 20
                                        }}
                                    >
                                        <h1>
                                            [{i + 1}] {parsedStatement}
                                        </h1>
                                    </div>

                                    <div
                                        style={{ display: 'flex', flexDirection: 'column', gap: 5 }}
                                    >

                                        <div
                                            style={{ display: 'flex', flexDirection: 'column', gap: 6 }}
                                        >
                                            <p style={{ color: 'grey' }} >
                                                {Positions.Participant}
                                            </p>
                                            {

                                                typeof reportAnswer === 'number' ? (
                                                    <QuestionProgress
                                                        type={Positions.Participant}
                                                        percent={participantAnswerPercentage}
                                                        format={(percent: number) => {
                                                            return <h4 style={{ color: 'white', fontWeight: 'bold' }}>{typeof participantAnswer === 'number' ? participantAnswer?.toFixed(1) : reportAnswer}</h4>
                                                        }}
                                                    />
                                                ) : (
                                                    <p>
                                                        {participantAnswer}
                                                    </p>
                                                )
                                            }
                                        </div>
                                        <p style={{ color: 'grey' }} >
                                            {Positions.LineManager}
                                        </p>
                                        {
                                            typeof lineManagerAnswer === 'number' ? (
                                                <QuestionProgress
                                                    type={Positions.LineManager}
                                                    percent={lineManagerAnswerPercentage}
                                                    format={(percent: number) => {
                                                        return <h4 style={{ color: 'white', fontWeight: 'bold' }}>{typeof lineManagerAnswer === 'number' ? lineManagerAnswer?.toFixed(1) : lineManagerAnswer}</h4>
                                                    }}
                                                />
                                            ) : (
                                                <p>
                                                    {lineManagerAnswerPercentage}
                                                </p>
                                            )
                                        }   <p style={{ color: 'grey' }} >
                                            {Positions.Team}
                                        </p>
                                        {

                                            typeof teamAnswer === 'number' ? (
                                                <QuestionProgress
                                                    type={Positions.Team}
                                                    percent={teamAnswerPercentage}
                                                    format={(percent: number) => {
                                                        return <h4 style={{ color: 'white', fontWeight: 'bold' }}>{typeof teamAnswer === 'number' ? teamAnswer?.toFixed(1) : teamAnswer}</h4>
                                                    }}
                                                />
                                            ) : (
                                                <p>
                                                    {teamAnswer}
                                                </p>
                                            )
                                        }
                                        <p style={{ color: 'grey' }} >
                                            {Positions.Reports}
                                        </p>
                                        {

                                            typeof reportAnswer === 'number' ? (
                                                <QuestionProgress
                                                    type={Positions.Reports}
                                                    percent={reportAnswerPercentage}
                                                    format={(percent: number) => {
                                                        return <h4 style={{ color: 'white', fontWeight: 'bold' }}>{typeof reportAnswer === 'number' ? reportAnswer?.toFixed(1) : reportAnswer}</h4>
                                                    }}
                                                />
                                            ) : (
                                                <p>
                                                    {reportAnswer}
                                                </p>
                                            )
                                        }
                                        {
                                            typeof rs.benchmarkScorePercentage === 'number' ? (
                                                <div
                                                    style={{ display: 'flex', flexDirection: 'column', gap: 5 }}
                                                >
                                                    <p style={{ color: 'grey' }} >
                                                        <>{t(FeedbackGroupQuestionReportKeys.feedbackIndividualQuestionReport_comparisonString)}</>
                                                    </p>
                                                    <QuestionProgress
                                                        type={'Comparison'}
                                                        progressColor={Colors.comparison}
                                                        percent={rs.benchmarkScorePercentage}
                                                        format={(percent: number) => {
                                                            return <h4 style={{ color: 'white', fontWeight: 'bold' }}>{rs.benchmarkScore?.toFixed(1)}</h4>
                                                        }}
                                                    />
                                                </div>
                                            ) :
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                                                    <p style={{ color: 'grey', fontStyle: 'italic' }}>Not applicable</p>
                                                </div>
                                        }
                                    </div>
                                    <p style={{ color: 'grey', fontStyle: 'italic', marginTop: 20 }}>
                                        {rs?.description}
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}