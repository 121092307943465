import { LoginPageKeys } from "./keys"

export const englishValues = {
    [LoginPageKeys.loginPage_loginPrompt]: 'Login',
    [LoginPageKeys.loginPage_emailPlaceholder]: 'Email',
    [LoginPageKeys.loginPage_passwordPlaceholder]: 'Password',
    [LoginPageKeys.loginPage_loginBtn]: 'Log In',
    [LoginPageKeys.loginPage_forgetPasswordBtn]: 'Forget password?',
}

export const malayValues = {
    [LoginPageKeys.loginPage_loginPrompt]: 'Log masuk',
    [LoginPageKeys.loginPage_emailPlaceholder]: 'E-mel',
    [LoginPageKeys.loginPage_passwordPlaceholder]: 'Kata laluan',
    [LoginPageKeys.loginPage_loginBtn]: 'Log masuk',
    [LoginPageKeys.loginPage_forgetPasswordBtn]: 'Terlupa kata laluan?',
}