export enum ReportsViewKeys {
    reportsView_reportsBreadcrumb = 'reportsView_reportsBreadcrumb',
    reportsView_lastUpdatedString = 'reportsView_lastUpdatedString',
    reportsView_totalString = 'reportsView_totalString',
    reportsView_completedString = 'reportsView_completedString',
    reportsView_cancelledString = 'reportsView_cancelledString',
    reportsView_completionRateString = 'reportsView_completionRateString',
    reportsView_groupReportTab = 'reportsView_groupReportTab',
    reportsView_individualsReportTab = 'reportsView_individualsReportTab',
    reportsView_dimensionSummary = 'reportsView_dimensionSummary',
    reportsView_engagementSummary = 'reportsView_engagementSummary',
    reportsView_questions = 'reportsView_questions',
    reportsView_highlights = 'reportsView_highlights',
    reportsView_legend = 'reportsView_legend',
    reportsView_valuesAreDisplayedInPercentage = 'reportsView_valuesAreDisplayedInPercentage',
    reportsView_favourable = 'reportsView_favourable',
    reportsView_neutral = 'reportsView_neutral',
    reportsView_unfavourable = 'reportsView_unfavourable',
    reportsView_notApplicable = 'reportsView_notApplicable',
    reportsView_reportUnavailable = 'reportsView_reportUnavailable',
}