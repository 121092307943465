import { EditOutlined, LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import {
    Button, Card, Form, Input, message, Modal, PageHeader, Select, Tabs, Tooltip, Typography
} from 'antd'
import React, { useContext, useState } from 'react'
import { SessionContext } from '../../App'
import UploadImage from '../../components/UploadImage'
import { ChangePasswordRequest, UpdateCompanyRequest, UpdateCompanyUserRequest, UploadCompanyLogoRequest } from '../../constants/apiRequestResponse'
import { CompanySizes, Gender } from '../../constants/types'
import { changeCompanyUserPassword, updateCompany, updateCompanyUser, uploadCompanyLogo } from '../../services/api'
import { getQueryParam, setQueryParam } from '../../util'
import { useTranslation } from 'react-i18next'
import { EditProfilePageKeys } from '../../services/i8tn/EditProfilePage/keys'

export default function EditProfilePage() {
    const context = useContext(SessionContext)
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)

    return (
        <div
            className='page-content'
        >
            <PageHeader
                className="site-page-header"
                title={t(EditProfilePageKeys.editProfilePage_editProfileTitle)}
            />
            <Card
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Tabs
                    tabPosition='left'
                    defaultActiveKey={getQueryParam('key') || "User"}
                    onChange={key => {
                        setQueryParam('key', key)
                    }}>
                    <Tabs.TabPane tab={t(EditProfilePageKeys.editProfilePage_userTab)} key="User">
                        <Typography.Title level={3}>
                            {t(EditProfilePageKeys.editProfilePage_userProfileTabTitle)}
                        </Typography.Title>
                        <Form
                            layout='vertical'
                            onFinish={(v) => {
                                console.log(v)
                                setIsLoading(true)

                                const req: UpdateCompanyUserRequest = {
                                    companyUser: v
                                }
                                updateCompanyUser(req)
                                    .then(res => {
                                        if (!res.success) throw new Error(res.message)
                                        message.success('Updated')
                                    })
                                    .catch(err => {
                                        message.error(err?.toString())
                                    })

                            }}
                            style={{
                                width: 500
                            }}
                            initialValues={context?.companyUser}
                        >
                            <Form.Item hidden name='_id' />
                            <Form.Item hidden name='companyId' />

                            <Form.Item label={t(EditProfilePageKeys.editProfilePage_userProfileNameString)} name='name' rules={[{ required: true }]}>
                                <Input placeholder='John' />
                            </Form.Item>
                            <Form.Item
                                label={t(EditProfilePageKeys.editProfilePage_userProfileEmailString)}
                                name='email'
                                rules={[
                                    /**
                                     * 1/6/2022 daniel.kwok
                                     * Not sure if company emailDomain validation is required here. 
                                     * Might need to cross check
                                     */
                                    {
                                        required: true,
                                        type: 'email',
                                        validator: (_, value) => {
                                            for (let emailDomain of context?.company?.emailDomains || []) {
                                                const regex = new RegExp(`.+@${emailDomain}$`)
                                                if (value.match(regex)) {
                                                    return Promise.resolve()
                                                }
                                            }

                                            return Promise.reject()
                                        },
                                        message: `Email must end with one of ${context?.company?.emailDomains.toString()}. E.g. johndoe@${context?.company?.emailDomains[0]}`,
                                    },
                                ]}>
                                <Input placeholder='johndoe@email.com' />
                            </Form.Item>
                            <Form.Item label={t(EditProfilePageKeys.editProfilePage_userProfileGenderString)} name='gender'
                                rules={[
                                    {
                                        required: true,
                                        type: 'enum',
                                        enum: [Gender.Male, Gender.Female]
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder={'Male'}
                                >
                                    {
                                        ['Male', 'Female'].map(gender => {
                                            return <Select.Option key={gender} value={gender}>
                                                {gender}
                                            </Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item label={t(EditProfilePageKeys.editProfilePage_userProfileMobileString)} name='mobile' rules={[{ required: true }]}>
                                <Input placeholder='0123456789' />
                            </Form.Item>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    gap: 5
                                }}
                            >
                                <Button
                                    htmlType='submit'
                                    type='primary'
                                >
                                    <>{t(EditProfilePageKeys.editProfilePage_userProfileSaveBtn)}</>
                                </Button>

                            </div>
                        </Form>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t(EditProfilePageKeys.editProfilePage_companyTab)} key="Company">
                        <Typography.Title level={3}>
                            {t(EditProfilePageKeys.editProfilePage_companyTabTitle)}
                        </Typography.Title>
                        <div
                            style={{

                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <img
                                alt=""
                                src={context?.company?.logo}
                                width={300}
                            />
                        </div>
                        <div
                            style={{

                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                type='link'
                                onClick={() => {
                                    Modal.info({
                                        icon: null,
                                        title: <Typography.Title level={5}>{t(EditProfilePageKeys.editProfilePage_companyUpdateLogoPrompt)}</Typography.Title>,
                                        content: (
                                            isLoading ? (
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <LoadingOutlined style={{ fontSize: 24 }} spin />
                                                    <p>Uploading</p>
                                                </div>
                                            ) : (
                                                <UploadImage
                                                    onBase64Changed={base64 => {
                                                        const req1: UploadCompanyLogoRequest = {
                                                            base64: base64
                                                        }
                                                        setIsLoading(true)
                                                        uploadCompanyLogo(req1)
                                                            .then(res => {
                                                                if (!res.success) throw new Error(res.message)

                                                                if (!context?.company) throw new Error(`Missing company`)

                                                                const req2: UpdateCompanyRequest = {
                                                                    company: {
                                                                        ...context?.company,
                                                                        logo: res.url || ''
                                                                    }
                                                                }

                                                                return updateCompany(req2)
                                                            })
                                                            .then(res => {
                                                                if (!res.success) throw new Error(res.message)

                                                                Modal.success({
                                                                    title: 'Updated company logo',
                                                                    content: 'Refresh page to view changes',
                                                                    onOk: () => window.location.reload()
                                                                })
                                                            })
                                                            .catch(err => {
                                                                message.error(err?.toString())
                                                            })
                                                            .finally(() => {
                                                                setIsLoading(false)
                                                            })
                                                    }}
                                                />
                                            )
                                        )
                                    })
                                }}
                            >
                                <EditOutlined /> {t(EditProfilePageKeys.editProfilePage_companyChangeLogoString)}
                            </Button>
                        </div>
                        <br />
                        <br />
                        <Form
                            layout='vertical'
                            onFinish={(v) => {

                                // if somehow there isn't a company, pop error and stop. Tho this would likely never happen.
                                if (!context?.company) {
                                    message.error(t(EditProfilePageKeys.editProfilePage_companySaveCompanyMissingErrorMessage))
                                    return
                                }

                                const updateCompanyRequest: UpdateCompanyRequest = {
                                    company: {
                                        ...context?.company,
                                        emailDomains: v.emailDomains,
                                        name: v.name,
                                        industry: v.industry,
                                        location: v.location,
                                        size: v.size,
                                    }
                                }
                                updateCompany(updateCompanyRequest)
                                    .then(res => {
                                        if (res.success) {
                                            message.success(t(EditProfilePageKeys.editProfilePage_companySaveCompanySuccessMessage))
                                        } else {
                                            message.error(res.message)
                                        }
                                    })
                                    .catch(err => {
                                        message.error(err.message)
                                    })
                            }}
                            style={{
                                width: 500
                            }}
                            initialValues={context?.company}
                        >

                            <Form.Item
                                label={<span>
                                    {t(EditProfilePageKeys.editProfilePage_companyEmailDomainString)} &nbsp;
                                    <Tooltip title={t(EditProfilePageKeys.editProfilePage_companyEmailDomainToolTip)}>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </span>}
                                name='emailDomains' required
                                help='E.g. gmail.com, acme.com, thedevelopmentcentre.com'
                            >
                                <Select mode="tags" style={{ width: '100%' }}>

                                </Select>
                            </Form.Item>

                            <Form.Item label={t(EditProfilePageKeys.editProfilePage_companyNameString)} name='name' rules={[{ required: true }]}>
                                <Input placeholder={t(EditProfilePageKeys.editProfilePage_companyNamePlaceholder)} />
                            </Form.Item>
                            <Form.Item label={t(EditProfilePageKeys.editProfilePage_companyIndustryString)} name='industry' rules={[{}]}>
                                <Input placeholder={t(EditProfilePageKeys.editProfilePage_companyIndustryPlaceholder)} />
                            </Form.Item>
                            <Form.Item label={t(EditProfilePageKeys.editProfilePage_companyLocationString)} name='location' rules={[{ required: true }]}>
                                <Input placeholder={t(EditProfilePageKeys.editProfilePage_companyLocationPlaceholder)} />
                            </Form.Item>
                            <Form.Item label={t(EditProfilePageKeys.editProfilePage_companySizeString)} name='size'
                                rules={[
                                    {
                                        required: true,
                                        type: 'enum',
                                        enum: [CompanySizes.enterprise, CompanySizes.large, CompanySizes.medium, CompanySizes.small, CompanySizes.micro,]
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder={CompanySizes.medium}
                                >
                                    {
                                        [CompanySizes.enterprise, CompanySizes.large, CompanySizes.medium, CompanySizes.small, CompanySizes.micro,].map(size => {
                                            return <Select.Option key={size} value={size}>
                                                {size}
                                            </Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    gap: 5
                                }}
                            >
                                <Button
                                    htmlType='submit'
                                    type='primary'
                                >
                                    {t(EditProfilePageKeys.editProfilePage_companySaveBtn)}
                                </Button>
                            </div>
                        </Form>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t(EditProfilePageKeys.editProfilePage_resetPasswordTab)} key="Reset password">
                        <Typography.Title level={3}>
                            {t(EditProfilePageKeys.editProfilePage_resetPasswordTabTitle)}
                        </Typography.Title>
                        <Form
                            layout='vertical'
                            onFinish={async (v: {
                                oldPassword: string,
                                newPassword: string,
                                confirmNewPassword: string,
                            }) => {
                                try {
                                    setIsLoading(true)
                                    if (v.newPassword !== v.confirmNewPassword) throw new Error(`New passwords don't match.`)

                                    const req: ChangePasswordRequest = {
                                        oldPassword: v.oldPassword,
                                        newPassword: v.newPassword
                                    }

                                    const res = await changeCompanyUserPassword(req)
                                    if (!res.success) throw new Error(res.message)

                                    message.success(`Password reset successful`)

                                    setIsLoading(false)
                                } catch (err) {
                                    message.error(typeof err === 'string' ? err : typeof err === 'object' ? err?.toString() : `Something went wrong`)
                                    setIsLoading(false)
                                }
                            }}
                            style={{
                                width: 500
                            }}
                        >
                            <Form.Item label={t(EditProfilePageKeys.editProfilePage_resetPasswordOldString)} name='oldPassword' rules={[{ required: true, }]}>
                                <Input.Password />
                            </Form.Item>
                            <Form.Item label={t(EditProfilePageKeys.editProfilePage_resetPasswordNewString)} name='newPassword' rules={[{ required: true, }]}>
                                <Input.Password />
                            </Form.Item>
                            <Form.Item label={t(EditProfilePageKeys.editProfilePage_resetPasswordConfirmString)} name='confirmNewPassword' rules={[{ required: true, }]}>
                                <Input.Password />
                            </Form.Item>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    gap: 5
                                }}
                            >
                                <Button
                                    htmlType='submit'
                                    type='primary'
                                    loading={isLoading}
                                >
                                    {t(EditProfilePageKeys.editProfilePage_resetPasswordResetBtn)}
                                </Button>
                            </div>
                        </Form>
                    </Tabs.TabPane>
                </Tabs>
            </Card>
        </div >
    )
}