import { FilePdfFilled, LoadingOutlined } from '@ant-design/icons'
import { Empty, message, Skeleton, Tooltip, Typography } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config'
import { GetSurveyCampaignIndividualReportRequest, GetSurveyResponseBySurveyResponseIdRequest, PrintRequest } from '../../constants/apiRequestResponse'
import TEST_IDS from '../../constants/cypressTestIds'
import { ReportLayouts } from '../../constants/misc'
import { IndividualResponseStatistics, Positions, SurveyResponse, SurveyTemplate } from '../../constants/types'
import { getSurveyCampaignIndividualReport, getSurveyResponseBySurveyResponseId, printAsPDF } from '../../services/api'
import { getQueryParam, parseDate, parseTime } from '../../util/index'
import QuestionProgress from '../QuestionProgress/index'
import styles from './index.module.css'
import { NormalIndividualQuestionReportKeys } from '../../services/i8tn/NormalIndividualQuestionReport/keys'

type Prop = {
    surveyResponseId?: string,
}

export default function NormalIndividualQuestionReport(props: Prop) {

    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [isPrintAsPDFLoading, setIsPrintAsPDFLoading] = useState(false)
    const [surveyTemplate, setSurveyTemplate] = useState<SurveyTemplate | undefined>()
    const [surveyResponse, setSurveyResponse] = useState<SurveyResponse | undefined>()
    const [responseStatistics, setResponseStatistics] = useState<IndividualResponseStatistics[] | undefined>()

    const surveyResponseId = props.surveyResponseId || getQueryParam('surveyResponseId') || undefined
    const mode = ReportLayouts.normalIndividualReport
    const isPdf = getQueryParam('print') ? true : false

    const { t } = useTranslation()

    const getData = useCallback(async () => {

        try {

            setIsLoading(true)

            const req1: GetSurveyResponseBySurveyResponseIdRequest = {
                surveyResponseId: surveyResponseId,
            }
            const res1 = await getSurveyResponseBySurveyResponseId(req1)
            if (!res1.success) throw new Error(res1.message)
            setSurveyResponse(res1.surveyResponse)

            const req2: GetSurveyCampaignIndividualReportRequest = {
                surveyResponseId: surveyResponseId,
            }
            const res2 = await getSurveyCampaignIndividualReport(req2)
            if (!res2.success) throw new Error(res2.message)
            setResponseStatistics(res2.individualResponseStatistics)
            setSurveyTemplate(res2.surveyTemplate)

        } catch (err) {


            if (err instanceof Error) {
                message.error(err?.message)
            } else {
                message.error('Something went wrong.')
            }
            setIsError(true)
        }
        setIsLoading(false)
    }, [surveyResponseId,])

    useEffect(() => {
        getData()
    }, [surveyResponseId, getData])

    const onExportPDF = () => {
        setIsPrintAsPDFLoading(true)

        let url = new URL(`${window.location.origin}/print`)
        url.searchParams.append('print', 'true')
        url.searchParams.append('layout', mode)
        url.searchParams.append('surveyResponseId', surveyResponseId?.toString() || '')

        let urlString = url.toString()


        /**
         * 14/8/2022 daniel.kwok
         * Render pdf will not work locally (puppeteer thing)
         * So, if it's local, just render the UI
         * Else, call the API to produce PDF
         */
        if (config.env === 'dev') {
            window.location.href = urlString
        } else {

            urlString = encodeURIComponent(urlString)
            const req: PrintRequest = {
                url: urlString
            }
            printAsPDF(req)
                .then(() => {
                    setIsPrintAsPDFLoading(false)
                })
                .catch(err => {
                    message.error(err?.toString())
                })
        }
    }

    if (isLoading) {
        return (
            <div className='page-content' style={{ backgroundColor: 'white' }}>
                <Skeleton active />
            </div>
        )
    }

    if (isError) {
        return (
            <div className='page-content' style={{ backgroundColor: 'white' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    <Typography.Title level={5}>
                        Something went wrong
                    </Typography.Title>
                </div>
            </div>
        )
    }

    return (
        <div className='page-content' style={{ backgroundColor: 'white' }}>
            <div
                style={{
                    margin: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >

                <div
                    style={{ marginBottom: 50 }}
                >
                    <div

                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            width: '100%',
                        }}
                    >
                        {
                            !isPdf ? (
                                <div>
                                    <Typography.Title level={2}>
                                        <>{t(NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_individualReportTitleString)}</> {parseDate(moment.now())}
                                    </Typography.Title>
                                    <h1>
                                        {surveyResponse?.surveyEmployee?.fname}{surveyResponse?.surveyEmployee?.lname}, {surveyResponse?.surveyEmployee?.position} ({surveyResponse?.surveyEmployee?.team})
                                    </h1>
                                    <p style={{ color: 'grey' }}><>{t(NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_genderString)}</></p>
                                    <p style={{ fontWeight: 1000 }} >{surveyResponse?.surveyEmployee.gender}</p>
                                    <p style={{ color: 'grey' }}><>{t(NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_emailString)}</></p>
                                    <p style={{ fontWeight: 1000 }} >{surveyResponse?.surveyEmployee.email}</p>
                                    <p style={{ color: 'grey' }}><>{t(NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_surveyIDString)}</></p>
                                    <p style={{ fontWeight: 1000 }} >{surveyResponseId}</p>
                                    <p style={{ color: 'grey' }}><>{t(NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_surveyString)}</></p>
                                    <p style={{ fontWeight: 1000 }} >{surveyTemplate?.name}</p>
                                    <p style={{ color: 'grey' }}><>{t(NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_lastUpdatedString)}</></p>
                                    <p style={{ fontWeight: 1000 }} >{parseTime(surveyResponse?.updatedAt)}</p>
                                </div>
                            ) : null
                        }
                        {

                            !isPdf ? (
                                isPrintAsPDFLoading ? (
                                    <LoadingOutlined
                                        style={{ fontSize: 30, }} />
                                ) : (
                                    <Tooltip title={t(NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_downloadAsPDFTooltip)}>

                                        <FilePdfFilled
                                            onClick={e => {
                                                onExportPDF()
                                            }}
                                            data-testid={TEST_IDS.REPORT_PAGE.individual_download_as_pdf_button_id}
                                            style={{ fontSize: 30, }} />
                                    </Tooltip>
                                )
                            ) : null
                        }
                    </div>
                </div>
                <div
                    className={styles.options_container}
                >
                    {
                        (responseStatistics)?.map((rs, i) => {


                            return (
                                <div
                                    key={rs?.surveyQuestionId}
                                    style={{
                                        marginBottom: 50,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        breakInside: 'avoid',
                                        pageBreakInside: 'avoid',
                                    }}
                                >
                                    <h1 style={{ marginBottom: 20 }}>
                                        [{i + 1}] {rs?.statement}
                                    </h1>
                                    <br />
                                    <div
                                        style={{ display: 'flex', flexDirection: 'column', gap: 5 }}
                                    >
                                        <p style={{ color: 'grey' }} ><>{t(NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_participantString)}</></p>
                                        {
                                            typeof rs.answer === 'number' ? (

                                                <QuestionProgress
                                                    type={Positions.Participant}
                                                    percent={rs.answerPercentage}
                                                    format={(percent: number) => {
                                                        return <h4 style={{ color: 'white', fontWeight: 'bold' }}>{typeof rs.answer === 'number' ? rs.answer?.toFixed(1) : rs.answer}</h4>
                                                    }}
                                                />
                                            ) : (
                                                <p>
                                                    {rs.answer}
                                                </p>
                                            )
                                        }
                                    </div>
                                    {
                                        rs.benchmarkScorePercentage ? (
                                            <div
                                                style={{ display: 'flex', flexDirection: 'column', gap: 5 }}
                                            >
                                                <p style={{ color: 'grey' }}><>{t(NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_comparisonString)}</></p>
                                                <QuestionProgress
                                                    type={'Comparison'}
                                                    percent={rs.benchmarkScorePercentage}
                                                    format={(percent: number) => {
                                                        return <h4 style={{ color: 'white', fontWeight: 'bold' }}>{rs.benchmarkScore?.toFixed(1)}</h4>
                                                    }}
                                                />
                                            </div>
                                        ) : null
                                    }
                                    <p style={{ color: 'grey', fontStyle: 'italic', marginTop: 20 }}>
                                        {rs?.description}
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}