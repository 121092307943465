import { SettingsPageKeys } from "./keys"

export const englishValues = {
    [SettingsPageKeys.settingsPage_settingsTitle]: 'Settings',
    [SettingsPageKeys.settingsPage_emailTemplatesString]: 'Email templates',
    [SettingsPageKeys.settingsPage_manageTemplatesString]: 'Manage email templates to be sent out for each survey campaign',
    [SettingsPageKeys.settingsPage_nameTableHeader]: 'Name',
    [SettingsPageKeys.settingsPage_templateTableHeader]: 'Linked survey template',
    [SettingsPageKeys.settingsPage_createdTableHeader]: 'Created',
    [SettingsPageKeys.settingsPage_actionTableHeader]: 'Action',

}

export const malayValues = {
    [SettingsPageKeys.settingsPage_settingsTitle]: 'Tetapan',
    [SettingsPageKeys.settingsPage_emailTemplatesString]: 'Templat e-mel',
    [SettingsPageKeys.settingsPage_manageTemplatesString]: 'Mengurus templat e-mel yang akan dihantar untuk setiap kempen tinjauan',
    [SettingsPageKeys.settingsPage_nameTableHeader]: 'Nama',
    [SettingsPageKeys.settingsPage_templateTableHeader]: 'Templat tinjauan berpautan',
    [SettingsPageKeys.settingsPage_createdTableHeader]: 'Dicipta pada',
    [SettingsPageKeys.settingsPage_actionTableHeader]: 'Tindakan',

}