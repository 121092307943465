
import React from 'react'
import { Positions } from '../../constants/types'
import styles from './QuestionProgress.module.css'

export type ProgressType = Positions | 'Comparison'

const COLOR_BY_TYPE = {
    [Positions.Participant]: '#a92188',
    [Positions.Reports]: '#009f50',
    [Positions.LineManager]: '#d4bd2d',
    [Positions.Team]: '#104240',
    Comparison: '#009e4f',
}

type Prop = {
    percent?: number,
    type?: ProgressType
    progressColor?: string,
    backgroundColor?: string,
    showInfo?: boolean,
    format?: Function,
    style?: Object,
}

/**
 * TODO daniel.kwok 2/5/2022
 * Will mess up layout if parent is display=flex
 * Need to figure out
 */
export default function QuestionProgress(props: Prop) {
    const percent = props.percent || 0
    const height = 10
    const type = props.type || ''
    const progressColor = props.progressColor || COLOR_BY_TYPE[type] || 'lightblue'
    const showInfo = props.showInfo || true

    if (!props) {
        return <></>
    }
    return (
        <div
            className={styles.container}
            style={{
                height: height * 3,
                ...props.style,
            }}
        >

            {/* Progress bar */}
            <div
                className={styles.progress}
                style={{
                    backgroundColor: progressColor,
                    width: `${percent}%`,
                }}
            >
            </div>

            {/* Bulb */}
            <div
                className={styles.bulb}
                style={{
                    backgroundColor: progressColor,
                    left: `max(calc(${percent}% - 30px), 0px)`,
                }}
            >
                {
                    showInfo ? (
                        props.format ? (
                            props.format(percent)
                        ) : <span>{percent}</span>
                    ) : null
                }
            </div>
        </div>
    )
}