import { SurveyCampaignViewKeys } from "./keys"

export const englishValues = {
    [SurveyCampaignViewKeys.surveyCampaignView_viewReportBtn]: 'View report',
    [SurveyCampaignViewKeys.surveyCampaignView_emailTableHeader]: 'Email',
    [SurveyCampaignViewKeys.surveyCampaignView_surveyLinkTableHeader]: 'Survey Link',
    [SurveyCampaignViewKeys.surveyCampaignView_surveyLinkString]: 'Link',
    [SurveyCampaignViewKeys.surveyCampaignView_progressTableHeader]: 'Progress',
    [SurveyCampaignViewKeys.surveyCampaignView_progressPrompt]: 'Number of questions that were answered so far.',
    [SurveyCampaignViewKeys.surveyCampaignView_firstNameTableHeader]: 'First Name',
    [SurveyCampaignViewKeys.surveyCampaignView_lastNameTableHeader]: 'Last Name',
    [SurveyCampaignViewKeys.surveyCampaignView_positionTableHeader]: 'Position',
    [SurveyCampaignViewKeys.surveyCampaignView_teamTableHeader]: 'Team',
    [SurveyCampaignViewKeys.surveyCampaignView_genderTableHeader]: 'Gender',
    [SurveyCampaignViewKeys.surveyCampaignView_lastUpdatedTableHeader]: 'Last updated',
    [SurveyCampaignViewKeys.surveyCampaignView_faqString]: 'FAQ',
    [SurveyCampaignViewKeys.surveyCampaignView_availableStatusString]: 'What are the available status for each surveys?',
    [SurveyCampaignViewKeys.surveyCampaignView_pendingStatusString]: 'Email had yet to be sent out',
    [SurveyCampaignViewKeys.surveyCampaignView_sentStatusString]: 'Email sent out successfully',
    [SurveyCampaignViewKeys.surveyCampaignView_failedStatusString]: 'Email failed to be sent out',
    [SurveyCampaignViewKeys.surveyCampaignView_cancelledStatusString]: 'Survey was cancelled',
    [SurveyCampaignViewKeys.surveyCampaignView_completedStatusString]: 'Survey had been completed by employee',
    [SurveyCampaignViewKeys.surveyCampaignView_refreshPrompt]: 'Refresh',
    [SurveyCampaignViewKeys.surveyCampaignView_refreshingDataPrompt]: 'Refreshing data',
    [SurveyCampaignViewKeys.surveyCampaignView_resendEmailBtn]: 'Resend email',
    [SurveyCampaignViewKeys.surveyCampaignView_cancelSurveyBtn]: 'Cancel survey',
    [SurveyCampaignViewKeys.surveyCampaignView_reopenSurveyBtn]: 'Re-open survey',
    [SurveyCampaignViewKeys.surveyCampaignView_completeCampaignBtn]: 'Mark as completed',
    [SurveyCampaignViewKeys.surveyCampaignView_completeCampaignAreYouSurePrompt]: 'Are you sure?',
    [SurveyCampaignViewKeys.surveyCampaignView_completeCampaignFurtherResponsesPrompt]: 'Further survey reponses will not be accepted.',
    [SurveyCampaignViewKeys.surveyCampaignView_completingSurveyCampaignPrompt]: 'Completing',
    [SurveyCampaignViewKeys.surveyCampaignView_completedSurveyCampaignPrompt]: 'Completed',
    [SurveyCampaignViewKeys.surveyCampaignView_moreTooltip]: 'More',
    [SurveyCampaignViewKeys.surveyCampaignView_deleteCampaignPrompt]: 'This survey campaign will be deleted.',
    [SurveyCampaignViewKeys.surveyCampaignView_deletingSurveyCampaignPrompt]: 'Deleting',
    [SurveyCampaignViewKeys.surveyCampaignView_deletedSurveyCampaignPrompt]: 'Deleted',
    [SurveyCampaignViewKeys.surveyCampaignView_restoreCampaignPrompt]: 'This survey campaign will be restored.',
    [SurveyCampaignViewKeys.surveyCampaignView_restoringSurveyCampaignPrompt]: 'Restoring',
    [SurveyCampaignViewKeys.surveyCampaignView_restoredSurveyCampaignPrompt]: 'Restored',
    [SurveyCampaignViewKeys.surveyCampaignView_surveyString]: 'Survey',
    [SurveyCampaignViewKeys.surveyCampaignView_createdString]: 'Created',

}

export const malayValues = {
    [SurveyCampaignViewKeys.surveyCampaignView_viewReportBtn]: 'Tunjukkan laporan',
    [SurveyCampaignViewKeys.surveyCampaignView_emailTableHeader]: 'E-mel',
    [SurveyCampaignViewKeys.surveyCampaignView_surveyLinkTableHeader]: 'Pautan Tinjauan',
    [SurveyCampaignViewKeys.surveyCampaignView_surveyLinkString]: 'Pautan',
    [SurveyCampaignViewKeys.surveyCampaignView_progressTableHeader]: 'Progres',
    [SurveyCampaignViewKeys.surveyCampaignView_progressPrompt]: 'Bilangan soalan yang telah dijawabkan setakat ini.',
    [SurveyCampaignViewKeys.surveyCampaignView_firstNameTableHeader]: 'Nama Pertama',
    [SurveyCampaignViewKeys.surveyCampaignView_lastNameTableHeader]: 'Nama Keluarga',
    [SurveyCampaignViewKeys.surveyCampaignView_positionTableHeader]: 'Jawatan',
    [SurveyCampaignViewKeys.surveyCampaignView_teamTableHeader]: 'Pasukan',
    [SurveyCampaignViewKeys.surveyCampaignView_genderTableHeader]: 'Jantina',
    [SurveyCampaignViewKeys.surveyCampaignView_lastUpdatedTableHeader]: 'Dikemas kini pada',
    [SurveyCampaignViewKeys.surveyCampaignView_faqString]: 'Soalan lazim',
    [SurveyCampaignViewKeys.surveyCampaignView_availableStatusString]: 'Apakah status yang tersedia untuk setiap tinjauan?',
    [SurveyCampaignViewKeys.surveyCampaignView_pendingStatusString]: 'E-mel belum dihantar',
    [SurveyCampaignViewKeys.surveyCampaignView_sentStatusString]: 'E-mel sudah dihantar',
    [SurveyCampaignViewKeys.surveyCampaignView_failedStatusString]: 'E-mel tidak dapat dihantar',
    [SurveyCampaignViewKeys.surveyCampaignView_cancelledStatusString]: 'Tinjauan telah dibatalkan',
    [SurveyCampaignViewKeys.surveyCampaignView_completedStatusString]: 'Tinjauan telah dilengkapkan oleh pekerja',
    [SurveyCampaignViewKeys.surveyCampaignView_refreshPrompt]: 'Muat semula',
    [SurveyCampaignViewKeys.surveyCampaignView_refreshingDataPrompt]: 'Data dimuat semula',
    [SurveyCampaignViewKeys.surveyCampaignView_resendEmailBtn]: 'Hantar e-mel semula',
    [SurveyCampaignViewKeys.surveyCampaignView_cancelSurveyBtn]: 'Batalkan tinjauan',
    [SurveyCampaignViewKeys.surveyCampaignView_reopenSurveyBtn]: 'Buka tinjauan semula',
    [SurveyCampaignViewKeys.surveyCampaignView_completeCampaignBtn]: 'Tandai sebagai selesai',
    [SurveyCampaignViewKeys.surveyCampaignView_completeCampaignAreYouSurePrompt]: 'Adakah anda pasti?',
    [SurveyCampaignViewKeys.surveyCampaignView_completeCampaignFurtherResponsesPrompt]: 'Respons tinjauan yang lanjutnya tidak akan diterima lagi.',
    [SurveyCampaignViewKeys.surveyCampaignView_completingSurveyCampaignPrompt]: 'Sedang ditamatkan',
    [SurveyCampaignViewKeys.surveyCampaignView_completedSurveyCampaignPrompt]: 'Sudah ditamatkan',
    [SurveyCampaignViewKeys.surveyCampaignView_deleteCampaignPrompt]: 'Tinjauan ini akan dipadamkan.',
    [SurveyCampaignViewKeys.surveyCampaignView_moreTooltip]: 'Lebih',
    [SurveyCampaignViewKeys.surveyCampaignView_deletingSurveyCampaignPrompt]: 'Sedang dipadamkan',
    [SurveyCampaignViewKeys.surveyCampaignView_deletedSurveyCampaignPrompt]: 'Dipadamkan',
    [SurveyCampaignViewKeys.surveyCampaignView_restoreCampaignPrompt]: 'Tinjauan ini akan dipulihkan',
    [SurveyCampaignViewKeys.surveyCampaignView_restoringSurveyCampaignPrompt]: 'Sedang dipulihankan',
    [SurveyCampaignViewKeys.surveyCampaignView_restoredSurveyCampaignPrompt]: 'Dipulihkan',
    [SurveyCampaignViewKeys.surveyCampaignView_surveyString]: 'Tinjauan',
    [SurveyCampaignViewKeys.surveyCampaignView_createdString]: 'Dicipta pada',

}