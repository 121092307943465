import { SurveyCampaignKeys } from "./keys"

export const englishValues = {
    [SurveyCampaignKeys.surveyCampaigns_surveyCampaignsHeader]: 'Survey Campaigns',
    [SurveyCampaignKeys.surveyCampaigns_searchPlaceholder]: 'Search',
    [SurveyCampaignKeys.surveyCampaigns_statusString]: 'Status',
    [SurveyCampaignKeys.surveyCampaigns_surveyString]: 'Survey',
    [SurveyCampaignKeys.surveyCampaigns_surveyAllDropdown]: "All",
    [SurveyCampaignKeys.surveyCampaigns_statusAllDropdown]: "All",
    [SurveyCampaignKeys.surveyCampaigns_nameTableHeader]: 'Name',
    [SurveyCampaignKeys.surveyCampaigns_surveyTableHeader]: 'Survey',
    [SurveyCampaignKeys.surveyCampaigns_createdTableHeader]: 'Created at',
    [SurveyCampaignKeys.surveyCampaigns_selectFromLeftString]: 'Select a survey campaign from the left',
}

export const malayValues = {
    [SurveyCampaignKeys.surveyCampaigns_surveyCampaignsHeader]: 'Kempen Tinjauan',
    [SurveyCampaignKeys.surveyCampaigns_searchPlaceholder]: 'Cari',
    [SurveyCampaignKeys.surveyCampaigns_statusString]: 'Status',
    [SurveyCampaignKeys.surveyCampaigns_surveyString]: 'Tinjauan',
    [SurveyCampaignKeys.surveyCampaigns_surveyAllDropdown]: "Semua",
    [SurveyCampaignKeys.surveyCampaigns_statusAllDropdown]: "Semua",
    [SurveyCampaignKeys.surveyCampaigns_nameTableHeader]: 'Nama',
    [SurveyCampaignKeys.surveyCampaigns_surveyTableHeader]: 'Tinjauan',
    [SurveyCampaignKeys.surveyCampaigns_createdTableHeader]: 'Dicipta pada',
    [SurveyCampaignKeys.surveyCampaigns_selectFromLeftString]: 'Sila pilih kempen tinjauan di sebelah kiri',  
}