import { Button, Typography } from 'antd'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import TEST_IDS from '../../constants/cypressTestIds'
import {
    SurveyResponse, SurveyResponseStatusLabels
} from '../../constants/types'
import { parseTime } from '../../util'
import styles from './Landing.module.css'
import { ERROR_TITLES } from '.'
import { SurveyResponsePageKeys } from '../../services/i8tn/SurveyResponsePage/keys'

export default function Landing(props: {
    surveyResponse?: SurveyResponse,
    parsedPrompt?: string,
    errorMessage?: ERROR_TITLES,
}) {
    const [errorMessage, setErrorMessage] = useState<ERROR_TITLES | undefined>()
    const [parsedPrompt, setParsedPrompt] = useState<string | undefined>()
    const [surveyResponse, setSurveyResponse] = useState<SurveyResponse | undefined>()

    useEffect(() => {
        setErrorMessage(props.errorMessage)
        setParsedPrompt(props.parsedPrompt)
        setSurveyResponse(props.surveyResponse)
    }, [props])

    const getErrorMessageDescription = (errorCode: ERROR_TITLES) => {
        switch (errorCode) {
            case ERROR_TITLES.surveyResponseAlreadySubmitted: {
                return (
                    <>
                        <Typography.Title style={{ color: 'white' }} level={1}>
                            {t(SurveyResponsePageKeys.surveyResponsePage_surveyAlreadySubmittedTitleString)}
                        </Typography.Title>
                        <h3 className={styles.h3}>{t(SurveyResponsePageKeys.surveyResponsePage_surveyAlreadySubmittedSubtitleString)}</h3>
                    </>
                )
            }
            case ERROR_TITLES.surveyResponseAlreadyCancelled: {
                return (
                    <>
                        <Typography.Title style={{ color: 'white' }} level={1}>
                            {t(SurveyResponsePageKeys.surveyResponsePage_surveyCancelledTitleString)}
                        </Typography.Title>
                        <h3 className={styles.h3}>
                            {t(SurveyResponsePageKeys.surveyResponsePage_surveyCancelledSubtitleString)}
                        </h3>
                    </>
                )
            }
            case ERROR_TITLES.surveyCampaignDraft: {
                return (
                    <>
                        <Typography.Title style={{ color: 'white' }} level={1}>
                            {t(SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDraftTitleString)}
                        </Typography.Title>
                        <h3 className={styles.h3}>
                            {t(SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDraftSubtitleString)}
                        </h3>
                    </>
                )
            }
            case ERROR_TITLES.surveyCampaignDeleted: {
                return (
                    <>
                        <Typography.Title style={{ color: 'white' }} level={1}>
                            {t(SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDeletedTitleString)}
                        </Typography.Title>
                        <h3 className={styles.h3}>
                            {t(SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDeletedSubtitleString)}
                        </h3>
                    </>
                )
            }
            default: {
                return (
                    <>
                        <Typography.Title style={{ color: 'white' }} level={1}>
                            <>{t(SurveyResponsePageKeys.surveyResponsePage_somethingsWrongWithSurveyString)}</>
                        </Typography.Title>
                        <h3 className={styles.h3}>
                            {errorMessage}
                        </h3>
                    </>
                )
            }
        }
    }

    return (
        <div
            className={styles.container}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    maxWidth: 500,
                }}
            >
                {
                    errorMessage ? (

                        getErrorMessageDescription(errorMessage)
                    ) : (
                        <>
                            <div
                                style={{
                                    marginBottom: 50
                                }}
                            >

                                <Typography.Title style={{ color: 'white' }} level={1}>
                                    Hello, {surveyResponse?.surveyEmployee.fname}  {surveyResponse?.surveyEmployee.lname}.
                                </Typography.Title>
                                <h3 style={{ color: 'white' }} >
                                    {parsedPrompt}
                                </h3>
                                {
                                    surveyResponse?.status === SurveyResponseStatusLabels.DRAFT ? (
                                        <p
                                            style={{
                                                color: 'white',
                                                fontStyle: 'italic'
                                            }}
                                        >
                                            Last saved: {parseTime(surveyResponse?.updatedAt)}
                                        </p>
                                    ) : null
                                }
                            </div>
                            <Button
                                className={styles.button}
                                href={`/survey/questions/${window.location.search}`}
                                data-testid={TEST_IDS.SURVEY_RESPONSE_PAGE.start_survey_button_id}
                            >
                                {
                                    surveyResponse?.status === SurveyResponseStatusLabels.DRAFT ? `Continue` : <>{t(SurveyResponsePageKeys.surveyResponsePage_startBtn)}</>
                                }
                            </Button>
                        </>
                    )
                }

            </div>
        </div>
    )
}