import { Layout } from 'antd';
import React from 'react';
import PublicRoutes from '../../routes/PublicRoutes/index';

const { Content } = Layout;

function PublicLayout() {

    return (

        <Layout style={{ minHeight: '100vh' }}>
            <Content>
                <PublicRoutes />
            </Content>
        </Layout>
    )
}

export default PublicLayout