const config = {
    api: process.env.REACT_APP_API,
    version: process.env.REACT_APP_VERSION,
    env: process.env.REACT_APP_ENVIRONMENT,
    assets: {
        employees_template_csv_url: process.env.REACT_APP_EMPLOYEES_TEMPLATE_CSV_URL,
        employees_360_template_2_xlsx_url: process.env.REACT_APP_360_EMPLOYEES_TEMPLATE_XLXS_URL,
    },
    alicloudOssDomainPanelUrl: process.env.REACT_APP_ALICLOUD_OSS_DOMAIN_PANEL_URL
}

export default config