import { DashboardPageKeys } from "./keys"

export const englishValues = {
    [DashboardPageKeys.dashboardPage_whatSurveyString]: 'What kind of survey would you like to conduct today?',
    [DashboardPageKeys.dashboardPage_createBtn]: 'Create',
    [DashboardPageKeys.dashboardPage_comingSoonBtn]: 'Coming soon',
    [DashboardPageKeys.dashboardRecentSurvey_recentSurveyString]: 'Recent surveys',
    [DashboardPageKeys.dashboardRecentSurvey_viewAllString]: 'View all',
    [DashboardPageKeys.dashboardPage_howString]: 'How do I create a survey?',
    [DashboardPageKeys.dashboardPage_howToCreateString]: 'How do I create a survey?',
    [DashboardPageKeys.dashboardPage_howToAddString]: 'How do I add new participants to a campaign?',
    [DashboardPageKeys.dashboardPage_howToRespondString]: 'How do I respond to a survey?',
    [DashboardPageKeys.dashboardPage_howToViewString]: 'How do I view reports?',
}

export const malayValues = {
    [DashboardPageKeys.dashboardPage_whatSurveyString]: 'Apakah jenis tinjauan yang anda ingin laksanakan hari ini?',
    [DashboardPageKeys.dashboardPage_createBtn]: 'Cipta',
    [DashboardPageKeys.dashboardPage_comingSoonBtn]: 'Bakal datang',
    [DashboardPageKeys.dashboardRecentSurvey_recentSurveyString]: 'Tinjauan terbaharu',
    [DashboardPageKeys.dashboardRecentSurvey_viewAllString]: 'Tunjukkan semua',
    [DashboardPageKeys.dashboardPage_howString]: 'Bagaimana caranya untuk saya mencipta tinjauan?',
    [DashboardPageKeys.dashboardPage_howToCreateString]: 'Bagaimana caranya untuk saya mencipta tinjaun?',
    [DashboardPageKeys.dashboardPage_howToAddString]: 'Bagaimana caranya untuk saya tambah peserta baharu kepada kempen?',
    [DashboardPageKeys.dashboardPage_howToRespondString]: 'Bagaimana caranya untuk saya membalas tinjauan?',
    [DashboardPageKeys.dashboardPage_howToViewString]: 'Bagaimana caranya untuk saya melihat laporan?',

}