export enum SurveyCampaignKeys {
    surveyCampaigns_surveyCampaignsHeader = 'surveyCampaigns_surveyCampaignsHeader',
    surveyCampaigns_statusString = 'surveyCampaigns_statusString',
    surveyCampaigns_surveyString = 'surveyCampaigns_surveyString',
    surveyCampaigns_surveyAllDropdown = 'surveyCampaigns_surveyAllDropdown',
    surveyCampaigns_statusAllDropdown = 'surveyCampaigns_statusAllDropdown',
    surveyCampaigns_searchPlaceholder = 'surveyCampaigns_searchPlaceholder',
    surveyCampaigns_nameTableHeader = 'surveyCampaigns_nameTableHeader',
    surveyCampaigns_surveyTableHeader = 'surveyCampaigns_surveyTableHeader',
    surveyCampaigns_createdTableHeader = 'surveyCampaigns_createdTableHeader',
    surveyCampaigns_selectFromLeftString = 'surveyCampaigns_selectFromLeftString',
}