import { LoadingOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { GetSurveyResponseDetailRequest } from '../../constants/apiRequestResponse';
import {
    SurveyCampaignStatusLabels,
    SurveyQuestion,
    SurveyResponse,
    SurveyResponseStatusLabels
} from '../../constants/types';
import { getSurveyResponseDetail } from '../../services/api';
import { getAllQueryParams } from '../../util/index';
import Landing from './Landing';
import Questions from './Questions';


export enum ERROR_TITLES {
    invalidSurveyResponse = 'Invalid survey.',
    surveyResponseAlreadySubmitted = 'Response submitted',
    surveyResponseAlreadyCancelled = 'This survey had been cancelled.',
    invalidSurveyCampaign = 'Invalid survey campaign.',
    surveyCampaignDraft = 'This survey campaign had yet to be started.',
    surveyCampaignClosed = 'This survey campaign is closed.',
    surveyCampaignDeleted = 'This survey campaign is deleted.',
    invalidSurveyTemplate = 'Invalid survey template.',
    others = 'Unknown error.',
}

export default function SurveyResponsePage() {

    let { url } = useRouteMatch();

    const [errorMessage, setErrorMessage] = useState<ERROR_TITLES | undefined>()
    const [surveyCampaign, setSurveyCampaign] = useState<{
        questions: SurveyQuestion[],
        prompt: string,
    } | undefined>()
    const [surveyResponse, setSurveyResponse] = useState<SurveyResponse | undefined>()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        const queryParams = getAllQueryParams()
        setIsLoading(true)

        const req: GetSurveyResponseDetailRequest = {
            surveyResponseId: queryParams.s || queryParams.surveyResponseId
        }

        getSurveyResponseDetail(req)
            .then(res => {
                if (!res.success) throw res

                setSurveyResponse(res.surveyResponse)
                setSurveyCampaign(res.surveyCampaign)

                switch (res.surveyResponse.status) {
                    case SurveyResponseStatusLabels.COMPLETED:
                        throw new Error(ERROR_TITLES.surveyResponseAlreadySubmitted)

                    case SurveyResponseStatusLabels.CANCELLED:
                        throw new Error(ERROR_TITLES.surveyResponseAlreadyCancelled)
                }

                switch (res.surveyCampaign.status) {
                    case SurveyCampaignStatusLabels.draft:
                        throw new Error(ERROR_TITLES.surveyCampaignDraft)

                    case SurveyCampaignStatusLabels.deleted:
                        throw new Error(ERROR_TITLES.surveyCampaignDeleted)
                }
            })
            .catch(err => {
                setErrorMessage(err.message || ERROR_TITLES.invalidSurveyResponse)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    if (isLoading) {
        return <div
            style={{
                backgroundColor: 'black',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}
        >
            <LoadingOutlined
                style={{
                    color: 'white',
                    fontSize: 100
                }}
                spin
            />
        </div>
    }

    return (
        <Switch>
            <Route
                path={`${url}/questions`}
            >
                <Questions
                    surveyResponse={surveyResponse}
                    surveyQuestions={surveyCampaign?.questions}
                />
            </Route>
            <Route
                path={`${url}`}
            >
                <Landing
                    surveyResponse={surveyResponse}
                    parsedPrompt={surveyCampaign?.prompt}
                    errorMessage={errorMessage}
                />
            </Route>
        </Switch>
    )

}