import { ForgotPasswordPageKeys } from "./keys";


export const englishValues = {
    [ForgotPasswordPageKeys.forgotPasswordPage_forgotPasswordString]: 'Forgot password?',
    [ForgotPasswordPageKeys.forgotPasswordPage_enterYourEmailString]: 'Enter your email and we will send you a link to reset your password.',
    [ForgotPasswordPageKeys.forgotPasswordPage_emailPlaceholder]: 'Email',
    [ForgotPasswordPageKeys.forgotPasswordPage_backToLoginBtn]: 'Back to login',
    [ForgotPasswordPageKeys.forgotPassword_pageSendBtn]: 'Send',
    [ForgotPasswordPageKeys.forgotPasswordPage_sentLinkString]: "We've sent the link to your email.",
    [ForgotPasswordPageKeys.forgotPasswordPage_resetYourPasswordString]: 'Click on it to reset your password.',
    [ForgotPasswordPageKeys.forgotPasswordPage_linkExpiresString]: 'The link expires within 24 hours.',
    [ForgotPasswordPageKeys.forgotPasswordPage_backToLoginLink]: 'Back to login',
}

export const malayValues = {
    [ForgotPasswordPageKeys.forgotPasswordPage_forgotPasswordString]: 'Terlupa kata laluan?',
    [ForgotPasswordPageKeys.forgotPasswordPage_enterYourEmailString]: 'Sila masukkan e-mel anda dan kami akan menghantar pautan untuk menetap semula kata laluan anda.',
    [ForgotPasswordPageKeys.forgotPasswordPage_emailPlaceholder]: 'E-mel',
    [ForgotPasswordPageKeys.forgotPasswordPage_backToLoginBtn]: 'Kembali',
    [ForgotPasswordPageKeys.forgotPassword_pageSendBtn]: 'Hantar',
    [ForgotPasswordPageKeys.forgotPasswordPage_sentLinkString]: "Kami sudah menghantar pautan ke alamat e-mel anda.",
    [ForgotPasswordPageKeys.forgotPasswordPage_resetYourPasswordString]: 'Sila tekan pautan tersebut untuk menetap semula kata laluan.',
    [ForgotPasswordPageKeys.forgotPasswordPage_linkExpiresString]: 'Pautan ini akan luput dalam tempoh 24 jam.',
    [ForgotPasswordPageKeys.forgotPasswordPage_backToLoginLink]: 'Kembali ke log masuk',
}