import { Tag } from 'antd'
import React from 'react'
import TEST_IDS from '../constants/cypressTestIds'
import { SurveyCampaignStatusLabels } from '../constants/types'

interface Prop {
  status?: SurveyCampaignStatusLabels
}

export default function SurveyCampaignStatusTag(prop: Prop) {
  switch (prop.status) {
    case SurveyCampaignStatusLabels.draft:
      return <Tag
        data-testid={`${TEST_IDS.SURVEY_CAMPAIGN_VIEW.status_id}`}
        color={'default'}
      >
        {SurveyCampaignStatusLabels.draft}
      </Tag>
    case SurveyCampaignStatusLabels.ongoing:
      return <Tag
        data-testid={`${TEST_IDS.SURVEY_CAMPAIGN_VIEW.status_id}`}
        color={'blue'}>
        {SurveyCampaignStatusLabels.ongoing}
      </Tag>
    case SurveyCampaignStatusLabels.completed:
      return <Tag
        data-testid={`${TEST_IDS.SURVEY_CAMPAIGN_VIEW.status_id}`}
        color={'green'}>
        {SurveyCampaignStatusLabels.completed}
      </Tag>
    case SurveyCampaignStatusLabels.deleted:
      return <Tag
        data-testid={`${TEST_IDS.SURVEY_CAMPAIGN_VIEW.status_id}`}
        color={'red'}>
        {SurveyCampaignStatusLabels.deleted}
      </Tag>
    default:
      return <Tag
        data-testid={`${TEST_IDS.SURVEY_CAMPAIGN_VIEW.status_id}`}
        color={'black'}>
        Unknown
      </Tag>
  }
}
