import { Skeleton } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import logo from '../../assets/logo.png'
import FeedbackIndividualQuestionReport from '../../components/QuestionReport/FeedbackIndividualQuestionReport'
import { GetCompanyByCompanyIdRequest, GetConstantsRequest, GetSurveyCampaignBySurveyCampaignIdRequest, GetSurveyResponseBySurveyResponseIdRequest, GetSurveyTemplatesBySurveyTemplateIdRequest } from '../../constants/apiRequestResponse'
import { COLORS } from '../../constants/misc'
import { Company, SurveyResponse, SurveyTemplate } from '../../constants/types'
import { getCompanyByCompanyId, getConstants, getSurveyCampaignBySurveyCampaignId, getSurveyResponseBySurveyResponseId, getSurveyTemplateBySurveyTemplateId } from '../../services/api'
import { getQueryParam } from '../../util'

export default function FeedbackIndividualPrintView() {

    const surveyResponseId = getQueryParam('surveyResponseId') || undefined
    const [isLoading, setIsLoading] = useState(false)
    const [company, setCompany] = useState<Company | undefined>()
    const [surveyTemplate, setSurveyTemplate] = useState<SurveyTemplate | undefined>()
    const [surveyResponse, setSurveyResponse] = useState<SurveyResponse | undefined>()
    const [introductoryStatement, setIntroductoryStatement] = useState<string>('')

    const getData = useCallback(async () => {
        try {
            setIsLoading(true)

            /**Get survey response */
            const req1: GetSurveyResponseBySurveyResponseIdRequest = {
                surveyResponseId: surveyResponseId
            }
            const res1 = await getSurveyResponseBySurveyResponseId(req1)
            if (!res1.success) throw res1.message
            setSurveyResponse(res1.surveyResponse)

            /**Get survey campaign */
            const req2: GetSurveyCampaignBySurveyCampaignIdRequest = {
                surveyCampaignId: res1.surveyResponse?.surveyCampaignId
            }
            const res2 = await getSurveyCampaignBySurveyCampaignId(req2)
            if (!res2.success) throw res2.message

            /**Get company */
            const companyId = res2.surveyCampaign.companyId
            const req3: GetCompanyByCompanyIdRequest = {
                companyId: companyId
            }
            const res3 = await getCompanyByCompanyId(req3)
            if (!res3.success) throw res3.message
            setCompany(res3.company)

            /**Get survey template */
            const req4: GetSurveyTemplatesBySurveyTemplateIdRequest = {
                surveyTemplateId: res2.surveyCampaign.surveyTemplateId
            }
            const res4 = await getSurveyTemplateBySurveyTemplateId(req4)
            if (!res4.success) throw res4.message
            setSurveyTemplate(res4.surveyTemplate)

            /**Get constants */
            const req5: GetConstantsRequest = {
                keys: ['360IndividualReport.introductory.statement'],
                parsed: true,
                surveyResponseId: surveyResponseId,
            }
            const res5 = await getConstants(req5)
            if (!res5.success) throw res5.message
            setIntroductoryStatement(res5.constants ? res5.constants['360IndividualReport.introductory.statement'] : '')
            console.log(res5)
        } catch (err) {
            console.log(err)
        }

        setIsLoading(false)
    }, [surveyResponseId])

    useEffect(() => {
        getData()
    }, [surveyResponseId, getData])

    if (isLoading) {
        return (
            <div
                style={{
                    ...styles.page,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}
            >
                <Skeleton active />
            </div>
        )
    }

    return (
        <>
            <div
                style={{
                    ...styles.page,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}
            >
                <div>
                    <img alt="" src={company?.logo} height={150} />

                    <div
                        style={{
                            marginTop: 100
                        }}
                    >
                        <p
                            style={{ ...styles.survey_template_title, color: COLORS.PRIMARY_COLOR, }}
                        >
                            {surveyTemplate?.name}
                        </p>
                        <p
                            style={styles.report_type_title}
                        >
                            Individual Report
                        </p>
                        <br />

                        <h2 style={{ color: 'grey' }}>
                            {surveyResponse?.surveyEmployee.fname} {surveyResponse?.surveyEmployee.lname}
                        </h2>
                        <h2 style={{ color: 'grey' }}>
                            {moment().format('Do MMMM YYYY')}
                        </h2>
                    </div>
                </div>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                    }}
                >
                    <div
                        style={{
                            width: '30%'
                        }}
                    >
                        <p><b>This report was prepared by</b></p>

                        <img alt="" src={logo} height={100} />
                    </div>

                    <div
                        style={{
                            width: '30%'
                        }}
                    >
                        <p><b>Confidential</b></p>
                        <p style={{ color: 'grey' }}>
                            This document contains confidential information that should not be shared with unauthorised persons
                        </p>
                    </div>
                </div>
            </div>

            <div
                style={styles.page}
                dangerouslySetInnerHTML={{__html: introductoryStatement}}
            >
            </div>
            <div
                style={styles.page}
            >
                <FeedbackIndividualQuestionReport
                    surveyResponseId={surveyResponseId}
                />
            </div>

        </>
    )
}

const styles = {
    page: {
        minHeight: '100vh',
        minWidht: '100vw',
        backgroundColor: 'white',
        padding: 30,
    },
    survey_template_title: {
        fontSize: 60,
        fontWeight: 500,
    },
    report_type_title: {
        fontSize: 80,
        fontWeight: 1000,
        lineHeight: 1
    }
}