export enum ForgotPasswordPageKeys {
    forgotPasswordPage_forgotPasswordString = 'forgotPasswordPage_forgotPasswordString',
    forgotPasswordPage_enterYourEmailString = 'forgotPasswordPage_enterYourEmailString',
    forgotPasswordPage_emailPlaceholder = 'forgotPasswordPage_emailPlaceholder',
    forgotPasswordPage_backToLoginBtn = 'forgotPasswordPage_backToLoginBtn',
    forgotPassword_pageSendBtn = 'forgotPassword_pageSendBtn',
    forgotPasswordPage_sentLinkString = 'forgotPasswordPage_sentLinkString',
    forgotPasswordPage_resetYourPasswordString = 'forgotPasswordPage_resetYourPasswordString',
    forgotPasswordPage_linkExpiresString = 'forgotPasswordPage_linkExpiresString',
    forgotPasswordPage_backToLoginLink = 'forgotPasswordPage_backToLoginLink',
}
