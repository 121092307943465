export enum ReportsPageKeys {
    reportsPage_reportsTitleString = 'reportsPage_reportsTitleString',
    reportsPage_searchString = 'reportsPage_searchString',
    reportsPage_engagementSurveyPlaceholder = 'reportsPage_engagementSurveyPlaceholder',
    reportsPage_surveyString = 'reportsPage_surveyString',
    reportsPage_allDropdown = 'reportsPage_allDropdown',
    reportsPage_searchPrompt = 'reportsPage_searchPrompt',
    reportsPage_clearFiltersPrompt = 'reportsPage_clearFiltersPrompt',
    reportsPage_clearedPrompt = 'reportsPage_clearedPrompt',
    reportsPage_lastUpdateString = 'reportsPage_lastUpdateString',
    reportsPage_viewReportBtn = 'reportsPage_viewReportBtn',
    reportsPage_endString = 'reportsPage_endString',
}