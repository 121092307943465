import { EditEmailTemplatePageKeys } from "./keys"

export const englishValues = {
    [EditEmailTemplatePageKeys.editEmailTemplate_editEmailTemplateTitle]: 'Edit email template',
    [EditEmailTemplatePageKeys.editEmailTemplate_nameString]: 'Name',
    [EditEmailTemplatePageKeys.editEmailTemplate_linkedSurveyTemplateString]: 'Linked survey template',
    [EditEmailTemplatePageKeys.editEmailTemplate_subjectString]: 'Subject',
    [EditEmailTemplatePageKeys.editEmailTemplate_contentString]: 'Content',
    [EditEmailTemplatePageKeys.editEmailTemplate_variablesString]: 'Variables',
    [EditEmailTemplatePageKeys.editEmailTemplate_introDynamicValuesString]: 'Introduce dynamic values into your email template.',
    [EditEmailTemplatePageKeys.editEmailTemplate_variableNameTableHeader]: 'Variable name',
    [EditEmailTemplatePageKeys.editEmailTemplate_descriptionTableHeader]: 'Description',
    [EditEmailTemplatePageKeys.editEmailTemplate_cancelBtn]: 'Cancel',
    [EditEmailTemplatePageKeys.editEmailTemplate_saveBtn]: 'Save',
    [EditEmailTemplatePageKeys.editEmailTemplate_savePrompt]: 'This will only affect upcoming emails, and not past emails.',

}

export const malayValues = {
    [EditEmailTemplatePageKeys.editEmailTemplate_editEmailTemplateTitle]: 'Edit templat e-mel',
    [EditEmailTemplatePageKeys.editEmailTemplate_nameString]: 'Nama',
    [EditEmailTemplatePageKeys.editEmailTemplate_linkedSurveyTemplateString]: 'Templat tinjauan berpautan',
    [EditEmailTemplatePageKeys.editEmailTemplate_subjectString]: 'Subjek',
    [EditEmailTemplatePageKeys.editEmailTemplate_contentString]: 'Kandungan',
    [EditEmailTemplatePageKeys.editEmailTemplate_variablesString]: 'Pembolehubah',
    [EditEmailTemplatePageKeys.editEmailTemplate_introDynamicValuesString]: 'Masukkan kandungan dinamik ke dalam templat e-mel anda.',
    [EditEmailTemplatePageKeys.editEmailTemplate_variableNameTableHeader]: 'Nama pembolehubah',
    [EditEmailTemplatePageKeys.editEmailTemplate_descriptionTableHeader]: 'Penghuraian',
    [EditEmailTemplatePageKeys.editEmailTemplate_cancelBtn]: 'Batal',
    [EditEmailTemplatePageKeys.editEmailTemplate_saveBtn]: 'Simpan',
    [EditEmailTemplatePageKeys.editEmailTemplate_savePrompt]: 'Tindakan ini akan mengubah e-mel yang baharu sahaja, dan tidak akan menjejaskan e-mel sebelum ini.',

}