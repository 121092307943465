export enum SurveyCampaignFormKeys {
    surveyCampaignForm_runASurveyString = 'surveyCampaignForm_runASurveyString',
    surveyCampaignForm_basicInfoString = 'surveyCampaignForm_basicInfoString',
    surveyCampaignForm_campaignNameString = 'surveyCampaignForm_campaignNameString',
    surveyCampaignForm_saveBtn = 'surveyCampaignForm_saveBtn',
    surveyCampaignForm_nextBtn = 'surveyCampaignForm_nextBtn',
    surveyCampaignForm_backBtn = 'surveyCampaignForm_backBtn',
    surveyCampaignForm_uploadEmployeesString = 'surveyCampaignForm_uploadEmployeesString',
    surveyCampaignForm_uploadEmployeesTitleString = 'surveyCampaignForm_uploadEmployeesTitleString',
    surveyCampaignForm_uploadEmployeesDownloadString = 'surveyCampaignForm_uploadEmployeesDownloadString',
    surveyCampaignForm_uploadEmployeesSampleCSVLink = 'surveyCampaignForm_uploadEmployeesSampleCSVLink',
    surveyCampaignForm_uploadEmployeesExampleString = 'surveyCampaignForm_uploadEmployeesExampleString',
    surveyCampaignForm_uploadEmployeesSupportedFormatsString = 'surveyCampaignForm_uploadEmployeesSupportedFormatsString',
    surveyCampaignForm_uploadEmployeesOverridePrompt = 'surveyCampaignForm_uploadEmployeesOverridePrompt',
    surveyCampaignForm_errorsFoundString = 'surveyCampaignForm_errorsFoundString',
    surveyCampaignForm_uploadEmployeesGoodToGoPrompt = 'surveyCampaignForm_uploadEmployeesGoodToGoPrompt',
    surveyCampaignForm_addAnEmployeeBtn = 'surveyCampaignForm_addAnEmployeeBtn',
    surveyCampaignForm_employeesCSVNoDataFoundString = 'surveyCampaignForm_employeesCSVNoDataFoundString',
    surveyCampaignForm_employeesFirstNameTableHeader = 'surveyCampaignForm_employeesFirstNameTableHeader',
    surveyCampaignForm_employeesLastNameTableHeader = 'surveyCampaignForm_employeesLastNameTableHeader',
    surveyCampaignForm_employeesPositionTableHeader = 'surveyCampaignForm_employeesPositionTableHeader',
    surveyCampaignForm_employeesEmailTableHeader = 'surveyCampaignForm_employeesEmailTableHeader',
    surveyCampaignForm_employeesTeamTableHeader = 'surveyCampaignForm_employeesTeamTableHeader',
    surveyCampaignForm_employeesGenderTableHeader = 'surveyCampaignForm_employeesGenderTableHeader',
    surveyCampaignForm_employeesErrorsTableHeader = 'surveyCampaignForm_employeesErrorsTableHeader',
    surveyCampaignForm_employeesActionTableHeader = 'surveyCampaignForm_employeesActionTableHeader',
    surveyCampaignForm_employeesAddNewEmployeeString = 'surveyCampaignForm_employeesAddNewEmployeeString',
    surveyCampaignForm_employeesAddedString = 'surveyCampaignForm_employeesAddedString',
    surveyCampaignForm_employeesAddFormFirstNameString = 'surveyCampaignForm_employeesAddFormFirstNameString',
    surveyCampaignForm_employeesAddFormLastNameString = 'surveyCampaignForm_employeesAddFormLastNameString',
    surveyCampaignForm_employeesAddFormEmailString = 'surveyCampaignForm_employeesAddFormEmailString',
    surveyCampaignForm_emailMustEndString = 'surveyCampaignForm_emailMustEndString',
    surveyCampaignForm_egJohnDoeString = 'surveyCampaignForm_egJohnDoeString',
    surveyCampaignForm_employeesAddFormGenderString = 'surveyCampaignForm_employeesAddFormGenderString',
    surveyCampaignForm_employeesAddFormPositionString = 'surveyCampaignForm_employeesAddFormPositionString',
    surveyCampaignForm_employeesAddFormTeamString = 'surveyCampaignForm_employeesAddFormTeamString',
    surveyCampaignForm_employeesAddFormDeleteBtn = 'surveyCampaignForm_employeesAddFormDeleteBtn',
    surveyCampaignForm_employeesAddFormSaveBtn = 'surveyCampaignForm_employeesAddFormSaveBtn',
    surveyCampaignForm_employeesAddConfirmDeletePrompt = 'surveyCampaignForm_employeesAddConfirmDeletePrompt',
    surveyCampaignForm_deletedString = 'surveyCampaignForm_deletedString',
    surveyCampaignForm_updatedString = 'surveyCampaignForm_updatedString',
    surveyCampaignForm_employeesEditFormFirstNameString = 'surveyCampaignForm_employeesEditFormFirstNameString',
    surveyCampaignForm_employeesEditFormLastNameString = 'surveyCampaignForm_employeesEditFormLastNameString',
    surveyCampaignForm_employeesEditFormEmailString = 'surveyCampaignForm_employeesEditFormEmailString',
    surveyCampaignForm_employeesEditFormGenderString = 'surveyCampaignForm_employeesEditFormGenderString',
    surveyCampaignForm_employeesEditFormPositionString = 'surveyCampaign360Form_employeesEditFormPositionString',
    surveyCampaignForm_employeesEditFormTeamString = 'surveyCampaignForm_employeesEditFormTeamString',
    surveyCampaignForm_employeesEditFormDeleteBtn = 'surveyCampaignForm_employeesEditFormDeleteBtn',
    surveyCampaignForm_employeesEditFormSaveBtn = 'surveyCampaignForm_employeesEditFormSaveBtn',
    surveyCampaignForm_employeesEditConfirmDeletePrompt = 'surveyCampaignForm_employeesEditConfirmDeletePrompt',
    surveyCampaignForm_previewAndSendString = 'surveyCampaignForm_previewAndSendString',
    surveyCampaignForm_previewAndSendTitleString = 'surveyCampaignForm_previewAndSendTitleString',
    surveyCampaignForm_previewUploadedEmployeesString = 'surveyCampaignForm_previewUploadedEmployeesString',
    surveyCampaignForm_previewFirstNameTableHeader = 'surveyCampaignForm_previewFirstNameTableHeader',
    surveyCampaignForm_previewLastNameTableHeader = 'surveyCampaignForm_previewLastNameTableHeader',
    surveyCampaignForm_previewPositionTableHeader = 'surveyCampaignForm_previewPositionTableHeader',
    surveyCampaignForm_previewEmailTableHeader = 'surveyCampaignForm_previewEmailTableHeader',
    surveyCampaignForm_previewTeamTableHeader = 'surveyCampaignForm_previewTeamTableHeader',
    surveyCampaignForm_previewGenderTableHeader = 'surveyCampaignForm_previewGenderTableHeader',
    surveyCampaignForm_previewMoreActionsDropdown = 'surveyCampaignForm_previewMoreActionsDropdown',
    surveyCampaignForm_previewExampleEmailString = 'surveyCampaignForm_previewExampleEmailString',
    surveyCampaignForm_previewRefreshDropdown = 'surveyCampaignForm_previewRefreshDropdown',
    surveyCampaignForm_previewEditTemplateDropdown = 'surveyCampaignForm_previewEditTemplateDropdown',
    surveyCampaignForm_faqString = 'surveyCampaignForm_faqString',
    surveyCampaignForm_whatIsLinkString = 'surveyCampaignForm_whatIsLinkString',
    surveyCampaignForm_uniqueLinkString = 'surveyCampaignForm_uniqueLinkString',
    surveyCampaignForm_previewBackBtn = 'surveyCampaignForm_previewBackBtn',
    surveyCampaignForm_previewDraftBtn = 'surveyCampaignForm_previewDraftBtn',
    surveyCampaignForm_previewSendBtn = 'surveyCampaignForm_previewSendBtn',
    surveyCampaignForm_previewSendAreYouSurePrompt = 'surveyCampaignForm_previewSendAreYouSurePrompt',
    surveyCampaignForm_sendingSurveyPrompt = 'surveyCampaignForm_sendingSurveyPrompt',
    surveyCampaignForm_surveyCampaignStartedPrompt = 'surveyCampaignForm_surveyCampaignStartedPrompt',
    surveyCampaignForm_surveyCampaignBreadcrumb = 'surveyCampaignForm_surveyCampaignBreadcrumb',
}