import {
    UserOutlined
} from '@ant-design/icons';
import { Dropdown, Layout, Menu } from 'antd';
import React, { useContext } from 'react';
import { SessionContext } from '../../App';
import logo from '../../assets/logo.png';
import config from '../../config';
import { COLORS } from '../../constants/misc';
import AdminRoutes from '../../routes/AdminRoutes';
import { logout } from '../../services/api';
import styles from './AdminLayout.module.css';

const { Header, Content, Footer } = Layout;

function AdminLayout() {
    const activeKey = window.location.pathname
    const context = useContext(SessionContext)

    return (

        <Layout style={{ minHeight: '100vh', }}>
            <Header
                style={{
                    backgroundColor: COLORS.BACKGROUND_COLOR_DARK,
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0 20px',
                }}
            >
                <div
                    style={{ minWidth: 300 }}
                >
                    <a href='/'>
                        <img
                            src={logo} width={20}
                            alt=""
                        />
                        <h3
                            className={styles.logo_title}
                            style={{ color: 'white' }}
                        >
                            Admin
                        </h3>
                    </a>
                </div>
                <Menu
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        backgroundColor: COLORS.BACKGROUND_COLOR_DARK,
                        borderColor: COLORS.BACKGROUND_COLOR_DARK,
                        color: 'white'
                    }}
                    selectedKeys={[activeKey]} mode="horizontal" defaultSelectedKeys={['/']}>
                    <Menu.Item key='/profile'>
                        <Dropdown
                            overlay={<Menu>
                                <Menu.Item key='/profile' >
                                    <a href='/profile'>
                                        Profile
                                    </a>
                                </Menu.Item>
                                <Menu.Item
                                    key='/login'
                                    onClick={() => {
                                        logout()
                                        window.location.href = '/login'
                                    }}
                                >
                                    Logout
                                </Menu.Item>
                            </Menu>}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 5
                                }}
                            >
                                <UserOutlined style={{ fontSize: 20 }} />
                                {context?.companyUser?.name}
                            </div>
                        </Dropdown>

                    </Menu.Item>
                </Menu>
            </Header>
            <Content
            >
                <AdminRoutes />
            </Content>
            <Footer style={{
                textAlign: 'left',
                backgroundColor: COLORS.BACKGROUND_COLOR_DARK,
                color: 'white',
            }}>
                The Development Center Survey Platform {config.version}
            </Footer>
        </Layout>
    )
}

export default AdminLayout