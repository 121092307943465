import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { json } from '@codemirror/lang-json';
import CodeMirror from '@uiw/react-codemirror';
import { Alert, Button, Collapse, Form, Modal, PageHeader, Switch, Table, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import { DisableSurveyTemplateRequest, EnableSurveyTemplateRequest, GetSurveyTemplatesRequest, UpdateSurveyTemplateRequest } from '../../../constants/apiRequestResponse';
import { SurveyQuestionType, SurveyTemplate } from '../../../constants/types';
import { disableSurveyTemplate, enableSurveyTemplate, getSurveyTemplates, updateSurveyTemplate } from '../../../services/api';
import { getQueryParam, parseTime, setAllQueryParams, setQueryParam } from '../../../util';

enum ValidateStatus {
  unvalidated,
  validating,
  error,
  success
}

enum Modes {
  edit = 'edit', create = 'create'
}

type Filters = {
  isGetDeleted: boolean
}

export default function SurveyTemplateTab() {

  const [surveyTemplates, setSurveyTemplates] = useState<SurveyTemplate[] | undefined>()
  const [surveyTemplateId, setSurveyTemplateId] = useState<string>()
  const [string, setString] = useState('')
  const [validateStatus, setValidateStatus] = useState<ValidateStatus>(ValidateStatus.unvalidated)
  const [errorMessage, setErrorMessage] = useState<string | undefined>('')
  const [mode, setMode] = useState<Modes | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isActivateLoading, setIsActivateLoading] = useState(false)
  const [isGetDataLoading, setIsGetDataLoading] = useState(false)
  const [filters, setFilters] = useState<Filters>({
    isGetDeleted: getQueryParam('isGetDeleted') === "true" ? true : false
  })

  useEffect(() => {
    getData()
  }, [])

  const getData = (filters?: Filters) => {

    setIsGetDataLoading(true)

    const req: GetSurveyTemplatesRequest = {
      isGetDeleted: filters?.isGetDeleted,
    }

    getSurveyTemplates(req)
      .then(res => {
        if (!res.success) throw new Error(res.message)

        setSurveyTemplates(res.surveyTemplates)
      })
      .catch(err => message.error(err.toString()))
      .finally(() => setIsGetDataLoading(false))
  }

  const validate = () => {
    setValidateStatus(ValidateStatus.validating)

    setTimeout(() => {

      try {
        /**Validate is valid json */
        const object: SurveyTemplate = JSON.parse(string)

        /**Validate important _ids aren't changed */
        if (object._id !== surveyTemplate?._id) throw new Error(`_id cannot be changed!`)

        /**validate question.type is within range */
        object.questions?.map(question => {
          if (
            question.type !== SurveyQuestionType.RADIO
            &&
            question.type !== SurveyQuestionType.SELECT
            &&
            question.type !== SurveyQuestionType.SLIDER
            &&
            question.type !== SurveyQuestionType.TEXT
          ) {
            throw new Error(`Invalid question.type for question where question._id === ${question._id}. Found "${question.type}", expected one of "${SurveyQuestionType.RADIO}", "${SurveyQuestionType.SELECT}", "${SurveyQuestionType.SLIDER}", "${SurveyQuestionType.TEXT}"`)
          }

          return null
        })

        setValidateStatus(ValidateStatus.success)
        setErrorMessage('')

      } catch (err) {
        const _errorMessage = typeof err === 'string' ? err : typeof err === 'object' ? err?.toString() : `Something went wrong`

        setValidateStatus(ValidateStatus.error)
        setErrorMessage(_errorMessage)
      }
    }, 1 * 1000)
  }


  const onSave = () => {
    setIsLoading(true)
    const newSurveyTemplate = JSON.parse(string)

    const req: UpdateSurveyTemplateRequest = {
      surveyTemplate: newSurveyTemplate
    }

    updateSurveyTemplate(req)
      .then(res => {
        if (!res.success) throw new Error(res.message)

        message.success('Updated')

        setTimeout(() => {
          setAllQueryParams({})
          setMode(undefined)
          getData()
        }, 0.5 * 1000)
      })
      .catch(err => {
        message.error(err?.toString())
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const surveyTemplate = surveyTemplates?.find(st => st._id === surveyTemplateId)

  return (
    <div
      style={{
        display: 'flex',
        gap: 10,
        flexDirection: 'row'
      }}
    >
      {
        mode !== Modes.edit && mode !== Modes.create ? (

          <div
            style={{
              display: 'flex',
              gap: 10,
              flexDirection: 'column',
              flex: 2,
            }}
          >
            <Form
              onFinish={(filters: Filters) => {
                setFilters(filters)
                Object.keys(filters).forEach(key => {
                  setQueryParam(key, filters[key])
                })

                getData(filters)
              }}
              initialValues={filters}
              layout='inline'
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10
              }}
            >

              <Form.Item
                name='isGetDeleted'
                label="Get inactive"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item
              >
                <Button
                  icon={<SearchOutlined />}
                  htmlType='submit'
                  type='primary'
                  loading={isGetDataLoading}
                >
                  Search
                </Button>
              </Form.Item>
            </Form>
            <Table
              rowKey={'_id'}
              dataSource={surveyTemplates}
              columns={[
                {
                  title: 'Name',
                  dataIndex: 'name'
                },
                {
                  title: 'Order',
                  dataIndex: 'order',
                },
                {
                  title: 'Status',
                  dataIndex: 'active',
                  sorter: (a, b) => {
                    if (a.active && !b.active) {
                      return 1
                    }
                    return -1
                  },
                  render: (active, surveyTemplate) => (
                    <Switch
                      unCheckedChildren="Inactive"
                      checkedChildren="Active"
                      checked={active}
                      loading={isActivateLoading}
                      style={{
                        width: 80
                      }}
                      onChange={activate => {
                        setIsActivateLoading(true)
                        if (activate) {
                          const req: EnableSurveyTemplateRequest = {
                            surveyTemplateId: surveyTemplate._id
                          }
                          enableSurveyTemplate(req)
                            .then(res => {
                              if (!res.success) throw new Error(res.message)

                              const newSurveyTemplates = surveyTemplates?.map(st => {
                                if (st._id === surveyTemplate._id) {
                                  st.active = activate
                                }
                                return st
                              })

                              setSurveyTemplates(newSurveyTemplates)

                            })
                            .catch(err => message.error(err))
                            .finally(() => setIsActivateLoading(false))
                        } else {

                          const req: DisableSurveyTemplateRequest = {
                            surveyTemplateId: surveyTemplate._id
                          }
                          disableSurveyTemplate(req)
                            .then(res => {
                              if (!res.success) throw new Error(res.message)

                              const newSurveyTemplates = surveyTemplates?.map(st => {
                                if (st._id === surveyTemplate._id) {
                                  st.active = activate
                                }
                                return st
                              })

                              setSurveyTemplates(newSurveyTemplates)
                            })
                            .catch(err => message.error(err))
                            .finally(() => setIsActivateLoading(false))
                        }

                      }}
                    />
                  )
                },
                {
                  title: 'Number of questions',
                  dataIndex: 'questions',
                  render: questions => questions.length,
                },
                {
                  title: 'Last updated',
                  dataIndex: 'updatedAt',
                  render: updatedAt => parseTime(updatedAt)
                },
                {
                  title: 'Action',
                  render: (row: SurveyTemplate) => {
                    return (
                      <EditOutlined onClick={() => {

                        Modal.confirm({
                          title: `Are you sure?`,
                          content: <p>This will affect all subsequent surveys generated with this template.</p>,
                          onOk: () => {
                            setQueryParam('layout', Modes.edit)
                            setQueryParam('surveyTemplateId', row._id)
                            setMode(Modes.edit)
                            setSurveyTemplateId(row._id)

                            const st = surveyTemplates?.find(st => st._id === row._id)
                            setString(JSON.stringify(st, null, 2))
                          }
                        })
                      }} style={{ fontSize: 20 }} />
                    )
                  }
                },
              ]}
            />
          </div>
        ) : null
      }
      {
        mode === Modes.edit ? (

          <div
            style={{
              display: 'flex',
              gap: 10,
              flexDirection: 'column',
              flex: 4,
            }}
          >
            <PageHeader
              title={`${surveyTemplate?.name}`}
              subTitle={`Last updated: ${parseTime(surveyTemplate?.updatedAt)}`}
              onBack={() => {
                setAllQueryParams({})
                setMode(undefined)
              }}
            />

            <Collapse
              defaultActiveKey={'1'}
            >
              <Collapse.Panel header="Edit" key="1">

                <CodeMirror
                  value={string}
                  height={'1000px'}
                  extensions={[json()]}
                  onChange={v => {
                    setString(v)
                    setValidateStatus(ValidateStatus.unvalidated)
                  }}
                />

                <div
                  style={{
                    display: 'flex',
                    gap: 10,
                    marginTop: 10,
                    width: '100%',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Button
                    type='primary'
                    onClick={validate}
                    disabled={validateStatus === ValidateStatus.success}
                    loading={validateStatus === ValidateStatus.validating}
                  >
                    Validate
                  </Button>
                  <Tooltip title='Revert back to original, removing all unsaved changes.'>
                    <Button
                      type='link'
                      onClick={() => {
                        setString(JSON.stringify(surveyTemplate, null, 2))
                      }}
                    >
                      Revert
                    </Button>
                  </Tooltip>
                </div>
                <br />
                {
                  validateStatus === ValidateStatus.error ? (
                    <Alert message={errorMessage} type="error" />
                  ) : validateStatus === ValidateStatus.success ? (
                    <Alert message="Validated!" type="success" />
                  ) : null
                }
              </Collapse.Panel>
              <Collapse.Panel collapsible={validateStatus === ValidateStatus.success ? undefined : 'disabled'} header="Confirm and update diff" key="3">
                {
                  validateStatus === ValidateStatus.success ? (

                    <div>
                      <ReactDiffViewer oldValue={JSON.stringify(surveyTemplate, null, 2)} newValue={string} splitView={true} />

                      <br />
                      <div
                        style={{
                          display: 'flex',
                          gap: 10,
                          width: '100%',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <Button
                          type='primary'
                          onClick={() => onSave()}
                          loading={isLoading}
                        >
                          Save
                        </Button>
                        <Button
                          type='link'
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  ) : null
                }

              </Collapse.Panel>
            </Collapse>

          </div>
        ) : null
      }
    </div>
  )
}
