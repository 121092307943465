import { NormalIndividualViewKeys } from "./keys"

export const englishValues = {
    [NormalIndividualViewKeys.normalIndividualView_emailTableHeader]: 'Email',
    [NormalIndividualViewKeys.normalIndividualView_actionsTableHeader]: 'Actions',
    [NormalIndividualViewKeys.normalIndividualView_viewReportLink]: 'View report',
    [NormalIndividualViewKeys.normalIndividualView_selectReportString]: 'Select a report from the left',

}

export const malayValues = {
    [NormalIndividualViewKeys.normalIndividualView_emailTableHeader]: 'E-mel',
    [NormalIndividualViewKeys.normalIndividualView_actionsTableHeader]: 'Tindakan',
    [NormalIndividualViewKeys.normalIndividualView_viewReportLink]: 'Tunjukkan laporan',
    [NormalIndividualViewKeys.normalIndividualView_selectReportString]: 'Pilih laporan di sebelah kiri',

}