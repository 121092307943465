
import {
  LoadingOutlined
} from '@ant-design/icons';
import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.less';
import {
  Company,
  CompanyUser,
  Roles
} from './constants/types';
import AdminLayout from './layouts/AdminLayout';
import CompanyUserLayout from './layouts/CompanyUserLayout/index';
import PublicLayout from './layouts/PublicLayout/index';
import UnauthenticatedLayout from './layouts/UnauthenticatedLayout/index';
import { getProfile } from './services/api';
import { getAllQueryParams } from './util';
import { getAccessToken } from './services/local';

interface SessionContextInterface {
  companyUser?: CompanyUser,
  company?: Company,
  layout?: Layouts
}
export const SessionContext = createContext<SessionContextInterface | null>(null)

export enum Layouts {
  unauthenticated, companyUser, admin, survey,
}

export default function App(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [companyUser, setCompanyUser] = useState<CompanyUser | undefined>(undefined)
  const [company, setCompany] = useState<Company | undefined>(undefined)
  const [layout, setLayout] = useState(Layouts.unauthenticated)

  function redirectToLogin() {
    window.location.href = `/login?redirect=${window.location.pathname}${window.location.search}`
  }

  useEffect(() => {

    (async () => {

      /**
       * 7/5/2022 daniel.kwok
       * Quite a hacky way to determine survey layout. 
       * Gotta find a better way else gonna introduce weird-ass bugs
       */
      const isPublicLayout = getIsPublicLayout()
      const pathsOmittedFromRedirects = [
        '/login', '/reset-password',
        '/login/', '/reset-password/'
      ]
      /**
       * 21/6/2022 daniel.kwok
       * Do not need to authenticate when printing or responding to surveys
       */
      if (isPublicLayout) {
        setLayout(Layouts.survey)
      } else {

        /**
         * 21/6/2022 daniel.kwok
         * Assume user is not authenticated first
         * Get company from subdomain
         * Get user from company
         */
        setLayout(Layouts.unauthenticated)

        const token = getAccessToken()

        /**if not access token found, just straight redirect to login */
        if (!token) {
          if (!pathsOmittedFromRedirects.includes(window.location.pathname)) {
            redirectToLogin()
          }
        } else {
          /**if access token found, give it a shot with getProfile(). might still be expired  */

          setIsLoading(true)
          getProfile()
            .then(getProfileRes => {
              setCompanyUser(getProfileRes?.companyUser)
              setCompany(getProfileRes.company)

              const isAdmin = getProfileRes?.companyUser?.role === Roles.ADMIN

              if (isAdmin) {
                setLayout(Layouts.admin)
              } else {
                setLayout(Layouts.companyUser)
              }
            })
            .catch(err => {
              if (!pathsOmittedFromRedirects.includes(window.location.pathname)) {
                redirectToLogin()
              }
            })
            .finally(() => {
              setIsLoading(false)
            })

        }
      }
    })();

  }, [])

  if (isLoading) {
    return <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <LoadingOutlined
        style={{ fontSize: 50 }}
      />
    </div>
  }

  function getIsPublicLayout() {
    const queryParams = getAllQueryParams()

    // for pdf print layout
    // TODO fix, potential security vulnerability
    if (queryParams.print) {
      return true
    }

    // for answering surveys
    const regex = /^\/survey\/.*$/
    if(regex.test(window.location.pathname)){
      return true
    }

    return false
  }

  return (
    <BrowserRouter>

      {
        layout === Layouts.companyUser ? (
          <SessionContext.Provider value={{ companyUser, company }}>
            <CompanyUserLayout />
          </SessionContext.Provider>
        ) : layout === Layouts.admin ? (
          <SessionContext.Provider value={{ companyUser, company }}>
            <AdminLayout />
          </SessionContext.Provider>
        ) : layout === Layouts.unauthenticated ?
          (
            <SessionContext.Provider value={{ company, layout }}>
              <UnauthenticatedLayout />
            </SessionContext.Provider>
          ) :
          layout === Layouts.survey ?
            (
              <SessionContext.Provider value={{ company }}>
                <PublicLayout />
              </SessionContext.Provider>

            ) : null
      }
    </BrowserRouter>
  );
}