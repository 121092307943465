import { SearchOutlined } from '@ant-design/icons'
import { Button, Divider, Form, Input, List, message, Select, Skeleton } from 'antd'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { SessionContext } from '../../../App'
import { default as SurveyCampaignStatusTag } from '../../../components/SurveyCampaignStatusTag'
import { GetSurveyCampaignByCompanyIdRequest } from '../../../constants/apiRequestResponse'
import TEST_IDS from '../../../constants/cypressTestIds'
import {
  SurveyCampaign,
  SurveyCampaignStatusLabels, SurveyTemplate
} from '../../../constants/types'
import { getSurveyCampaignsByCompanyId, getSurveyTemplates } from '../../../services/api'
import { ReportsPageKeys } from '../../../services/i8tn/ReportsPage/keys'
import { SurveyCampaignKeys } from '../../../services/i8tn/SurveyCampaignsPage/keys'
import { getQueryParam, setQueryParam } from '../../../util/index'
import styles from './SurveyCampaignPage.module.css'
import SurveyCampaignView from './SurveyCampaignView'


type QueryValues = {
  searchString: string
  surveyCampaignStatus: string,
  surveyCampaignId?: string,
  surveyTemplateName?: string,
}

const DEFAULT_LOAD_LIMIT = 10

export default function SurveyCampaignsPage() {
  const { t } = useTranslation()
  const context = useContext(SessionContext)
  const [isLoading, setIsLoading] = useState(false)
  const [surveyCampaigns, setSurveyCampaigns] = useState<SurveyCampaign[]>([])
  const [surveyTemplates, setSurveyTemplates] = useState<SurveyTemplate[]>([])
  const [queryValues, setQueryValues] = useState<QueryValues>({
    searchString: getQueryParam('searchString') || '',
    surveyCampaignStatus: getQueryParam('surveyCampaignStatus') || 'All',
    surveyCampaignId: getQueryParam('surveyCampaignId') || '',
    surveyTemplateName: getQueryParam('surveyTemplateName') || 'All',
  })
  const [isSearchable, setIsSearchable] = useState(false)

  const [infiniteScrollConfig, setInifiniteScrollConfig] = useState({
    limit: DEFAULT_LOAD_LIMIT,
    hasMore: true,
  })


  const getData = useCallback((req: GetSurveyCampaignByCompanyIdRequest) => {
    setIsLoading(true)
    getSurveyCampaignsByCompanyId(req)
      .then(res => {
        if (!res.success) throw new Error(res.message)

        /**
         * 14/2/2022 daniel.kwok
         * Append to list if it's not a first query.
         */
        if (req.next) {
          setSurveyCampaigns(prev => [...prev, ...res.surveyCampaigns])
        } else {
          setSurveyCampaigns(prev => [...res.surveyCampaigns])
        }
        /**
         * 14/2/2022 daniel.kwok
         * End of pagination result
         */
        if (res.surveyCampaigns.length < DEFAULT_LOAD_LIMIT) {
          setInifiniteScrollConfig(prev => ({
            ...prev,
            hasMore: false
          }))
        }
      })
      .catch(err => {
        message.error(err.message)
      })
      .finally(() => setIsLoading(false))

    if (surveyTemplates.length === 0) {
      getSurveyTemplates()
        .then(res => {
          if (!res.success) throw new Error(res.message)
          setSurveyTemplates(res.surveyTemplates)
        })
        .catch(err => {
          message.error(err.message)
        })
    }
  }, [surveyTemplates.length,])


  useEffect(() => {

    const req: GetSurveyCampaignByCompanyIdRequest = {
      companyId: context?.company?._id,
      limit: DEFAULT_LOAD_LIMIT,
      next: undefined,
      status: undefined
    }

    getData(req)
  }, [getData, context?.company?._id,])

  const onSearch = useCallback((queryValues: QueryValues) => {
    const req: GetSurveyCampaignByCompanyIdRequest = {
      companyId: context?.company?._id,
      limit: infiniteScrollConfig.limit,
      next: undefined,
      status: queryValues?.surveyCampaignStatus === 'All' ? undefined : queryValues?.surveyCampaignStatus,
    }

    getData(req)
    setIsSearchable(false)
  }, [getData, context?.company?._id, infiniteScrollConfig.limit])

  const dataSource = surveyCampaigns.map(sc => {
    return {
      ...sc,
      surveyTemplateName: surveyTemplates.find(st => st._id === sc.surveyTemplateId)?.name
    }
  })

  return (
    <div className='page-content'>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          gap: 20,
        }}
      >

        <div
          className={styles.card}
          style={{
            width: '30%',
            overflow: 'scroll'
          }}
        >
          <Form
            layout='vertical'
            style={{
              gap: 0
            }}
            onFinish={(values: QueryValues) => {
              onSearch(values)
            }}
            onFieldsChange={e => {
              setIsSearchable(true)
            }}
            initialValues={queryValues}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}

            >
              <Form.Item
                name='searchString'
                style={{ width: '80%' }}
              >
                <Input
                  addonBefore={(
                    <Select
                      dropdownMatchSelectWidth={false}
                      data-testid={`${TEST_IDS.SURVEY_CAMPAIGN_VIEW.survey_template_filter_id}`}
                    >
                      <Select.Option key={'All'} value={'All'}>
                        {t(SurveyCampaignKeys.surveyCampaigns_surveyAllDropdown)}
                      </Select.Option>
                      {
                        surveyTemplates.map(st => {
                          return <Select.Option key={st.name} value={st.name}>
                            {st.name}
                          </Select.Option>
                        })
                      }
                    </Select>
                  )}
                  placeholder={t(SurveyCampaignKeys.surveyCampaigns_searchPlaceholder)}
                  data-testid={`${TEST_IDS.SURVEY_CAMPAIGN_VIEW.search_bar_id}`}
                />
              </Form.Item>
              <Button
                loading={isLoading}
                style={{ width: '18%' }}
                htmlType='submit'
                type='primary'
                disabled={!isSearchable}
                data-testid={`${TEST_IDS.SURVEY_CAMPAIGN_VIEW.search_button_id}`}
              >
                <SearchOutlined />
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                gap: 10,
              }}
            >

              <Form.Item
                name='surveyTemplateName'
                label={<span style={{ color: 'grey', fontSize: 12 }}>{t(SurveyCampaignKeys.surveyCampaigns_surveyString)}</span>}
              >
                <Select
                  style={{ minWidth: 100, width: '100%' }}
                  data-testid={`${TEST_IDS.SURVEY_CAMPAIGN_VIEW.survey_template_filter_id}`}
                >
                  <Select.Option key={'All'} value={'All'}>
                    {t(SurveyCampaignKeys.surveyCampaigns_surveyAllDropdown)}
                  </Select.Option>
                  {
                    surveyTemplates.map(st => {
                      return <Select.Option key={st.name} value={st.name}>
                        {st.name}
                      </Select.Option>
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item
                name='surveyCampaignStatus'
                label={<span style={{ color: 'grey' }}> Status </span>}
              >
                <Select
                  style={{ minWidth: 100, width: '100%' }}
                  data-testid={`${TEST_IDS.SURVEY_CAMPAIGN_VIEW.status_filter_id}`}
                >
                  <Select.Option
                    data-testid={`${TEST_IDS.SURVEY_CAMPAIGN_VIEW.all_status_filter_id}`}
                    key={'All'}
                    value={'All'}>
                    {t(SurveyCampaignKeys.surveyCampaigns_statusAllDropdown)}
                  </Select.Option>
                  <Select.Option key={SurveyCampaignStatusLabels.draft} value={SurveyCampaignStatusLabels.draft}>
                    {SurveyCampaignStatusLabels.draft}
                  </Select.Option>
                  <Select.Option key={SurveyCampaignStatusLabels.ongoing} value={SurveyCampaignStatusLabels.ongoing}>
                    {SurveyCampaignStatusLabels.ongoing}
                  </Select.Option>
                  <Select.Option key={SurveyCampaignStatusLabels.completed} value={SurveyCampaignStatusLabels.completed}>
                    {SurveyCampaignStatusLabels.completed}
                  </Select.Option>
                  <Select.Option
                    data-testid={`${TEST_IDS.SURVEY_CAMPAIGN_VIEW.deleted_status_filter_id}`}
                    key={SurveyCampaignStatusLabels.deleted}
                    value={SurveyCampaignStatusLabels.deleted}>
                    {SurveyCampaignStatusLabels.deleted}
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>
          </Form>
          <div>
            <InfiniteScroll
              dataLength={dataSource.length}
              next={() => {
                const latestItem = dataSource[dataSource.length - 1]

                const req: GetSurveyCampaignByCompanyIdRequest = {
                  companyId: context?.company?._id,
                  limit: infiniteScrollConfig.limit,
                  next: latestItem._id
                }
                getData(req)
              }}
              hasMore={infiniteScrollConfig.hasMore}
              loader={<Skeleton paragraph={{ rows: 1 }} active />}
              scrollableTarget="scrollableDiv"
              height={window.innerHeight * 0.6}
              scrollThreshold={'100%'}
              endMessage={<Divider plain>{t(ReportsPageKeys.reportsPage_endString)}</Divider>}

            >
              <List
                dataSource={dataSource}
                renderItem={row => (
                  <List.Item
                    onClick={e => {
                      setQueryParam('surveyCampaignId', row._id)
                      setQueryValues({
                        ...queryValues,
                        surveyCampaignId: row._id
                      })
                    }}
                    key={row._id}>
                    <div >
                      <div
                        style={{
                          display: 'flex',
                          gap: 5,
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'flex-start',
                          width: '100%'
                        }}
                      >
                        <b>{row.name}</b>
                        <SurveyCampaignStatusTag status={row.status} />
                      </div>

                      <div
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                      >
                        <p style={{ flex: 2, color: 'grey' }}>
                          ID
                        </p>
                        <p style={{ flex: 4 }}>
                          {row._id}
                        </p>

                      </div>
                      <div
                        style={{ display: 'flex' }}
                      >
                        <p style={{ flex: 2, color: 'grey' }}>
                          Company
                        </p>
                        <p style={{ flex: 4 }}>
                          {row.companyId}
                        </p>

                      </div>

                    </div>
                  </List.Item>
                )}
              />

            </InfiniteScroll>
          </div>
        </div>

        <div
          className={styles.card}
          style={{
            width: '70%',
            overflow: 'scroll'
          }}
        >
          <SurveyCampaignView
            surveyCampaignId={queryValues.surveyCampaignId}
            onSurveyCampaignUpdated={(surveyCampaign) => {

              const newSurveyCampaigns = surveyCampaigns.map(sc => {
                if (sc._id === surveyCampaign._id) {
                  sc = surveyCampaign
                }
                return sc
              })

              setSurveyCampaigns(newSurveyCampaigns)
            }}
          />
        </div>

      </div>

    </div >
  )
}