export enum FeedbackGroupQuestionReportKeys {
    feedbackGroupQuestionReport_groupReportTitleString = 'feedbackGroupQuestionReport_groupReportTitleString',
    feedbackGroupQuestionReport_surveyCampaignString = 'feedbackGroupQuestionReport_surveyCampaignString',
    feedbackGroupQuestionReport_surveyString = 'feedbackGroupQuestionReport_surveyString',
    feedbackGroupQuestionReport_lastUpdatedString = 'feedbackGroupQuestionReport_lastUpdatedString',
    feedbackGroupQuestionReport_downloadAsPDFTooltip = 'feedbackGroupQuestionReport_downloadAsPDFTooltip',
    feedbackGroupQuestionReport_comparisonString = 'feedbackGroupQuestionReport_comparisonString',
    feedbackIndividualView_emailTableHeader = 'feedbackIndividualView_emailTableHeader',
    feedbackIndividualView_actionsTableHeader = ' feedbackIndividualView_actionsTableHeader',
    feedbackIndividualView_viewReportLink = ' feedbackIndividualView_viewReportLink',
    feedbackIndividualView_statusTableHeader = ' feedbackIndividualView_statusTableHeader',
    feedbackIndividualView_positionTableHeader = ' feedbackIndividualView_positionTableHeader',
    feedbackIndividualView_lastUpdatedTableHeader = ' feedbackIndividualView_lastUpdatedTableHeader',
    feedbackIndividualView_selectReportString = 'feedbackIndividualView_selectReportString',
    feedbackIndividualQuestionReport_individualReportTitleString = 'feedbackIndividualQuestionReport_individualReportTitleString',
    feedbackIndividualQuestionReport_genderString = 'feedbackIndividualQuestionReport_genderString',
    feedbackIndividualQuestionReport_emailString = 'feedbackIndividualQuestionReport_emailString',
    feedbackIndividualQuestionReport_responseIDString = 'feedbackIndividualQuestionReport_responseIDString',
    feedbackIndividualQuestionReport_surveyCampaignString = 'feedbackIndividualQuestionReport_surveyCampaignString',
    feedbackIndividualQuestionReport_surveyString = 'feedbackIndividualQuestionReport_surveyString',
    feedbackIndividualQuestionReport_lastUpdatedString = 'feedbackIndividualQuestionReport_lastUpdatedString',
    feedbackIndividualQuestionReport_downloadAsPDFTooltip = 'feedbackIndividualQuestionReport_downloadAsPDFTooltip',
    feedbackIndividualQuestionReport_comparisonString = 'feedbackIndividualQuestionReport_comparisonString',
}