import { Tag } from 'antd'
import React from 'react'
import { SurveyResponseStatusLabels } from '../constants/types'

interface Prop {
  status: SurveyResponseStatusLabels
}

export default function SurveyResponseStatusTag(prop: Prop) {
  switch (prop.status) {
    case SurveyResponseStatusLabels.PENDING:
      return <Tag color={'yellow'}>
        {SurveyResponseStatusLabels.PENDING}
      </Tag>
    case SurveyResponseStatusLabels.EMAIL_FAILED:
      return <Tag color={'red'}>
        {SurveyResponseStatusLabels.EMAIL_FAILED}
      </Tag>
    case SurveyResponseStatusLabels.EMAIL_SENT:
      return <Tag color={'blue'}>
        {SurveyResponseStatusLabels.EMAIL_SENT}
      </Tag>
    case SurveyResponseStatusLabels.DRAFT:
      return <Tag color={'orange'}>
        {SurveyResponseStatusLabels.DRAFT}
      </Tag>
    case SurveyResponseStatusLabels.COMPLETED:
      return <Tag color={'green'}>
        {SurveyResponseStatusLabels.COMPLETED}
      </Tag>
    default:
      return <Tag>
        {prop.status}
      </Tag>
  }
}
