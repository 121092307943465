import { NormalIndividualQuestionReportKeys } from "./keys"

export const englishValues = {
    [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_individualReportTitleString]: 'Individual Report',
    [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_genderString]: 'Gender',
    [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_emailString]: 'Email',
    [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_surveyIDString]: 'Survey ID',
    [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_surveyString]: 'Survey',
    [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_lastUpdatedString]: 'Last updated',
    [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_downloadAsPDFTooltip]: 'Download as PDF',
    [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_participantString]: 'Participant',
    [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_comparisonString]: 'Comparison',
}

export const malayValues = {
    [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_individualReportTitleString]: 'Laporan Individu',
    [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_genderString]: 'Jantina',
    [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_emailString]: 'E-mel',
    [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_surveyIDString]: 'ID Tinjauan',
    [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_surveyString]: 'Tinjauan',
    [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_lastUpdatedString]: 'Dikemas kini pada',
    [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_downloadAsPDFTooltip]: 'Muat turun sebagai PDF',
    [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_participantString]: 'Peserta',
    [NormalIndividualQuestionReportKeys.normalIndividualQuestionReport_comparisonString]: 'Perbandingan',
}