import { Positions } from "./types"

/**
 * daniel.kwok 2 may 2022
 * 
 * A really sucky workaround to getting colors via variables, without having to mess with less, scss etc
 * 
 */
export enum COLORS {
    BACKGROUND_COLOR = `#c2d9e1`,
    BACKGROUND_COLOR_DARK = `#467e8e`,
    PRIMARY_COLOR = `#c01172`,
    BACKGROUND_COLOR_DARk_GREY = 'rgb(47, 47, 47)'
}

export const POSITION_COLORS = {
    [Positions.Participant]: 'gold',
    [Positions.LineManager]: 'red',
    [Positions.Team]: 'blue',
    [Positions.Reports]: 'green',
}

export const PLACEHOLDER_IMAGE = 'https://image.shutterstock.com/image-vector/ui-image-placeholder-wireframes-apps-260nw-1037719204.jpg'


export enum ReportLayouts {
    normalIndividualReport = 'individual',
    feedbackIndividualReport = '360individual',
    normalGroupReport = 'group',
    feedbackGroupReport = '360group',
    feedbackTeamReport = '360team',
}
