import { LoadingOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons'
import { Button, Card, Descriptions, Drawer, Form, Input, Select, Table, Tooltip, Typography, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { ChangePasswordRequest, GetAllCompanyUserRequest, GetCompaniesRequest, SignUpRequest, UpdateCompanyUserRequest } from '../../../constants/apiRequestResponse'
import {
    Company, CompanyUser, Gender, Roles
} from '../../../constants/types'
import { changeCompanyUserPassword, getAllCompanies, getAllCompanyUsers, signup, updateCompanyUser } from '../../../services/api'
import { getQueryParam, parseTime } from '../../../util'

export default function CompanyUserTab() {
    enum DrawerModes {
        closed,
        add,
        edit
    }
    const [form] = Form.useForm()

    const [isLoading, setIsLoading] = useState(false)
    const [isAddLoading, setIsAddLoading] = useState(false)
    const [isEditLoading, setIsEditLoading] = useState(false)
    const [isGetCompaniesLoading, setIsGetCompaniesLoading] = useState(false)
    const [companyUsers, setCompanyUsers] = useState<CompanyUser[] | undefined>()
    const [companies, setCompanies] = useState<Company[] | undefined>()
    const [drawerMode, setDrawerMode] = useState<DrawerModes>(DrawerModes.closed)
    const [isExpandedByCompanyUserId, setIsExpandedByCompanyUserId] = useState<{
        [key: string]: boolean
    }>()

    const isDrawerOpen = drawerMode !== DrawerModes.closed

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Company',
            dataIndex: 'companyName',
            render: (companyName: string, companyUser: CompanyUser) => {
                return <a
                    href={`/?key=Company&companyId=${companyUser.companyId}`}
                >
                    {companyName}
                </a>
            },
            sorter: (a, b) => {
                return a.companyName.localeCompare(b.companyName)
            },
        },
        {
            title: 'Role',
            dataIndex: 'role',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            width: 250,
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: time => parseTime(time)
        },
        {
            title: 'Updated at',
            dataIndex: 'updatedAt',
            render: time => parseTime(time)
        },
        {
            title: 'Action',
            fixed: 'right' as 'right',
            width: 150,
            render: (companyUser: CompanyUser) => {
                return (
                    <div>
                        <Button
                            type='link'
                            onClick={() => {
                                setDrawerMode(DrawerModes.edit)

                                form.setFieldsValue(companyUser)
                            }}
                        >
                            Edit
                        </Button>

                    </div>
                )
            }
        },
    ]

    useEffect(() => {
        getData()

        /**
         * 8/8/2022 daniel.kwok
         * If a companyUser is selected in url, 
         * 
         * expand & scroll to view
         * 
         * Somehow the setTimeout is still needed else it wont work ¯\_(ツ)_/¯
         * Timeout is to wait for api to complete. Not the best, I know.
         */
        setTimeout(() => {
            const companyUserId = getQueryParam('companyUserId') || ''
            if (companyUserId) {
                setIsExpandedByCompanyUserId({
                    ...isExpandedByCompanyUserId,
                    [companyUserId]: true
                })


                const element = document.querySelectorAll(`[data-row-key="${companyUserId}"]`)[0]
                element?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            }
        }, 0.5 * 1000)
    }, [isExpandedByCompanyUserId])

    const getData = () => {
        setIsLoading(true)
        const req1: GetAllCompanyUserRequest = {

        }
        getAllCompanyUsers(req1)
            .then(res => {
                if (!res.success) throw new Error(res.message)
                setCompanyUsers(res.companyUsers)
            })
            .catch(err => {
                message.error(err.toString())
            })
            .finally(() => {
                setIsLoading(false)
            })

        setIsGetCompaniesLoading(true)
        const req2: GetCompaniesRequest = {

        }
        getAllCompanies(req2)
            .then(res => {
                if (!res.success) throw new Error(res.message)
                setCompanies(res.companies)
            })
            .catch(err => {
                message.error(err.toString())
            })
            .finally(() => {
                setIsGetCompaniesLoading(false)
            })
    }


    return (
        <div
            style={{
                width: '100%'
            }}
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: 10
                }}
            >
                <Button
                    type='primary'
                    onClick={() => {
                        setDrawerMode(DrawerModes.add)
                    }}
                    icon={<PlusOutlined />}
                >
                    Create
                </Button>
            </div>

            <Table
                tableLayout={'auto'}
                loading={isLoading}
                rowKey={'_id'}
                columns={columns}
                scroll={{
                    x: columns.length * 150,
                }}
                dataSource={companyUsers?.map(cu => {
                    const company = companies?.find(c => c._id === cu.companyId)
                    return {
                        ...cu,
                        companyName: company?.name,
                    }
                })}
                expandable={{
                    expandedRowKeys: isExpandedByCompanyUserId ? Object.keys(isExpandedByCompanyUserId).filter(key => isExpandedByCompanyUserId[key]) : [],
                    onExpand: (expanded, companyUser: CompanyUser) => {
                        setIsExpandedByCompanyUserId({
                            ...isExpandedByCompanyUserId,
                            [companyUser._id]: expanded
                        })
                    },
                    expandedRowRender: (companyUser: CompanyUser) => {

                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    width: window.innerWidth * 0.9,
                                    gap: 10
                                }}
                            >
                                <Card style={{ flex: 4 }}>
                                    <Typography.Title level={5}>
                                        User Info
                                    </Typography.Title>
                                    <Descriptions column={1} layout='vertical' labelStyle={{ fontWeight: 100 }} size={'small'}>
                                        <Descriptions.Item label='Mobile'>{companyUser.mobile}</Descriptions.Item>
                                        <Descriptions.Item label='Gender'>{companyUser.gender}</Descriptions.Item>

                                    </Descriptions>
                                </Card>
                                <Card style={{ flex: 2 }}>
                                    <Typography.Title level={5}>
                                        Reset password
                                    </Typography.Title>

                                    <Form
                                        form={form}
                                        layout='vertical'
                                        onFinish={async (v: {
                                            oldPassword: string,
                                            newPassword: string,
                                            confirmNewPassword: string,
                                        }) => {
                                            try {
                                                setIsLoading(true)
                                                if (v.newPassword !== v.confirmNewPassword) throw new Error(`New passwords don't match.`)

                                                const req: ChangePasswordRequest = {
                                                    companyUserId: companyUser?._id,
                                                    oldPassword: v.oldPassword,
                                                    newPassword: v.newPassword
                                                }

                                                const res = await changeCompanyUserPassword(req)
                                                if (!res.success) throw new Error(res.message)

                                                message.success(`Password reset successful`)
                                                form.resetFields()

                                                setIsLoading(false)
                                            } catch (err) {
                                                message.error(typeof err === 'string' ? err : typeof err === 'object' ? err?.toString() : `Something went wrong`)
                                                setIsLoading(false)
                                            }
                                        }}
                                    >
                                        <Form.Item label='Old password' name='oldPassword' rules={[{ required: true, }]}>
                                            <Input.Password />
                                        </Form.Item>
                                        <Form.Item label='New password' name='newPassword' rules={[{ required: true, }]}>
                                            <Input.Password />
                                        </Form.Item>
                                        <Form.Item label='Confirm new password' name='confirmNewPassword' rules={[{ required: true, }]}>
                                            <Input.Password />
                                        </Form.Item>
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                gap: 5
                                            }}
                                        >
                                            <Button
                                                htmlType='submit'
                                                type='primary'
                                                loading={isLoading}
                                            >
                                                Reset
                                            </Button>
                                        </div>
                                    </Form>
                                </Card>
                            </div>
                        )
                    },

                }}
            />

            <Drawer
                title={drawerMode === DrawerModes.add ? "Add a new user" : `Edit user`}
                placement="right"
                visible={isDrawerOpen}
                onClose={() => {
                    setDrawerMode(DrawerModes.closed)
                    form.resetFields()
                }}
                destroyOnClose={true}
            >
                <Form
                    form={form}
                    layout='vertical'
                    onFinishFailed={v => {
                        console.log(v)
                    }}
                    onFinish={async (v) => {
                        console.log('onFinish')
                        if (drawerMode === DrawerModes.edit) {
                            setIsEditLoading(true)
                            const req1: UpdateCompanyUserRequest = {
                                companyUser: v
                            }
                            updateCompanyUser(req1)

                                .then(res2 => {
                                    if (!res2.success) throw res2.message

                                    message.success(`Updated successfully`)

                                    setDrawerMode(DrawerModes.closed)
                                    getData()
                                })

                                .catch(err => {
                                    message.error(err.toString())
                                })
                                .finally(() => {
                                    setIsEditLoading(false)
                                })
                        } else {

                            if (v.password !== v.passwordConfirm) {
                                message.error(`Password and Confirm passwords do not match`)
                                return
                            }

                            setIsAddLoading(true)

                            const req1: SignUpRequest = {
                                companyUser: {
                                    name: v.name,
                                    companyId: v.companyId,
                                    gender: v.gender,
                                    email: v.email,
                                    mobile: v.mobile,
                                    password: v.password,
                                    role: v.role,
                                }
                            }
                            signup(req1)

                                .then(res2 => {
                                    if (!res2.success) throw res2.message

                                    message.success(`Created successfully`)

                                    setDrawerMode(DrawerModes.closed)
                                    getData()
                                })

                                .catch(err => {
                                    message.error(err.toString())
                                })
                                .finally(() => {
                                    setIsAddLoading(false)
                                })
                        }
                    }}
                >
                    <Form.Item label='ID' name='_id' hidden={drawerMode === DrawerModes.add}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label={
                            <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                                <span>Company</span>
                                {
                                    isGetCompaniesLoading ? (
                                        <LoadingOutlined />
                                    ) : (
                                        <Tooltip title='Refresh list'>
                                            <ReloadOutlined
                                                onClick={() => {
                                                    setIsGetCompaniesLoading(true)
                                                    const req: GetCompaniesRequest = {

                                                    }
                                                    getAllCompanies(req)
                                                        .then(res => {
                                                            if (!res.success) throw new Error(res.message)
                                                            setCompanies(res.companies)
                                                        })
                                                        .catch(err => message.error(err?.toString()))
                                                        .finally(() => setIsGetCompaniesLoading(false))
                                                }}
                                            />
                                        </Tooltip>
                                    )
                                }
                            </div>
                        }
                        name='companyId'
                        rules={[{ required: true, message: 'Company is required' }]}
                    >
                        <Select
                            showSearch
                        >
                            {
                                companies?.map(company => {
                                    return <Select.Option key={company._id} value={company._id}>
                                        {company.name}
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label='Name'
                        name='name'
                        rules={[{ required: true, message: 'Name is required' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label='Email'
                        name='email'
                        rules={[{ required: true, message: 'Email is required' }]}
                    >
                        <Input />
                    </Form.Item>
                    {
                        drawerMode === DrawerModes.add ? (
                            <Form.Item
                                label='Password'
                                name='password'
                                rules={[{ required: true, message: 'Password is required' }]}
                            >
                                <Input.Password />
                            </Form.Item>
                        ) : null
                    }
                    {
                        drawerMode === DrawerModes.add ? (
                            <Form.Item
                                label='Confirm password'
                                name='passwordConfirm'
                                rules={[{ required: true, message: 'Confirm password is required' }]}
                            >
                                <Input.Password />
                            </Form.Item>
                        ) : null
                    }
                    <Form.Item
                        label='Role'
                        name='role'
                        rules={[{ required: true, message: 'Role is required' }]}
                    >
                        <Select
                            showSearch
                        >
                            <Select.Option key={Roles.COMPANY_USER} value={Roles.COMPANY_USER}>
                                {Roles.COMPANY_USER}
                            </Select.Option>
                            <Select.Option key={Roles.ADMIN} value={Roles.ADMIN}>
                                {Roles.ADMIN}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label='Mobile' name='mobile'>
                        <Input />
                    </Form.Item>
                    <Form.Item label='Gender' name='gender'
                        rules={[
                            {
                                required: false,
                                type: 'enum',
                                enum: [Gender.Male, Gender.Female]
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder={Gender.Male}
                        >
                            {
                                [Gender.Male, Gender.Female].map(size => {
                                    return <Select.Option key={size} value={size}>
                                        {size}
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >

                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                loading={isEditLoading || isAddLoading}
                            >
                                {
                                    drawerMode === DrawerModes.edit ? 'Save' : 'Add'

                                }
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </Drawer >

        </div >
    )
}