export enum SurveyResponsePageKeys {
    surveyResponsePage_startBtn = 'surveyResponsePage_startBtn',
    surveyResponsePage_saveAsDraftBtn = 'surveyResponsePage_saveAsDraftBtn',
    surveyResponsePage_saveProgressPrompt = 'surveyResponsePage_saveProgressPrompt',
    surveyResponsePage_accessibleFromEmailPrompt = 'surveyResponsePage_accessibleFromEmailPrompt',
    surveyResponsePage_submitBtn = 'surveyResponsePage_submitBtn',
    surveyResponsePage_surveyIncompletePrompt = 'surveyResponsePage_surveyIncompletePrompt',
    surveyResponsePage_submitSurveyPrompt = 'surveyResponsePage_submitSurveyPrompt',
    surveyResponsePage_confirmString = 'surveyResponsePage_confirmString',
    surveyResponsePage_savedAsDraftString = 'surveyResponsePage_savedAsDraftString',
    surveyResponsePage_comeBackAnytimeString = 'surveyResponsePage_comeBackAnytimeString',
    surveyResponsePage_continueBtn = 'surveyResponsePage_continueBtn',
    surveyResponsePage_surveyCompletedString = 'surveyResponsePage_surveyCompletedString',
    surveyResponsePage_thankYouString = 'surveyResponsePage_thankYouString',
    surveyResponsePage_surveyCancelledTitleString = 'surveyResponsePage_surveyCancelledTitleString',
    surveyResponsePage_surveyCancelledSubtitleString = 'surveyResponsePage_surveyCancelledSubtitleString',
    surveyResponsePage_surveyCampaignDraftTitleString = 'surveyResponsePage_surveyCampaignDraftTitleString',
    surveyResponsePage_surveyCampaignDraftSubtitleString = 'surveyResponsePage_surveyCampaignDraftSubtitleString',
    surveyResponsePage_surveyCampaignDeletedTitleString = 'surveyResponsePage_surveyCampaignDeletedString',
    surveyResponsePage_surveyCampaignDeletedSubtitleString = 'surveyResponsePage_surveyCampaignDeletedSubtitleString',
    surveyResponsePage_unknownStatusString = 'surveyResponsePage_unknownStatusString',
    surveyResponsePage_reachOutToHRString = 'surveyResponsePage_reachOutToHRString',
    surveyResponsePage_yetToStartString = 'surveyResponsePage_yetToStartString',
    surveyResponsePage_noSubmissionAcceptedString = 'surveyResponsePage_noSubmissionAcceptedString',
    surveyResponsePage_expiredString = 'surveyResponsePage_expiredString',
    surveyResponsePage_noMoreSubmissionString = 'surveyResponsePage_noMoreSubmissionString',
    surveyResponsePage_somethingsWrongWithSurveyString = 'surveyResponsePage_somethingsWrongWithSurveyString',
    surveyResponsePage_surveyAlreadySubmittedTitleString = 'surveyResponsePage_surveyAlreadySubmittedTitleString',
    surveyResponsePage_surveyAlreadySubmittedSubtitleString = 'surveyResponsePage_surveyAlreadySubmittedSubtitleString',
    surveyResponsePage_cannotResubmitString = 'surveyResponsePage_cannotResubmitString',
    surveyResponsePage_somethingsWrongWithCampaignString = 'surveyResponsePage_somethingsWrongWithCampaignString',

}