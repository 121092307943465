export enum EditEmailTemplatePageKeys {
    editEmailTemplate_editEmailTemplateTitle = 'editEmailTemplate_editEmailTemplateTitle',
    editEmailTemplate_nameString = 'editEmailTempalte_nameString',
    editEmailTemplate_linkedSurveyTemplateString = 'editEmailTemplate_linkedSurveyTemplateString',
    editEmailTemplate_subjectString = 'editEmailTemplate_subjectString',
    editEmailTemplate_contentString = 'editEmailTemplate_contentString',
    editEmailTemplate_variablesString = 'editEmailTemplate_variablesString',
    editEmailTemplate_introDynamicValuesString = 'editEmailTemplate_introDynamicValuesString',
    editEmailTemplate_variableNameTableHeader = 'editEmailTemplate_variableNameTableHeader',
    editEmailTemplate_descriptionTableHeader = 'editEmailTemplate_descriptionTableHeader',
    editEmailTemplate_cancelBtn = 'editEmailTemplate_cancelBtn',
    editEmailTemplate_saveBtn = 'editEmailTemplate_saveBtn',
    editEmailTemplate_savePrompt = 'editEmailTemplate_savePrompt',
}