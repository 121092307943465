import { SurveyCampaign360FormKeys } from "./keys"

export const englishValues = {
    [SurveyCampaign360FormKeys.surveyCampaign360Form_runASurveyString]: 'Run a survey',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_basicInfoString]: 'Basic info',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_campaignNameString]: 'Campaign name',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_nextBtn]: 'Next',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesString]: 'Upload employees',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesTitleString]: 'Upload employees',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesDownloadString]: 'Download a ',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesSampleExcelLink]: 'sample excel template',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesExampleString]: 'to see an example of the format required.',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesSupportedFormatsString]: 'Supported formats: xlxs, csv',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesParticipantsString]: 'participants added',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddNewParticipantString]: 'Add a new participant',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesClickToEditTooltip]: 'Click to edit',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesClearAllBtn]: 'Clear all',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesConfirmClearAllPrompt]: 'Confirm clear all?',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesClearGroupTooltip]: 'Clear group',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesConfirmClearGroupPrompt]: ' Confirm clear group?',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesNextBtn]: 'Next',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_addingAString]: 'Adding a',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_toString]: 'to',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormPositionString]: 'Position',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormFirstNameString]: 'First Name',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormLastNameString]: 'Last Name',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormEmailString]: 'Email',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormGenderString]: 'Gender',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormTeamString]: 'Team',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormSaveBtn]: 'Save',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormPositionString]: 'Position',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormFirstNameString]: 'First Name',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormLastNameString]: 'Last Name',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormEmailString]: 'Email',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormGenderString]: 'Gender',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormTeamString]: 'Team',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormSaveBtn]: 'Save',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormDeleteBtn]: 'Delete',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormConfirmDeletePrompt]: 'Confirm delete?',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewAndSendString]: 'Preview and send',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewEmailsTitleString]: 'Preview emails',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewSampleEmailString]: 'Sample email: ',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewFeedbackString]: 'providing feedback to ',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewAsString]: 'as ',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewMoreActionsDropdown]: 'More actions',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewRefreshDropdown]: 'Refresh',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewEditTemplateDropdown]: ' Edit template',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_faqString]: 'FAQ',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_whatIsLinkString]: 'What is [$link]?',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_uniqueLinkString]: 'This is the unique link for each survey. Will only be generated once "Send" is clicked.',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewBackBtn]: 'Back',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewDraftBtn]: 'Save as draft',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewSendBtn]: 'Send',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewSendAreYouSurePrompt]: 'Are you sure?',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_sendingSurveyPrompt]: 'Sending survey',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_surveyCampaignStartedPrompt]: 'Survey campaign started!',
}

export const malayValues = {

    [SurveyCampaign360FormKeys.surveyCampaign360Form_runASurveyString]: 'Jalankan tinjauan',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_basicInfoString]: 'Info asas',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_campaignNameString]: 'Nama kempen',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_nextBtn]: 'Seterusnya',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesString]: 'Memuat naik pekerja',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesTitleString]: 'Memuat naik pekerja',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesDownloadString]: 'Muat turun ',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesSampleExcelLink]: 'templat sampel excel ',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesExampleString]: ' untuk lihat contoh format yang ditetapkan.',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesSupportedFormatsString]: 'Format yang disokong: xlxs, csv',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_uploadEmployeesParticipantsString]: 'peserta ditambah',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddNewParticipantString]: 'Tambah peserta yang baharu',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesClickToEditTooltip]: 'Tekan untuk edit',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesClearAllBtn]: 'Padam semua',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesConfirmClearAllPrompt]: 'Sahkan padam semua?',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesClearGroupTooltip]: 'Padam kumpulan',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesConfirmClearGroupPrompt]: ' Sahkan padam kumpulan?',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesNextBtn]: 'Seterusnya',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_addingAString]: 'Tambah',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_toString]: 'kepada',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormPositionString]: 'Jawatan',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormFirstNameString]: 'Nama pertama',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormLastNameString]: 'Nama keluarga',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormEmailString]: 'E-mel',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormGenderString]: 'Jantina',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormTeamString]: 'Pasukan',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesAddFormSaveBtn]: 'Simpan',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormPositionString]: 'Jawatan',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormFirstNameString]: 'Nama pertama',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormLastNameString]: 'Nama keluarga',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormEmailString]: 'E-mel',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormGenderString]: 'Jantina',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormTeamString]: 'Pasukan',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormSaveBtn]: 'Simpan',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormDeleteBtn]: 'Padam',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_employeesEditFormConfirmDeletePrompt]: 'Sahkan padam?',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewAndSendString]: 'Pratonton dan hantar',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewEmailsTitleString]: 'Pratonton e-mel',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewSampleEmailString]: 'Sampel e-mel:',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewFeedbackString]: 'memberikan maklum balas kepada',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewAsString]: 'sebagai',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewMoreActionsDropdown]: 'Tindakan lanjut',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewRefreshDropdown]: 'Muat semula',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewEditTemplateDropdown]: ' Edit templat',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_faqString]: 'Soalan lazim',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_whatIsLinkString]: 'Apakah itu [$link]?',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_uniqueLinkString]: 'Ini ialah pautan yang unik untuk setiap tinjauan. Pautan akan dijanakan selepas tekan "Hantar".',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewBackBtn]: 'Kembali',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewDraftBtn]: 'Simpan sebagai draf',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewSendBtn]: 'Hantar',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_previewSendAreYouSurePrompt]: 'Adakah anda pasti?',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_sendingSurveyPrompt]: 'Tinjauan sedang dihantar',
    [SurveyCampaign360FormKeys.surveyCampaign360Form_surveyCampaignStartedPrompt]: 'Kempen tinjauan telah dimulakan!',

}