export enum EditProfilePageKeys {
    editProfilePage_editProfileTitle = 'editProfilePage_editProfileTitle',
    editProfilePage_userTab = 'editProfilePage_userTabString',
    editProfilePage_userProfileTabTitle = 'editProfilePage_userProfileTabTitle',
    editProfilePage_userProfileNameString = 'editProfilePage_userProfileNameString',
    editProfilePage_userProfileEmailString = 'editProfilePage_userProfileEmailString',
    editProfilePage_userProfileGenderString = 'editProfilePage_userProfileGenderString',
    editProfilePage_userProfileMobileString = 'editProfilePage_userProfileMobileString',
    editProfilePage_userProfileSaveBtn = 'ditProfilePage_userProfileSaveBtn',
    editProfilePage_companyTab = 'editProfilePage_companyTabString',
    editProfilePage_companyTabTitle = 'editProfilePage_companyTabTitle',
    editProfilePage_companyChangeLogoString = 'editProfilePage_companyChangeLogoString',
    editProfilePage_companyUpdateLogoPrompt = 'editProfilePage_companyUpdateLogoPrompt',
    editProfilePage_companyEmailDomainString = 'editProfilePage_companyEmailDomainString',
    editProfilePage_companyEmailDomainToolTip = 'editProfilePage_companyEmailDomainToolTip',
    editProfilePage_companyNameString = 'editProfilePage_companyNameString',
    editProfilePage_companyNamePlaceholder = 'editProfilePage_companyNamePlaceholder',
    editProfilePage_companyIndustryString = 'editProfilePage_companyIndustryString',
    editProfilePage_companyIndustryPlaceholder = 'editProfilePage_companyIndustryPlaceholder',
    editProfilePage_companyLocationString = 'editProfilePage_companyLocationString',
    editProfilePage_companyLocationPlaceholder = 'editProfilePage_companyLocationPlaceholder',
    editProfilePage_companySizeString = 'editProfilePage_companySizeString',
    editProfilePage_companySaveBtn = 'editProfilePage_companySaveBtn',
    editProfilePage_companySaveCompanyMissingErrorMessage = 'editProfilePage_companySaveCompanyMissingErrorMessage',
    editProfilePage_companySaveCompanySuccessMessage = 'editProfilePage_companySaveCompanySuccessMessage',
    editProfilePage_resetPasswordTab = 'editProfilePage_resetPasswordTabString',
    editProfilePage_resetPasswordTabTitle = 'editProfilePage_resetPasswordTabTitle',
    editProfilePage_resetPasswordOldString = 'editProfilePage_resetPasswordOldString',
    editProfilePage_resetPasswordNewString = 'editProfilePage_resetPasswordNewString',
    editProfilePage_resetPasswordConfirmString = 'editProfilePage_resetPasswordConfirmString',
    editProfilePage_resetPasswordResetBtn = 'editProfilePage_resetPasswordResetBtn',
}