import { FilePdfFilled, LoadingOutlined } from '@ant-design/icons'
import { Empty, message, Skeleton, Tooltip, Typography } from 'antd'
import { t } from 'i18next'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'

import config from '../../config'
import { GetSurveyCampaign360GroupReportRequest, PrintRequest } from '../../constants/apiRequestResponse'
import { ReportLayouts } from '../../constants/misc'
import { GroupResponse360Statistics, Positions, SurveyCampaign, SurveyTemplate } from '../../constants/types'
import { getSurveyCampaign360GroupReport, printAsPDF } from '../../services/api'
import { getQueryParam, parseDate, parseTime } from '../../util/index'
import QuestionProgress from '../QuestionProgress/index'
import styles from './index.module.css'
import { FeedbackGroupQuestionReportKeys } from '../../services/i8tn/FeedbackGroupQuestionReport/keys'

type Prop = {
    surveyCampaignId?: string,
}

export default function FeedbackGroupQuestionReport(props: Prop) {

    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [isPrintAsPDFLoading, setIsPrintAsPDFLoading] = useState(false)
    const [surveyTemplate, setSurveyTemplate] = useState<SurveyTemplate | undefined>()
    const [responseStatistics, setResponseStatistics] = useState<GroupResponse360Statistics[] | undefined>()
    const [surveyCampaign, setSurveyCampaign] = useState<SurveyCampaign | undefined>()

    const surveyCampaignId = props.surveyCampaignId || getQueryParam('surveyCampaignId') || undefined
    const mode = ReportLayouts.feedbackGroupReport
    const isPdf = getQueryParam('print') ? true : false

    const getData = useCallback(async () => {
        try {
            setIsLoading(true)
            const req: GetSurveyCampaign360GroupReportRequest = {
                surveyCampaignId: surveyCampaignId,

            }
            getSurveyCampaign360GroupReport(req)
                .then(res => {
                    if (!res.success) throw new Error(res.message)
                    setResponseStatistics(res.responseStatistics)
                    setSurveyCampaign(res.surveyCampaign)
                    setSurveyTemplate(res.surveyTemplate)

                })
                .catch(err => {
                    message.error(err)
                    setIsError(true)
                })
                .finally(() => setIsLoading(false))

        } catch (err) {
            message.error(typeof err === 'string' ? err : typeof err === 'object' ? err?.toString() : `Something went wrong`)
            setIsError(true)
        }
        setIsLoading(false)
    }, [surveyCampaignId])

    useEffect(() => {
        getData()
    }, [surveyCampaignId, getData])

    const onExportPDF = () => {
        setIsPrintAsPDFLoading(true)

        let url = new URL(`${window.location.origin}/print`)
        url.searchParams.append('print', 'true')
        url.searchParams.append('layout', mode)
        url.searchParams.append('surveyCampaignId', surveyCampaignId?.toString() || '')

        let urlString = url.toString()

        /**
         * 14/8/2022 daniel.kwok
         * Render pdf will not work locally (puppeteer thing)
         * So, if it's local, just render the UI
         * Else, call the API to produce PDF
         */
        if (config.env === 'dev') {
            window.location.href = urlString
        } else {

            urlString = encodeURIComponent(urlString)
            const req: PrintRequest = {
                url: urlString
            }
            printAsPDF(req)
                .then(() => {
                    setIsPrintAsPDFLoading(false)
                })
                .catch(err => {
                    message.error(err?.toString())
                })
        }
    }

    if (isLoading) {
        return (
            <div className='page-content' style={{ backgroundColor: 'white' }}>
                <Skeleton active />
            </div>
        )
    }

    if (isError) {
        return (
            <div className='page-content' style={{ backgroundColor: 'white' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    <Typography.Title level={5}>
                        Something went wrong
                    </Typography.Title>
                </div>
            </div>
        )
    }

    return (
        <div className='page-content' style={{ backgroundColor: 'white' }}>
            <div
                style={{
                    margin: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >

                <div
                    style={{ marginBottom: 50 }}
                >
                    <div

                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            width: '100%',
                        }}
                    >
                        {
                            !isPdf ? (
                                <div>
                                    <Typography.Title level={2}>
                                        <>{t(FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_groupReportTitleString)}</> {parseDate(moment.now())}
                                    </Typography.Title>
                                    <p style={{ color: 'grey' }}><>{t(FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_surveyCampaignString)}</></p>
                                    <p style={{ fontWeight: 1000 }} >{surveyCampaign?.name}</p>
                                    <p style={{ color: 'grey' }}><>{t(FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_surveyString)}</></p>
                                    <p style={{ fontWeight: 1000 }} >{surveyTemplate?.name}</p>
                                    <p style={{ color: 'grey' }}><>{t(FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_lastUpdatedString)}</></p>
                                    <p style={{ fontWeight: 1000 }} >{parseTime(surveyCampaign?.updatedAt)}</p>
                                </div>
                            ) : null
                        }
                        {

                            !isPdf ? (
                                isPrintAsPDFLoading ? (
                                    <LoadingOutlined
                                        style={{ fontSize: 30, }} />
                                ) : (
                                    <Tooltip title={t(FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_downloadAsPDFTooltip)}>
                                        <FilePdfFilled
                                            onClick={e => {
                                                onExportPDF()
                                            }}
                                            style={{ fontSize: 30, }} />
                                    </Tooltip>
                                )
                            ) : null
                        }
                    </div>
                </div>
                <div
                    className={styles.options_container}
                >
                    {
                        (responseStatistics)?.map((rs, i) => {

                            const participantAnswer = rs.byPosition[Positions.Participant]?.average
                            const participantAnswerPercentage = rs.byPosition[Positions.Participant]?.averagePercentage

                            const reportAnswer = rs.byPosition[Positions.Reports]?.average
                            const reportAnswerPercentage = rs.byPosition[Positions.Reports]?.averagePercentage

                            const lineManagerAnswer = rs.byPosition[Positions.LineManager]?.olympicScoring
                            const lineManagerAnswerPercentage = rs.byPosition[Positions.LineManager]?.olympicScoringPercentage

                            const teamAnswer = rs.byPosition[Positions.Team]?.average
                            const teamAnswerPercentage = rs.byPosition[Positions.Team]?.averagePercentage

                            return (
                                <div
                                    key={rs?.surveyQuestionId}
                                    style={{
                                        marginBottom: 50,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        breakInside: 'avoid',
                                        pageBreakInside: 'avoid',
                                    }}
                                >
                                    <div
                                        style={{
                                            marginBottom: 20
                                        }}
                                    >
                                        <h1>
                                            [{i + 1}] {rs?.statement}
                                        </h1>
                                    </div>

                                    <div
                                        style={{ display: 'flex', flexDirection: 'column', gap: 6 }}
                                    >
                                        <p style={{ color: 'grey' }} >
                                            {Positions.Participant}
                                        </p>
                                        {

                                            typeof participantAnswer === 'number' ? (
                                                <QuestionProgress
                                                    percent={participantAnswerPercentage}
                                                    type={Positions.Participant}
                                                    format={(percent: number) => {
                                                        return <h4 style={{ color: 'white', fontWeight: 'bold' }}>{typeof participantAnswer === 'number' ? participantAnswer?.toFixed(1) : reportAnswer}</h4>
                                                    }}
                                                />
                                            ) : (
                                                <p>
                                                    {participantAnswer}
                                                </p>
                                            )
                                        }
                                    </div>
                                    <div
                                        style={{ display: 'flex', flexDirection: 'column', gap: 6 }}
                                    >
                                        <p style={{ color: 'grey' }} >
                                            {Positions.Reports}
                                        </p>
                                        {

                                            typeof reportAnswer === 'number' ? (
                                                <QuestionProgress
                                                    type={Positions.Reports}
                                                    percent={reportAnswerPercentage}
                                                    format={(percent: number) => {
                                                        return <h4 style={{ color: 'white', fontWeight: 'bold' }}>{typeof reportAnswer === 'number' ? reportAnswer?.toFixed(1) : reportAnswer}</h4>
                                                    }}
                                                />
                                            ) : (
                                                <p>
                                                    {reportAnswer}
                                                </p>
                                            )
                                        }
                                    </div>
                                    <div
                                        style={{ display: 'flex', flexDirection: 'column', gap: 6 }}
                                    >
                                        <p style={{ color: 'grey' }} >
                                            {Positions.LineManager}
                                        </p>
                                        {
                                            typeof lineManagerAnswer === 'number' ? (
                                                <QuestionProgress
                                                    type={Positions.LineManager}
                                                    percent={lineManagerAnswerPercentage}
                                                    format={(percent: number) => {
                                                        return <h4 style={{ color: 'white', fontWeight: 'bold' }}>{typeof lineManagerAnswer === 'number' ? lineManagerAnswer?.toFixed(1) : lineManagerAnswer}</h4>
                                                    }}
                                                />
                                            ) : (
                                                <p>
                                                    {lineManagerAnswerPercentage}
                                                </p>
                                            )
                                        }
                                    </div>
                                    <div
                                        style={{ display: 'flex', flexDirection: 'column', gap: 6 }}
                                    >
                                        <p style={{ color: 'grey' }} >
                                            {Positions.Team}
                                        </p>
                                        {

                                            typeof teamAnswer === 'number' ? (
                                                <QuestionProgress
                                                    type={Positions.Team}
                                                    percent={teamAnswerPercentage}
                                                    format={(percent: number) => {
                                                        return <h4 style={{ color: 'white', fontWeight: 'bold' }}>{typeof teamAnswer === 'number' ? teamAnswer?.toFixed(1) : teamAnswer}</h4>
                                                    }}
                                                />
                                            ) : (
                                                <p>
                                                    {teamAnswer}
                                                </p>
                                            )
                                        }
                                    </div>
                                    {
                                        rs.benchmarkScorePercentage ? (
                                            <div
                                                style={{ display: 'flex', flexDirection: 'column', gap: 6 }}
                                            >
                                                <p style={{ color: 'grey' }} >
                                                    <>{t(FeedbackGroupQuestionReportKeys.feedbackGroupQuestionReport_comparisonString)}</>
                                                </p>
                                                <QuestionProgress
                                                    type={'Comparison'}
                                                    percent={rs.benchmarkScorePercentage}
                                                    format={(percent: number) => {
                                                        return <h4 style={{ color: 'white', fontWeight: 'bold' }}>{rs.benchmarkScore?.toFixed(1)}</h4>
                                                    }}
                                                />
                                            </div>
                                        ) : null
                                    }
                                    <p style={{ color: 'grey', fontStyle: 'italic', marginTop: 20 }}>
                                        {rs?.description}
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}