import { FilePdfFilled, LoadingOutlined } from '@ant-design/icons'
import { Divider, Empty, Skeleton, Tooltip, Typography, message } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config'
import { GetSurveyCampaignGroupReportV2Request, PrintRequest } from '../../constants/apiRequestResponse'
import TEST_IDS from '../../constants/cypressTestIds'
import { ReportLayouts } from '../../constants/misc'
import { BasicSurveyCampaign, BasicSurveyTemplate, CategoryGroupedStatistics, QuestionStatistics, TeamStatistics } from '../../constants/types'
import { getSurveyCampaignGroupReportV2, printAsPDF } from '../../services/api'
import { NormalGroupQuestionReportKeys } from '../../services/i8tn/NormalGroupQuestionReport/keys'
import { ReportsViewKeys } from '../../services/i8tn/ReportsView/keys'
import { getQueryParam } from '../../util/index'


type Prop = {
    surveyCampaignId?: string,
}

const COLORS = {
    green: '#2da650',
    yellow: '#d9bc38',
    red: '#cc3b3b',
    grey: 'grey',
    black: 'black',
}

export default function NormalGroupQuestionReport(props: Prop) {

    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [isPrintAsPDFLoading, setIsPrintAsPDFLoading] = useState(false)
    const [surveyTemplate, setSurveyTemplate] = useState<BasicSurveyTemplate | undefined>()
    const [categoryGroupedStatistics, setCategoryGroupedStatistics] = useState<CategoryGroupedStatistics | undefined>()
    const [teamStatistics, setTeamStatistics] = useState<TeamStatistics | undefined>()
    const [questionStatistics, setQuestionStatistics] = useState<QuestionStatistics[] | undefined>()
    const [highlightedQuestionStatistics, setHighlightedQuestionStatistics] = useState<QuestionStatistics[] | undefined>()
    const [surveyCampaign, setSurveyCampaign] = useState<BasicSurveyCampaign | undefined>()

    const surveyCampaignId = props.surveyCampaignId || getQueryParam('surveyCampaignId') || undefined
    const mode = ReportLayouts.normalGroupReport
    const isPdf = getQueryParam('print') ? true : false

    const { t } = useTranslation()


    const getData = useCallback(async () => {

        try {

            setIsLoading(true)
            const req: GetSurveyCampaignGroupReportV2Request = {
                surveyCampaignId: surveyCampaignId,
            }

            getSurveyCampaignGroupReportV2(req)
                .then(res => {

                    if (!res.success) throw new Error(res.message)
                    setCategoryGroupedStatistics(res.categoryGroupedStatistics)
                    setTeamStatistics(res.teamStatistics)
                    setQuestionStatistics(res.questionStatistics)
                    setHighlightedQuestionStatistics(res.highlightedQuestionStatistics)
                    setSurveyCampaign(res.surveyCampaign)
                    setSurveyTemplate(res.surveyTemplate)
                })
                .catch(err => {
                    message.error(err?.toString())
                    setIsError(true)
                })
                .finally(() => setIsLoading(false))

        } catch (err) {
            message.error(typeof err === 'string' ? err : typeof err === 'object' ? err?.toString() : `Something went wrong`)
            setIsError(true)
        }
        setIsLoading(false)
    }, [surveyCampaignId])

    useEffect(() => {
        getData()
    }, [surveyCampaignId, getData])


    const onExportPDF = () => {
        setIsPrintAsPDFLoading(true)

        let url = new URL(`${window.location.origin}/print`)
        url.searchParams.append('print', 'true')
        url.searchParams.append('layout', mode)
        url.searchParams.append('surveyCampaignId', surveyCampaignId?.toString() || '')

        let urlString = url.toString()
        /**
         * 14/8/2022 daniel.kwok
         * Render pdf will not work locally (puppeteer thing)
         * So, if it's local, just render the UI
         * Else, call the API to produce PDF
         */
        if (config.env === 'dev') {
            window.location.href = urlString
        } else {

            urlString = encodeURIComponent(urlString)
            const req: PrintRequest = {
                url: urlString
            }
            printAsPDF(req)
                .then(() => {
                    setIsPrintAsPDFLoading(false)
                })
                .catch(err => {
                    message.error(err?.toString())
                })
        }
    }

    if (isLoading) {
        return (
            <div className='page-content' style={{ backgroundColor: 'white' }}>
                <Skeleton active />
            </div>
        )
    }

    if (isError) {
        return (
            <div className='page-content' style={{ backgroundColor: 'white' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    <Typography.Title level={5}>
                        Something went wrong
                    </Typography.Title>
                </div>
            </div>
        )
    }


    return (
        <div className='page-content' style={{ backgroundColor: 'white' }}>
            <div
                style={{
                    margin: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >

                {
                    !isPdf ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                gap: 20,
                                marginBottom: 50,
                            }}
                        >
                            <div>
                                <p style={{ fontWeight: 'bold' }} >{surveyCampaign?.name}</p>
                                <p style={{ color: 'grey' }}>{surveyTemplate?.name}</p>
                            </div>
                            {
                                isPrintAsPDFLoading ? (
                                    <LoadingOutlined
                                        style={{ fontSize: 30, }} />
                                ) : (
                                    <Tooltip title={t(NormalGroupQuestionReportKeys.normalGroupQuestionReport_downloadAsPDFTooltip)}>

                                        <FilePdfFilled
                                            data-testid={TEST_IDS.REPORT_PAGE.group_download_as_pdf_button_id}
                                            onClick={e => {
                                                onExportPDF()
                                            }}
                                            style={{ fontSize: 30, }} />
                                    </Tooltip>
                                )
                            }
                        </div>
                    ) : null
                }

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-start',
                        width: '100%',
                    }}
                >

                    <Typography.Title level={3} >
                        {t(ReportsViewKeys.reportsView_dimensionSummary)}
                    </Typography.Title>
                </div>
                < Divider style={{ marginTop: 0 }} />
                {
                    categoryGroupedStatistics ? (
                        <div>
                            <div
                                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}
                            >
                                <p> {t(ReportsViewKeys.reportsView_legend)}</p>
                                <div
                                    style={{ display: 'flex', gap: 20, justifyContent: 'flex-end', marginBottom: 20, flexWrap: 'wrap' }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <div style={{ height: 11, width: 11, backgroundColor: COLORS.green }}></div>
                                        <p style={{ color: COLORS.green }}>{t(ReportsViewKeys.reportsView_favourable)}</p>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <div style={{ height: 11, width: 11, backgroundColor: COLORS.yellow }}></div>
                                        <p style={{ color: COLORS.yellow }}>{t(ReportsViewKeys.reportsView_neutral)}</p>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <div style={{ height: 11, width: 11, backgroundColor: COLORS.red }}></div>
                                        <p style={{ color: COLORS.red }}>{t(ReportsViewKeys.reportsView_unfavourable)}</p>
                                    </div>
                                </div>
                            </div>

                            <p style={{ color: 'grey' }}>{t(ReportsViewKeys.reportsView_valuesAreDisplayedInPercentage)}</p>
                            < Divider style={{ marginTop: 5 }} />
                            {
                                Object.keys(categoryGroupedStatistics).map(key => {
                                    const statistics = categoryGroupedStatistics[key]

                                    return (
                                        <div key={key} style={{ display: 'flex', alignItems: 'center', gap: 5, width: '100%' }}>
                                            <div style={{ width: '30%' }}>
                                                <p style={{ fontSize: 17 }}>
                                                    {key}
                                                </p>

                                            </div>
                                            <div style={{ width: '70%', display: 'flex', gap: 5 }}>
                                                {
                                                    statistics.favourable ? (
                                                        <div style={{ height: 20, width: `${statistics.favourable}%`, backgroundColor: COLORS.green, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <p style={{ color: 'white', fontWeight: 'bold' }}>{statistics.favourable}</p>
                                                        </div>
                                                    ) : null
                                                }
                                                {
                                                    statistics.neutral ? (
                                                        <div style={{ height: 20, width: `${statistics.neutral}%`, backgroundColor: COLORS.yellow, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <p style={{ color: 'white', fontWeight: 'bold' }}>{statistics.neutral}</p>
                                                        </div>
                                                    ) : null
                                                }
                                                {
                                                    statistics.unfavourable ? (
                                                        <div style={{ height: 20, width: `${statistics.unfavourable}%`, backgroundColor: COLORS.red, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <p style={{ color: 'white', fontWeight: 'bold' }}>{statistics.unfavourable}</p>
                                                        </div>
                                                    ) : null
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    ) :
                        <p style={{ color: 'grey' }}>{t(ReportsViewKeys.reportsView_reportUnavailable)}</p>
                }

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-start',
                        width: '100%',
                        marginTop: 150,
                    }}
                >

                    <Typography.Title level={3} >
                        {t(ReportsViewKeys.reportsView_engagementSummary)}
                    </Typography.Title>
                </div>
                < Divider style={{ marginTop: 0 }} />


                {
                    teamStatistics ? (
                        <div>
                            <div
                                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}
                            >
                                <p> {t(ReportsViewKeys.reportsView_legend)}</p>
                                <div
                                    style={{ display: 'flex', gap: 20, justifyContent: 'flex-end', marginBottom: 20, flexWrap: 'wrap' }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <div style={{ height: 11, width: 11, backgroundColor: COLORS.green }}></div>
                                        <p style={{ color: COLORS.green }}>{t(ReportsViewKeys.reportsView_favourable)}</p>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <div style={{ height: 11, width: 11, backgroundColor: COLORS.yellow }}></div>
                                        <p style={{ color: COLORS.yellow }}>{t(ReportsViewKeys.reportsView_neutral)}</p>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <div style={{ height: 11, width: 11, backgroundColor: COLORS.red }}></div>
                                        <p style={{ color: COLORS.red }}>{t(ReportsViewKeys.reportsView_unfavourable)}</p>
                                    </div>
                                </div>
                            </div>

                            <p style={{ color: 'grey' }}>{t(ReportsViewKeys.reportsView_valuesAreDisplayedInPercentage)}</p>
                            < Divider style={{ marginTop: 5 }} />
                            {
                                Object.keys(teamStatistics).map(key => {
                                    const statistics = teamStatistics[key]

                                    return (
                                        <div key={key} style={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
                                            <div style={{ width: '30%' }}>
                                                <p style={{ fontSize: 17 }}>
                                                    {key}
                                                </p>

                                            </div>
                                            <div style={{ width: '70%', display: 'flex', gap: 5 }}>
                                                {
                                                    statistics.favourable?(
                                                        <div style={{ height: 20, width: `${statistics.favourable}%`, backgroundColor: COLORS.green, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <p style={{ color: 'white', fontWeight: 'bold' }}>{statistics.favourable}</p>
                                                        </div>
                                                    ):null
                                                }
                                                {
                                                    statistics.neutral?(
                                                        <div style={{ height: 20, width: `${statistics.neutral}%`, backgroundColor: COLORS.yellow, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <p style={{ color: 'white', fontWeight: 'bold' }}>{statistics.neutral}</p>
                                                        </div>
                                                    ):null
                                                }
                                                {
                                                    statistics.unfavourable?(
                                                        <div style={{ height: 20, width: `${statistics.unfavourable}%`, backgroundColor: COLORS.red, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <p style={{ color: 'white', fontWeight: 'bold' }}>{statistics.unfavourable}</p>
                                                        </div>
                                                    ):null
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    ) :
                        <p style={{ color: 'grey' }}>{t(ReportsViewKeys.reportsView_reportUnavailable)}</p>
                }

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-start',
                        width: '100%',
                        marginTop: 150,
                    }}
                >

                    <Typography.Title level={3} >
                        {t(ReportsViewKeys.reportsView_questions)}
                    </Typography.Title>
                </div>
                < Divider style={{ marginTop: 0 }} />

                {
                    questionStatistics ? (
                        <div>
                            <div
                                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}
                            >
                                <p> {t(ReportsViewKeys.reportsView_legend)}</p>
                                <div
                                    style={{ display: 'flex', gap: 20, justifyContent: 'flex-end', marginBottom: 20, flexWrap: 'wrap' }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <div style={{ height: 11, width: 11, backgroundColor: COLORS.green }}></div>
                                        <p style={{ color: COLORS.green }}>{t(ReportsViewKeys.reportsView_favourable)}</p>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <div style={{ height: 11, width: 11, backgroundColor: COLORS.yellow }}></div>
                                        <p style={{ color: COLORS.yellow }}>{t(ReportsViewKeys.reportsView_neutral)}</p>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <div style={{ height: 11, width: 11, backgroundColor: COLORS.red }}></div>
                                        <p style={{ color: COLORS.red }}>{t(ReportsViewKeys.reportsView_unfavourable)}</p>
                                    </div>
                                </div>
                            </div>

                            <p style={{ color: 'grey' }}>{t(ReportsViewKeys.reportsView_valuesAreDisplayedInPercentage)}</p>
                            < Divider style={{ marginTop: 5 }} />
                            <div style={{ display: 'flex', gap: 15, flexDirection: 'column' }}>
                                {
                                    questionStatistics?.map(qs => {
                                        if (qs.isApplicable) {
                                            return (
                                                <div key={qs.index}>
                                                    <p>{qs.index}. {qs.statement}</p>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
                                                        <div style={{ width: '100%', display: 'flex', gap: 5, justifyContent: 'space-between' }}>
                                                            {
                                                                qs.favourable ? (
                                                                    <div style={{ height: 20, width: `${qs.favourable}%`, backgroundColor: COLORS.green, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <p style={{ color: 'white', fontWeight: 'bold' }}>{qs.favourable}</p>
                                                                    </div>
                                                                ) : null
                                                            }
                                                            {
                                                                qs.neutral ? (
                                                                    <div style={{ height: 20, width: `${qs.neutral}%`, backgroundColor: COLORS.yellow, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <p style={{ color: 'white', fontWeight: 'bold' }}>{qs.neutral}</p>
                                                                    </div>
                                                                ) : null
                                                            }
                                                            {
                                                                qs.unfavourable ? (
                                                                    <div style={{ height: 20, width: `${qs.unfavourable}%`, backgroundColor: COLORS.red, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <p style={{ color: 'white', fontWeight: 'bold' }}>{qs.unfavourable}</p>
                                                                    </div>

                                                                ) : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        return (
                                            <div key={qs.index}>
                                                <p>{qs.index}. {qs.statement}</p>
                                                <p style={{ color: COLORS.grey, fontWeight: 'bold' }}>
                                                    {t(ReportsViewKeys.reportsView_notApplicable)}
                                                </p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    ) :
                        <p style={{ color: 'grey' }}>{t(ReportsViewKeys.reportsView_reportUnavailable)}</p>
                }

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-start',
                        width: '100%',
                        marginTop: 150,
                    }}
                >

                    <Typography.Title level={3} >
                        {t(ReportsViewKeys.reportsView_highlights)}
                    </Typography.Title>
                </div>
                < Divider style={{ marginTop: 0 }} />

                {
                    highlightedQuestionStatistics ? (
                        <div>
                            <div
                                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}
                            >
                                <p> {t(ReportsViewKeys.reportsView_legend)}</p>
                                <div
                                    style={{ display: 'flex', gap: 20, justifyContent: 'flex-end', marginBottom: 20, flexWrap: 'wrap' }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <div style={{ height: 11, width: 11, backgroundColor: COLORS.green }}></div>
                                        <p style={{ color: COLORS.green }}>{t(ReportsViewKeys.reportsView_favourable)}</p>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <div style={{ height: 11, width: 11, backgroundColor: COLORS.yellow }}></div>
                                        <p style={{ color: COLORS.yellow }}>{t(ReportsViewKeys.reportsView_neutral)}</p>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <div style={{ height: 11, width: 11, backgroundColor: COLORS.red }}></div>
                                        <p style={{ color: COLORS.red }}>{t(ReportsViewKeys.reportsView_unfavourable)}</p>
                                    </div>
                                </div>
                            </div>

                            <p style={{ color: 'grey' }}>{t(ReportsViewKeys.reportsView_valuesAreDisplayedInPercentage)}</p>
                            < Divider style={{ marginTop: 5 }} />
                            <div style={{ display: 'flex', gap: 15, flexDirection: 'column' }}>
                                {
                                    highlightedQuestionStatistics?.map(cq => {
                                        return (
                                            <div key={cq.index}>
                                                <p>{cq.index}. {cq.statement}</p>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
                                                    <div style={{ width: '100%', display: 'flex', gap: 5 }}>
                                                        {
                                                            cq.favourable ?(
                                                                <div style={{ height: 20, width: `${cq.favourable}%`, backgroundColor: COLORS.green, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <p style={{ color: 'white', fontWeight: 'bold' }}>{cq.favourable}</p>
                                                                </div>
                                                            ): null
                                                        }
                                                        {
                                                            cq.neutral ?(
                                                                <div style={{ height: 20, width: `${cq.neutral}%`, backgroundColor: COLORS.yellow, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <p style={{ color: 'white', fontWeight: 'bold' }}>{cq.neutral}</p>
                                                                    </div>
                                                            ): null
                                                        }
                                                        {
                                                            cq.unfavourable ?(
                                                                <div style={{ height: 20, width: `${cq.unfavourable}%`, backgroundColor: COLORS.red, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <p style={{ color: 'white', fontWeight: 'bold' }}>{cq.unfavourable}</p>
                                                                </div>
                                                            ): null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    ) :
                        <p style={{ color: 'grey' }}>{t(ReportsViewKeys.reportsView_reportUnavailable)}</p>
                }

            </div>
        </div >
    )
}