import { Card, PageHeader, Tabs } from 'antd'
import React from 'react'
import { getQueryParam, setQueryParam } from '../../../util'
import CompanyTab from '../CompanyTab'
import CompanyUserTab from '../CompanyUserTab'
import SurveyTemplateTab from '../SurveyTemplateTab'
import SurveyCampaignTab from '../SurveyCampaignTab'

export default function AdminDashboardPage() {

    return (

        <div className='page-content'>

            <PageHeader
                className="site-page-header"
                title="Dashboard"
            />
            <Card>
                <Tabs
                    defaultActiveKey={getQueryParam('key') || "Company User"}
                    onChange={key => {
                        setQueryParam('key', key)
                    }}
                >
                    <Tabs.TabPane tab="Company User" key="Company User">
                        <CompanyUserTab />
                    </Tabs.TabPane>

                    <Tabs.TabPane tab="Company" key="Company">
                        <CompanyTab />
                    </Tabs.TabPane>

                    <Tabs.TabPane tab="Survey Templates" key="SurveyTemplate">
                        <SurveyTemplateTab />
                    </Tabs.TabPane>

                    <Tabs.TabPane tab="Survey Campaign" key="SurveyCampaign">
                        <SurveyCampaignTab />
                    </Tabs.TabPane>
                </Tabs>
            </Card>
        </div >

    )
}