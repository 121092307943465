import { ReportsPageKeys } from "./keys"

export const englishValues = {
    [ReportsPageKeys.reportsPage_reportsTitleString]: 'Reports',
    [ReportsPageKeys.reportsPage_searchString]: 'Search',
    [ReportsPageKeys.reportsPage_engagementSurveyPlaceholder]: 'Engagement survey',
    [ReportsPageKeys.reportsPage_surveyString]: 'Survey',
    [ReportsPageKeys.reportsPage_allDropdown]: 'All',
    [ReportsPageKeys.reportsPage_searchPrompt]: 'Search',
    [ReportsPageKeys.reportsPage_clearFiltersPrompt]: 'Clear filters',
    [ReportsPageKeys.reportsPage_clearedPrompt]: 'Cleared',
    [ReportsPageKeys.reportsPage_lastUpdateString]: 'Last update: ',
    [ReportsPageKeys.reportsPage_viewReportBtn]: 'View report',
    [ReportsPageKeys.reportsPage_endString]: 'End',
}

export const malayValues = {
    [ReportsPageKeys.reportsPage_reportsTitleString]: 'Laporan',
    [ReportsPageKeys.reportsPage_searchString]: 'Cari',
    [ReportsPageKeys.reportsPage_engagementSurveyPlaceholder]: 'Tinjauan penglibatan',
    [ReportsPageKeys.reportsPage_surveyString]: 'Tinjauan',
    [ReportsPageKeys.reportsPage_allDropdown]: 'Semua',
    [ReportsPageKeys.reportsPage_searchPrompt]: 'Cari',
    [ReportsPageKeys.reportsPage_clearFiltersPrompt]: 'Kosongkan penapis',
    [ReportsPageKeys.reportsPage_clearedPrompt]: 'Dikosongkan',
    [ReportsPageKeys.reportsPage_lastUpdateString]: 'Dikemas kini pada: ',
    [ReportsPageKeys.reportsPage_viewReportBtn]: 'Tunjukkan laporan',
    [ReportsPageKeys.reportsPage_endString]: 'Tamat',
}