import React from "react";
import {
    Route, Switch
} from "react-router-dom";
import NotFoundPage from '../../pages/NotFoundPage';
import ReportPrintViewPage from "../../pages/ReportPrintViewPage";
import SurveyResponsePage from "../../pages/SurveyResponsePage";

/**
 * 12/6/2022 daniel.kwok
 * 
 * Hosts pages that aren't auth-related, but can be accessed without login
 * - Responding to survey
 * - Printing layout (for puppeter to access without having to auth). Not the best, but quickest atm
 */
export default function SurveyRoutes() {
    return (
        <Switch>
            <Route
                path="/survey"
            >
                <SurveyResponsePage />
            </Route>

            <Route
                path="/print"
            >
                <ReportPrintViewPage />
            </Route>

            <Route
                path='*'
                component={NotFoundPage}
            />

        </Switch>
    );
}