export enum NormalIndividualQuestionReportKeys {
    normalIndividualQuestionReport_individualReportTitleString = 'normalIndividualQuestionReport_individualReportTitleString',
    normalIndividualQuestionReport_genderString = 'normalIndividualQuestionReport_genderString',
    normalIndividualQuestionReport_emailString = 'normalIndividualQuestionReport_emailString',
    normalIndividualQuestionReport_surveyIDString = 'normalIndividualQuestionReport_surveyIDString',
    normalIndividualQuestionReport_surveyString = 'normalIndividualQuestionReport_surveyString',
    normalIndividualQuestionReport_lastUpdatedString = 'normalIndividualQuestionReport_lastUpdatedString',
    normalIndividualQuestionReport_downloadAsPDFTooltip = 'normalIndividualQuestionReport_downloadAsPDFTooltip',
    normalIndividualQuestionReport_participantString = 'normalIndividualQuestionReport_participantString',
    normalIndividualQuestionReport_comparisonString = 'normalIndividualQuestionReport_comparisonString',
}