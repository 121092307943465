import { SurveyResponsePageKeys } from "./keys"

export const englishValues = {
    [SurveyResponsePageKeys.surveyResponsePage_startBtn]: 'Start',
    [SurveyResponsePageKeys.surveyResponsePage_saveAsDraftBtn]: 'Save as draft',
    [SurveyResponsePageKeys.surveyResponsePage_saveProgressPrompt]: 'Save progress and resume survey another time.',
    [SurveyResponsePageKeys.surveyResponsePage_accessibleFromEmailPrompt]: 'Survey will still be accessible from the link in your email.',
    [SurveyResponsePageKeys.surveyResponsePage_submitBtn]: 'Submit',
    [SurveyResponsePageKeys.surveyResponsePage_surveyIncompletePrompt]: 'Survey is incomplete',
    [SurveyResponsePageKeys.surveyResponsePage_submitSurveyPrompt]: 'Submit survey. Answers cannot be changed later.',
    [SurveyResponsePageKeys.surveyResponsePage_confirmString]: 'Confirm? You will not be able to edit this once submitted.',
    [SurveyResponsePageKeys.surveyResponsePage_savedAsDraftString]: 'Survey is saved as draft.',
    [SurveyResponsePageKeys.surveyResponsePage_comeBackAnytimeString]: 'Feel free to come back and finish it anytime.',
    [SurveyResponsePageKeys.surveyResponsePage_continueBtn]: 'Continue',
    [SurveyResponsePageKeys.surveyResponsePage_surveyCompletedString]: 'Survey is completed!',
    [SurveyResponsePageKeys.surveyResponsePage_thankYouString]: 'Thank you for submitting your response.',
    [SurveyResponsePageKeys.surveyResponsePage_surveyCancelledTitleString]: 'This survey response had been cancelled.',
    [SurveyResponsePageKeys.surveyResponsePage_surveyCancelledSubtitleString]: 'You will not be able to submit.',
    [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDraftTitleString]: 'This survey campaign has yet start.',
    [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDraftSubtitleString]: 'You will not be able to submit your response.',
    [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDeletedTitleString]: 'This campaign had been cancelled.',
    [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDeletedSubtitleString]: 'You will not be able to submit your response.',
    [SurveyResponsePageKeys.surveyResponsePage_unknownStatusString]: 'Unknown status',
    [SurveyResponsePageKeys.surveyResponsePage_reachOutToHRString]: 'Please reach out to your HR department.',
    [SurveyResponsePageKeys.surveyResponsePage_yetToStartString]: 'This campaign have yet to start.',
    [SurveyResponsePageKeys.surveyResponsePage_noSubmissionAcceptedString]: 'No submission will be accepted.',
    [SurveyResponsePageKeys.surveyResponsePage_expiredString]: 'This campaign is expired.',
    [SurveyResponsePageKeys.surveyResponsePage_noMoreSubmissionString]: 'No more submission will be accepted.',
    [SurveyResponsePageKeys.surveyResponsePage_somethingsWrongWithSurveyString]: "Something's wrong with this survey.",
    [SurveyResponsePageKeys.surveyResponsePage_surveyAlreadySubmittedTitleString]: 'Response submitted.',
    [SurveyResponsePageKeys.surveyResponsePage_surveyAlreadySubmittedSubtitleString]: 'Thank you for your submission.',
    [SurveyResponsePageKeys.surveyResponsePage_cannotResubmitString]: 'You cannot re-submit a submitted survey.',
    [SurveyResponsePageKeys.surveyResponsePage_somethingsWrongWithCampaignString]: "Something's wrong with this campaign.",
}

export const malayValues = {
    [SurveyResponsePageKeys.surveyResponsePage_startBtn]: 'Mula',
    [SurveyResponsePageKeys.surveyResponsePage_saveAsDraftBtn]: 'Simpan sebagai draf',
    [SurveyResponsePageKeys.surveyResponsePage_saveProgressPrompt]: 'Simpan progres dan sambung tinjauan pada masa yang lain.',
    [SurveyResponsePageKeys.surveyResponsePage_accessibleFromEmailPrompt]: 'Tinjauan masih dapat diakses dari pautan dalam e-mel anda.',
    [SurveyResponsePageKeys.surveyResponsePage_submitBtn]: 'Hantar',
    [SurveyResponsePageKeys.surveyResponsePage_surveyIncompletePrompt]: 'Tinjauan tidak dilengkapi',
    [SurveyResponsePageKeys.surveyResponsePage_submitSurveyPrompt]: 'Hantarkan tinjauan. Jawapan tidak boleh ditukar selepas ini.',
    [SurveyResponsePageKeys.surveyResponsePage_confirmString]: 'Sahkan? Anda tidak dapat edit ini selepas dihantarkan.',
    [SurveyResponsePageKeys.surveyResponsePage_savedAsDraftString]: 'Tinjauan telah disimpan sebagai draf.',
    [SurveyResponsePageKeys.surveyResponsePage_comeBackAnytimeString]: 'Anda boleh kembali dan melengkapkan tinjauan pada masa yang lain.',
    [SurveyResponsePageKeys.surveyResponsePage_continueBtn]: 'Teruskan',
    [SurveyResponsePageKeys.surveyResponsePage_surveyCompletedString]: 'Tinjauan telah dilengkapkan!',
    [SurveyResponsePageKeys.surveyResponsePage_thankYouString]: 'Terima kasih atas respons anda.',
    [SurveyResponsePageKeys.surveyResponsePage_surveyCancelledTitleString]: 'Tinjauan ini telah dibatalkan.',
    [SurveyResponsePageKeys.surveyResponsePage_surveyCancelledSubtitleString]: 'Anda tidak akan dapat menyerahkan jawapan anda.',
    [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDraftTitleString]: 'Kempen ini belum bermula.',
    [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDraftSubtitleString]: 'Anda tidak akan dapat menyerahkan jawapan anda.',
    [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDeletedTitleString]: 'Kempen ini telah dipadamkan.',
    [SurveyResponsePageKeys.surveyResponsePage_surveyCampaignDeletedSubtitleString]: 'Anda tidak akan dapat menyerahkan jawapan anda.',
    [SurveyResponsePageKeys.surveyResponsePage_unknownStatusString]: 'Status tidak diketahui',
    [SurveyResponsePageKeys.surveyResponsePage_reachOutToHRString]: 'Sila hubungi jabatan Sumber Manusia anda.',
    [SurveyResponsePageKeys.surveyResponsePage_yetToStartString]: 'Kempen ini belum bermula.',
    [SurveyResponsePageKeys.surveyResponsePage_noSubmissionAcceptedString]: 'Respons tidak akan diterimakan.',
    [SurveyResponsePageKeys.surveyResponsePage_expiredString]: 'Kempen ini sudah tamat tempoh.',
    [SurveyResponsePageKeys.surveyResponsePage_noMoreSubmissionString]: 'Respons yang baharu tidak akan diterimakan.',
    [SurveyResponsePageKeys.surveyResponsePage_somethingsWrongWithSurveyString]: "Ada yang tak kena dengan tinjauan ini.",
    [SurveyResponsePageKeys.surveyResponsePage_surveyAlreadySubmittedTitleString]: 'Tinjauan telah diserahkan.',
    [SurveyResponsePageKeys.surveyResponsePage_surveyAlreadySubmittedSubtitleString]: 'Terima kasih atas penyerahan anda',
    [SurveyResponsePageKeys.surveyResponsePage_cannotResubmitString]: 'Anda tidak dapat hantar semula tinjauan yang sudah dihantarkan.',
    [SurveyResponsePageKeys.surveyResponsePage_somethingsWrongWithCampaignString]: "Ada yang tak kena dengan kempen ini.",

}