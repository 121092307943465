import { NormalGroupQuestionReportKeys } from "./keys"

export const englishValues = {
    [NormalGroupQuestionReportKeys.normalGroupQuestionReport_groupReportTitleString]: 'Group Report',
    [NormalGroupQuestionReportKeys.normalGroupQuestionReport_surveyCampaignString]: 'Survey Campaign',
    [NormalGroupQuestionReportKeys.normalGroupQuestionReport_surveyString]: 'Survey',
    [NormalGroupQuestionReportKeys.normalGroupQuestionReport_lastUpdatedString]: 'Last updated',
    [NormalGroupQuestionReportKeys.normalGroupQuestionReport_downloadAsPDFTooltip]: 'Download as PDF',
    [NormalGroupQuestionReportKeys.normalGroupQuestionReport_modeString]: 'Mode',
    [NormalGroupQuestionReportKeys.normalGroupQuestionReport_averageString]: 'Average',
    [NormalGroupQuestionReportKeys.normalGroupQuestionReport_comparisonString]: 'Comparison',
    [NormalGroupQuestionReportKeys.normalGroupQuestionReport_notApplicableString]: 'Not applicable',
}

export const malayValues = {
    [NormalGroupQuestionReportKeys.normalGroupQuestionReport_groupReportTitleString]: 'Laporan Kumpulan',
    [NormalGroupQuestionReportKeys.normalGroupQuestionReport_surveyCampaignString]: 'Kempen Tinjauan',
    [NormalGroupQuestionReportKeys.normalGroupQuestionReport_surveyString]: 'Tinjauan',
    [NormalGroupQuestionReportKeys.normalGroupQuestionReport_lastUpdatedString]: 'Dikemas kini pada',
    [NormalGroupQuestionReportKeys.normalGroupQuestionReport_downloadAsPDFTooltip]: 'Muat turun sebagai PDF',
    [NormalGroupQuestionReportKeys.normalGroupQuestionReport_modeString]: 'Mod',
    [NormalGroupQuestionReportKeys.normalGroupQuestionReport_averageString]: 'Purata',
    [NormalGroupQuestionReportKeys.normalGroupQuestionReport_comparisonString]: 'Perbandingan',
    [NormalGroupQuestionReportKeys.normalGroupQuestionReport_notApplicableString]: 'Tidak berkaitan',
}