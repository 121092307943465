import { ReportsViewKeys } from "./keys"

export const englishValues = {
    [ReportsViewKeys.reportsView_reportsBreadcrumb]: 'Reports',
    [ReportsViewKeys.reportsView_lastUpdatedString]: 'Last updated',
    [ReportsViewKeys.reportsView_totalString]: 'Total',
    [ReportsViewKeys.reportsView_completedString]: 'Completed',
    [ReportsViewKeys.reportsView_cancelledString]: 'Cancelled',
    [ReportsViewKeys.reportsView_completionRateString]: 'Completion rate',
    [ReportsViewKeys.reportsView_groupReportTab]: 'Group Report',
    [ReportsViewKeys.reportsView_individualsReportTab]: 'Individuals Report',
    [ReportsViewKeys.reportsView_dimensionSummary]: 'Dimension Summary',
    [ReportsViewKeys.reportsView_engagementSummary]: 'Engagement Summary',
    [ReportsViewKeys.reportsView_questions]: 'Questions',
    [ReportsViewKeys.reportsView_highlights]: 'Highlights',
    [ReportsViewKeys.reportsView_legend]: 'Legend',
    [ReportsViewKeys.reportsView_valuesAreDisplayedInPercentage]: 'Values are displayed in %',
    [ReportsViewKeys.reportsView_favourable]: 'Favourable',
    [ReportsViewKeys.reportsView_neutral]: 'Neutral',
    [ReportsViewKeys.reportsView_unfavourable]: 'Unfavourable',
    [ReportsViewKeys.reportsView_notApplicable]: '(not applicable))',
    [ReportsViewKeys.reportsView_reportUnavailable]: '(report unavailable)',
}

export const malayValues = {
    [ReportsViewKeys.reportsView_reportsBreadcrumb]: 'Laporan',
    [ReportsViewKeys.reportsView_lastUpdatedString]: 'Dikemas kini pada',
    [ReportsViewKeys.reportsView_totalString]: 'Jumlah',
    [ReportsViewKeys.reportsView_completedString]: 'Dilengkapkan',
    [ReportsViewKeys.reportsView_cancelledString]: 'Dibatalkan',
    [ReportsViewKeys.reportsView_completionRateString]: 'Kadar pelengkapan',
    [ReportsViewKeys.reportsView_groupReportTab]: 'Laporan Kumpulan',
    [ReportsViewKeys.reportsView_individualsReportTab]: 'Laporan Individu',
    [ReportsViewKeys.reportsView_dimensionSummary]: 'Ringkasan Dimensi',
    [ReportsViewKeys.reportsView_engagementSummary]: 'Ringkasan Penglibatan',
    [ReportsViewKeys.reportsView_questions]: 'Soalan',
    [ReportsViewKeys.reportsView_highlights]: 'Sorotan',
    [ReportsViewKeys.reportsView_legend]: 'Legenda',
    [ReportsViewKeys.reportsView_valuesAreDisplayedInPercentage]: 'Nilai dipaparkan dalam %',
    [ReportsViewKeys.reportsView_favourable]: 'Menguntungkan',
    [ReportsViewKeys.reportsView_neutral]: 'Neutral',
    [ReportsViewKeys.reportsView_unfavourable]: 'Tidak Menguntungkan',
    [ReportsViewKeys.reportsView_notApplicable]: '(tidak berkenaan)',
    [ReportsViewKeys.reportsView_reportUnavailable]: '(laporan tidak tersedia)',
}