export enum NormalGroupQuestionReportKeys {
    normalGroupQuestionReport_groupReportTitleString = 'normalGroupQuestionReport_groupReportTitleString',
    normalGroupQuestionReport_surveyCampaignString = 'normalGroupQuestionReport_surveyCampaignString',
    normalGroupQuestionReport_surveyString = 'normalGroupQuestionReport_surveyString',
    normalGroupQuestionReport_lastUpdatedString = 'normalGroupQuestionReport_lastUpdatedString',
    normalGroupQuestionReport_downloadAsPDFTooltip = 'normalGroupQuestionReport_downloadAsPDFTooltip',
    normalGroupQuestionReport_modeString = 'normalGroupQuestionReport_modeString',
    normalGroupQuestionReport_averageString = 'normalGroupQuestionReport_averageString',
    normalGroupQuestionReport_comparisonString = 'normalGroupQuestionReport_comparisonString',
    normalGroupQuestionReport_notApplicableString = 'normalGroupQuestionReport_notApplicableString',
}