export enum SurveyCampaign360FormKeys {
    surveyCampaign360Form_runASurveyString = 'surveyCampaign360Form_runASurveyString',
    surveyCampaign360Form_basicInfoString = 'surveyCampaign360Form_basicInfoString',
    surveyCampaign360Form_campaignNameString = 'surveyCampaign360Form_campaignNameString',
    surveyCampaign360Form_nextBtn = 'surveyCampaign360Form_nextBtn',
    surveyCampaign360Form_uploadEmployeesString = 'surveyCampaign360Form_uploadEmployeesString',
    surveyCampaign360Form_uploadEmployeesTitleString = 'surveyCampaign360Form_uploadEmployeesTitleString',
    surveyCampaign360Form_uploadEmployeesDownloadString = 'surveyCampaign360Form_uploadEmployeesDownloadString',
    surveyCampaign360Form_uploadEmployeesSampleExcelLink = 'surveyCampaign360Form_uploadEmployeesSampleExcelLink',
    surveyCampaign360Form_uploadEmployeesExampleString = 'surveyCampaign360Form_uploadEmployeesExampleString',
    surveyCampaign360Form_uploadEmployeesSupportedFormatsString = 'surveyCampaign360Form_uploadEmployeesSupportedFormatsString',
    surveyCampaign360Form_uploadEmployeesParticipantsString = 'surveyCampaign360Form_uploadEmployeesParticipantsString',
    surveyCampaign360Form_employeesAddNewParticipantString = 'surveyCampaign360Form_employeesAddNewParticipantString',
    surveyCampaign360Form_employeesClickToEditTooltip = 'surveyCampaign360Form_employeesClickToEditTooltip',
    surveyCampaign360Form_employeesClearAllBtn = 'surveyCampaign360Form_employeesClearAllBtn',
    surveyCampaign360Form_employeesConfirmClearAllPrompt = 'surveyCampaign360Form_employeesConfirmClearAllPrompt',
    surveyCampaign360Form_employeesClearGroupTooltip = 'surveyCampaign360Form_employeesClearGroupTooltip',
    surveyCampaign360Form_employeesConfirmClearGroupPrompt = 'surveyCampaign360Form_employeesConfirmClearGroupPrompt',
    surveyCampaign360Form_employeesNextBtn = 'surveyCampaign360Form_employeesNextBtn',
    surveyCampaign360Form_addingAString = 'surveyCampaign360Form_addingAString',
    surveyCampaign360Form_toString = 'surveyCampaign360Form_toString',
    surveyCampaign360Form_employeesAddFormPositionString = 'surveyCampaign360Form_employeesAddFormPositionString',
    surveyCampaign360Form_employeesAddFormFirstNameString = 'surveyCampaign360Form_employeesAddFormFirstNameString',
    surveyCampaign360Form_employeesAddFormLastNameString = 'surveyCampaign360Form_employeesAddFormLastNameString',
    surveyCampaign360Form_employeesAddFormEmailString = 'surveyCampaign360Form_employeesAddFormEmailString',
    surveyCampaign360Form_employeesAddFormGenderString = 'surveyCampaign360Form_employeesAddFormGenderString',
    surveyCampaign360Form_employeesAddFormTeamString = 'surveyCampaign360Form_employeesAddFormTeamString',
    surveyCampaign360Form_employeesAddFormSaveBtn = 'surveyCampaign360Form_employeesAddFormSaveBtn',
    surveyCampaign360Form_employeesEditFormPositionString = 'surveyCampaignForm_employeesEditFormPositionString',
    surveyCampaign360Form_employeesEditFormFirstNameString = 'surveyCampaign360Form_employeesEditFormFirstNameString',
    surveyCampaign360Form_employeesEditFormLastNameString = 'surveyCampaign360Form_employeesEditFormLastNameString',
    surveyCampaign360Form_employeesEditFormEmailString = 'surveyCampaign360Form_employeesEditFormEmailString',
    surveyCampaign360Form_employeesEditFormGenderString = 'surveyCampaign360Form_employeesEditFormGenderString',
    surveyCampaign360Form_employeesEditFormTeamString = 'surveyCampaign360Form_employeesEditFormTeamString',
    surveyCampaign360Form_employeesEditFormSaveBtn = 'surveyCampaign360Form_employeesEditFormSaveBtn',
    surveyCampaign360Form_employeesEditFormDeleteBtn = 'surveyCampaign360Form_employeesEditFormDeleteBtn',
    surveyCampaign360Form_employeesEditFormConfirmDeletePrompt = 'surveyCampaign360Form_employeesEditFormConfirmDeletePrompt',
    surveyCampaign360Form_previewAndSendString = 'surveyCampaign360Form_previewAndSendString',
    surveyCampaign360Form_previewEmailsTitleString = 'surveyCampaign360Form_previewEmailsTitleString',
    surveyCampaign360Form_previewSampleEmailString = 'surveyCampaign360Form_previewSampleEmailString',
    surveyCampaign360Form_previewFeedbackString = 'surveyCampaign360Form_previewFeedbackString',
    surveyCampaign360Form_previewAsString = 'surveyCampaign360Form_previewAsString',
    surveyCampaign360Form_previewMoreActionsDropdown = 'surveyCampaign360Form_previewMoreActionsDropdown',
    surveyCampaign360Form_previewRefreshDropdown = 'surveyCampaign360Form_previewRefreshDropdown',
    surveyCampaign360Form_previewEditTemplateDropdown = 'surveyCampaign360Form_previewEditTemplateDropdown',
    surveyCampaign360Form_faqString = 'surveyCampaign360Form_faqString',
    surveyCampaign360Form_whatIsLinkString = 'surveyCampaign360Form_whatIsLinkString',
    surveyCampaign360Form_uniqueLinkString = 'surveyCampaign360Form_uniqueLinkString',
    surveyCampaign360Form_previewBackBtn = 'surveyCampaign360Form_previewBackBtn',
    surveyCampaign360Form_previewDraftBtn = 'surveyCampaign360Form_previewDraftBtn',
    surveyCampaign360Form_previewSendBtn = 'surveyCampaign360Form_previewSendBtn',
    surveyCampaign360Form_previewSendAreYouSurePrompt = 'surveyCampaign360Form_previewSendAreYouSurePrompt',
    surveyCampaign360Form_sendingSurveyPrompt = 'surveyCampaign360Form_sendingSurveyPrompt',
    surveyCampaign360Form_surveyCampaignStartedPrompt = 'surveyCampaign360Form_surveyCampaignStartedPrompt',

}