import { MailOutlined, QuestionCircleFilled, RollbackOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Empty, Modal, Skeleton, Table, Tooltip, Typography, message } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PositionsTag from '../../../components/PositionsTag';
import SurveyCampaignStatusTag from '../../../components/SurveyCampaignStatusTag';
import SurveyResponseStatusTag from '../../../components/SurveyResponseStatusTag';
import { GetSurveyCampaignBySurveyCampaignIdRequest, GetSurveyResponseBySurveyCampaignIdRequest, GetSurveyTemplatesBySurveyTemplateIdRequest, ResendSurveyResponseEmailRequest } from '../../../constants/apiRequestResponse';
import { SurveyCampaign, SurveyCampaignStatusLabels, SurveyResponse, SurveyResponseStatusLabels, SurveyTemplate, SurveyTemplateType } from '../../../constants/types';
import { getSurveyCampaignBySurveyCampaignId, getSurveyResponsesBySurveyCampaignId, getSurveyTemplateBySurveyTemplateId, resendSurveyResponseEmail } from '../../../services/api';
import { SurveyCampaignViewKeys } from '../../../services/i8tn/SurveyCampaignView/keys';
import { SurveyCampaignKeys } from '../../../services/i8tn/SurveyCampaignsPage/keys';
import { getQueryParam, parseTime } from '../../../util/index';

interface Prop {
    surveyCampaignId?: string
    onSurveyCampaignUpdated: (surveyCampaign: SurveyCampaign) => void
}

type QueryValues = {
    surveySearchString: string
    surveyStatus: string,
    surveyCampaignId?: string,
}

export default function SurveyCampaignView(props: Prop) {
    const [isLoading, setIsLoading] = useState(false)
    const [surveyCampaign, setSurveyCampaign] = useState<SurveyCampaign | undefined>()
    const [surveyTemplate, setSurveyTemplate] = useState<SurveyTemplate | undefined>()
    const [surveyResponses, setSurveyResponses] = useState<SurveyResponse[] | null>()
    const [selectedSurveyIds, setSelectedSurveyIds] = useState<string[]>([])
    const { t } = useTranslation()

    const queryValues: QueryValues = {
        surveySearchString: getQueryParam('surveySearchString') || '',
        surveyStatus: getQueryParam('surveyStatus') || 'All',
        surveyCampaignId: getQueryParam('surveyCampaignId') || '',
    }
    /**
        * 16/6/2022 daniel.kwok
        * Query for survey responses every 2 second, for a maximum of MAX times
        * Until changed survey campaign or no more pending
        * 
        * It's either this, or we do some sort of wss
        */
    const pollData = useCallback(async () => {
        if (!props.surveyCampaignId) return null

        const MAX = 60
        let counter = 0
        const timer = setInterval(async () => {
            try {

                const req: GetSurveyResponseBySurveyCampaignIdRequest = {
                    surveyCampaignId: props.surveyCampaignId,
                }
                const res3 = await getSurveyResponsesBySurveyCampaignId(req)

                if (!res3.success) throw res3.message

                const pendings = res3.surveyResponses.filter(sr => sr.status === SurveyResponseStatusLabels.PENDING)

                if (pendings.length === 0) {
                    clearInterval(timer)
                    return
                } else {
                    setSurveyResponses(res3.surveyResponses)
                    counter++
                    if (counter > MAX) {
                        clearInterval(timer)
                    }
                }
            } catch (err) {
                message.error(typeof err === 'string' ? err : typeof err === 'object' ? err?.toString() : `Unable to upload image`)
            }
        }, 2 * 1000)

        return timer
    }, [props])


    const getData = useCallback(async () => {
        if (!props.surveyCampaignId) return null
        if (props.surveyCampaignId === surveyCampaign?._id) return null

        try {
            setIsLoading(true)

            const req1: GetSurveyCampaignBySurveyCampaignIdRequest = {
                surveyCampaignId: props.surveyCampaignId,
            }
            const res1 = await getSurveyCampaignBySurveyCampaignId(req1)
            if (!res1.success) throw res1.message
            setSurveyCampaign(res1.surveyCampaign)

            const req2: GetSurveyTemplatesBySurveyTemplateIdRequest = {
                surveyTemplateId: res1.surveyCampaign.surveyTemplateId
            }
            const res2 = await getSurveyTemplateBySurveyTemplateId(req2)
            if (!res2.success) throw res2.message
            setSurveyTemplate(res2.surveyTemplate)

            const req3: GetSurveyResponseBySurveyCampaignIdRequest = {
                surveyCampaignId: props.surveyCampaignId,
            }
            const res3 = await getSurveyResponsesBySurveyCampaignId(req3)

            if (!res3.success) throw res3.message

            setSurveyResponses(res3.surveyResponses)

            setIsLoading(false)

            pollData()
        } catch (err) {
            message.error(typeof err === 'string' ? err : typeof err === 'object' ? err?.toString() : `Something went wrong`)
        }
    }, [props.surveyCampaignId, pollData, surveyCampaign?._id])


    useEffect(() => {
        getData()
    }, [getData, props.surveyCampaignId])


    if (!props.surveyCampaignId) {
        return <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                height: '100%',
            }}
        >
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            <Typography.Title level={5}>
                <>{t(SurveyCampaignKeys.surveyCampaigns_selectFromLeftString)}</>
            </Typography.Title>
        </div>
    }

    if (isLoading) {
        return <Skeleton active />
    }

    function onResendSurveyResponses(surveyIds: string[]) {
        const surveysToResend = surveyIds
            .map(surveyResponseId => surveyResponses?.find(s => s._id === surveyResponseId))
            .filter(survey => {
                return survey?._isAbleToResend
            })
            || []

        Modal.confirm({
            title: surveysToResend.length > 0 ? 'Confirm re-send email?' : 'Selected surveys cannot be re-sent',
            content: surveysToResend.length > 0 ? (
                <>

                    <p>{surveysToResend.length} emails would be sent out.</p>
                    <ul>
                        {
                            surveysToResend.map(s => {
                                return <li key={s?._id}>{s?.surveyEmployee.email}</li>
                            })
                        }
                    </ul>
                </>
            ) : null,
            onOk: () => {
                const req: ResendSurveyResponseEmailRequest = {
                    surveyResponseIds: surveysToResend.map(s => s?._id || '')
                }
                resendSurveyResponseEmail(req)
                    .then(res => {
                        if (!res.success) throw new Error(res.message)
                        message.success(`Resend scheduled`)

                        const newSurveyResponses = surveyResponses?.map(sr => {
                            if (surveyIds.includes(sr._id)) {
                                sr.status = SurveyResponseStatusLabels.PENDING
                            }
                            return sr
                        })

                        setSurveyResponses(newSurveyResponses)
                        pollData()
                    })
                    .catch(err => message.error(err.message))
            }

        })
    }

    const columns = [
        {
            title: <>{t(SurveyCampaignViewKeys.surveyCampaignView_emailTableHeader)}</>,
            width: 250,
            render: (rowData: SurveyResponse) => {
                return <span>{rowData.surveyEmployee.email}</span>
            },
            sorter: (a: SurveyResponse, b: SurveyResponse) => a.surveyEmployee.email.localeCompare(b.surveyEmployee.email),
        },
        {
            title: 'Status',
            render: (rowData: SurveyResponse) => {
                return <SurveyResponseStatusTag status={rowData.status} />
            },
            sorter: (a: SurveyResponse, b: SurveyResponse) => a.status.localeCompare(b.status),
        },
        {
            title: (
                <span>
                    {t(SurveyCampaignViewKeys.surveyCampaignView_progressTableHeader)} &nbsp;
                    <Tooltip title={t(SurveyCampaignViewKeys.surveyCampaignView_progressPrompt)}>
                        <QuestionCircleFilled />
                    </Tooltip>
                </span>
            ),
            render: (rowData: SurveyResponse) => {
                return `${rowData._totalNumberOfAnsweredQuestions} / ${rowData._totalNumberOfQuestions}`
            },
        },
        {
            title: <>{t(SurveyCampaignViewKeys.surveyCampaignView_firstNameTableHeader)}</>,
            render: (rowData: SurveyResponse) => {
                return <span>{rowData.surveyEmployee.fname}</span>
            },
            sorter: (a: SurveyResponse, b: SurveyResponse) => a.surveyEmployee.fname.localeCompare(b.surveyEmployee.fname),
        },
        {
            title: <>{t(SurveyCampaignViewKeys.surveyCampaignView_lastNameTableHeader)}</>,
            render: (rowData: SurveyResponse) => {
                return <span>{rowData.surveyEmployee.lname}</span>
            },
            sorter: (a: SurveyResponse, b: SurveyResponse) => a.surveyEmployee.lname.localeCompare(b.surveyEmployee.lname),
        },
        {
            title: <>{t(SurveyCampaignViewKeys.surveyCampaignView_positionTableHeader)}</>,
            render: (rowData: SurveyResponse) => {
                return <PositionsTag position={rowData.surveyEmployee.position} />
            },
            sorter: (a: SurveyResponse, b: SurveyResponse) => a.surveyEmployee.position.localeCompare(b.surveyEmployee.position),
        },
        {
            title: <>{t(SurveyCampaignViewKeys.surveyCampaignView_teamTableHeader)}</>,
            render: (rowData: SurveyResponse) => {
                return <span>{rowData.surveyEmployee.team}</span>
            },
            sorter: (a: SurveyResponse, b: SurveyResponse) => a.surveyEmployee.team.localeCompare(b.surveyEmployee.team),
        },
        {
            title: <>{t(SurveyCampaignViewKeys.surveyCampaignView_genderTableHeader)}</>,
            render: (rowData: SurveyResponse) => {
                return <span>{rowData.surveyEmployee.gender}</span>
            },
            sorter: (a: SurveyResponse, b: SurveyResponse) => a.surveyEmployee.gender?.localeCompare(b.surveyEmployee.gender),
        },
        {
            title: <>{t(SurveyCampaignViewKeys.surveyCampaignView_lastUpdatedTableHeader)}</>,
            dataIndex: 'updatedAt',
            render: (updatedAt) => parseTime(updatedAt),
            sorter: (a, b) => moment(a.updatedAt).valueOf() - moment(b.updatedAt).valueOf(),
        },
        {
            title: 'ID',
            dataIndex: '_id',
        },
    ]

    const dataSource = surveyResponses
        ?.filter(sr => {

            /**
             * 12/6/2022 daniel.kwok
             * Disabled search or filter by api. Frontend only, temporarily
             */

            let isIncludeSearchStringMatch = true
            let isSurveyCampaignStatusMatch = true

            if (queryValues.surveySearchString) {
                const hashedString = `${sr.surveyEmployee.email} ${sr.surveyEmployee.fname} ${sr.surveyEmployee.lname} ${sr.surveyEmployee.position} ${sr.surveyEmployee.team}`
                isIncludeSearchStringMatch = hashedString.toLocaleLowerCase().includes(queryValues.surveySearchString.toLowerCase())
            }

            if (queryValues.surveyStatus && queryValues.surveyStatus !== 'All') {
                isSurveyCampaignStatusMatch = sr.status === queryValues.surveyStatus
            }

            return isIncludeSearchStringMatch && isSurveyCampaignStatusMatch

        })
        || []

    function getSurveyStatisticComponent(label: string, value: any) {
        return <div
            style={{
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <p style={{ color: 'grey', marginBottom: 0 }}>
                {label}
            </p>
            <p>{value}</p>
        </div>
    }

    function getActionsComponent(selectedSurveyIds: string[]) {

        return (
            <div
                style={{
                    display: 'flex',
                    gap: 10,
                    alignItems: 'center',
                    marginBottom: 10,
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                {
                    /**
                     * 9/9/2022 daniel.kwok
                     * Hide survey response action buttons if survey campaign hasn't started
                     */
                    surveyCampaign?.status === SurveyCampaignStatusLabels.ongoing ? (
                        <div
                            style={{
                                display: 'flex',
                                gap: 10
                            }}
                        >

                            <Button
                                onClick={e => { onResendSurveyResponses(selectedSurveyIds) }}
                                disabled={selectedSurveyIds.length === 0}
                                icon={<MailOutlined />}
                            >
                                {t(SurveyCampaignViewKeys.surveyCampaignView_resendEmailBtn)}
                            </Button>
                            <Tooltip
                                title='Coming soon'
                            >
                                <Button
                                    onClick={e => { onResendSurveyResponses(selectedSurveyIds) }}
                                    disabled={true}
                                    icon={<StopOutlined />}
                                >
                                    {t(SurveyCampaignViewKeys.surveyCampaignView_cancelSurveyBtn)}
                                </Button>
                            </Tooltip>
                            <Tooltip
                                title='Coming soon'
                            >
                                <Button
                                    onClick={e => { onResendSurveyResponses(selectedSurveyIds) }}
                                    disabled={true}
                                    icon={<RollbackOutlined />}
                                >
                                    {t(SurveyCampaignViewKeys.surveyCampaignView_reopenSurveyBtn)}
                                </Button>
                            </Tooltip>

                        </div>
                    ) : <div></div>
                }
            </div>
        )
    }

    function getTableComponent() {
        switch (surveyTemplate?.type) {
            case SurveyTemplateType.normal:
                return (
                    <>
                        <p style={{ color: 'grey', marginBottom: 0 }}>
                            Responses
                        </p>
                        <Table
                            rowKey='_id'
                            columns={columns}
                            dataSource={dataSource}
                            scroll={{
                                x: columns.length * 150
                            }}
                            /**
                             * 9/9/2022 daniel.kwok
                             * Hide checkboxes if campaign hasn't started
                             */
                            rowSelection={surveyCampaign?.status === SurveyCampaignStatusLabels.ongoing ? {
                                type: 'checkbox',
                                selectedRowKeys: selectedSurveyIds,
                                onChange: (selectedRowKeys, selectedRows: SurveyResponse[]) => {
                                    setSelectedSurveyIds(selectedRowKeys as string[])
                                },
                                onSelectAll: () => {
                                    setSelectedSurveyIds(surveyResponses?.map(s => s._id) || [])
                                }
                            } : undefined}
                            pagination={{
                                position: ['bottomRight'],
                                showSizeChanger: true,
                                defaultPageSize: 10
                            }}
                        />
                    </>
                )

            case SurveyTemplateType.feedback:
                const participants: SurveyResponse[] = dataSource
                    .filter(d => d.surveyEmployee._id === d.surveyEmployee.participantId)

                if (participants.length === 0) {
                    return <Empty />
                }

                return (
                    <>
                        {
                            participants.map((participant, i) => {

                                const peers = dataSource
                                    .filter(d => {
                                        const isPeer = d.surveyEmployee.participantId === participant.surveyEmployee._id
                                        return isPeer
                                    })

                                return (
                                    <div key={participant._id}>
                                        <Typography.Title level={5}>
                                            360 Survey #{i + 1}: {`${participant.surveyEmployee.fname} ${participant.surveyEmployee.lname}(${participant.surveyEmployee.email})`}
                                        </Typography.Title>
                                        <Table
                                            rowKey='_id'
                                            columns={columns}
                                            dataSource={peers}
                                            scroll={{
                                                x: columns.length * 150
                                            }}
                                            rowSelection={surveyCampaign?.status === SurveyCampaignStatusLabels.ongoing ? {
                                                type: 'checkbox',
                                                selectedRowKeys: selectedSurveyIds,
                                                onChange: (selectedRowKeys, selectedRows: SurveyResponse[]) => {
                                                    setSelectedSurveyIds(selectedRowKeys as string[])
                                                },
                                                onSelectAll: () => {
                                                    setSelectedSurveyIds(surveyResponses?.map(s => s._id) || [])
                                                }
                                            } : undefined}
                                            pagination={{
                                                position: ['bottomRight'],
                                                showSizeChanger: true,
                                                defaultPageSize: 10
                                            }}
                                        />
                                    </div>
                                )
                            })
                        }
                    </>
                )
        }
    }

    function getTitleComponent(surveyCampaign?: SurveyCampaign) {
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 10,
                }}
            >
                <div>
                    <Typography.Title level={3} style={{ marginBottom: 0 }}>
                        {surveyCampaign?.name}
                    </Typography.Title>
                    <p style={{ color: 'grey', fontStyle: 'italic' }}>
                        ID: {surveyCampaign?._id}
                    </p>
                </div>
            </div>
        )
    }


    return (
        <>

            {
                getTitleComponent(surveyCampaign)
            }

            <br />
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    gap: 30,
                }}
            >
                {getSurveyStatisticComponent(t(SurveyCampaignKeys.surveyCampaigns_statusString), <SurveyCampaignStatusTag status={surveyCampaign?.status} />)}
                {getSurveyStatisticComponent(t(SurveyCampaignKeys.surveyCampaigns_surveyString), surveyTemplate?.name)}
                {getSurveyStatisticComponent(t(SurveyCampaignViewKeys.surveyCampaignView_createdString), parseTime(surveyCampaign?.createdAt))}
            </div>

            <br />
            <div
                style={{
                    display: 'flex',
                    gap: 10
                }}
            >
                {
                    getActionsComponent(selectedSurveyIds)
                }
            </div>

            <br />
            <br />
            {
                getTableComponent()
            }

        </>
    )
}